import { NoParamsKeys } from '@deltasierra/i18n';
import { sortedBy, SanitizedUser } from '@deltasierra/shared';
import * as React from 'react';
import { Translate } from '../../directives/Translate';
import UserInviteStatusButton from './UserInviteStatusButton';

type ColumnDefinition = {
    property: keyof SanitizedUser;
    key: NoParamsKeys;
};

export type UsersTableProps = {
    extraActions?: (user: SanitizedUser) => React.ReactNode;
    getEditUrl: (user: SanitizedUser) => string;
    users: SanitizedUser[];
    dataCy?: string;
};

const UsersTable: React.FunctionComponent<UsersTableProps> = ({ dataCy, extraActions, getEditUrl, users }) => {
    const columns: ColumnDefinition[] = [
        { key: 'AGENCY.CLIENT.LOCATION.USERS.FIRST_NAME', property: 'firstName' },
        { key: 'AGENCY.CLIENT.LOCATION.USERS.LAST_NAME', property: 'lastName' },
        { key: 'AGENCY.CLIENT.LOCATION.USERS.USERNAME_EMAIL', property: 'username' },
    ];
    const sortedUsers = sortedBy(users, ['lastName', 'firstName', 'username']);

    return (
        <table className="table table-striped" data-cy={dataCy}>
            <thead>
                <tr>
                    {columns.map(({ key }) => (
                        <th className="text-muted" key={key}>
                            <Translate keyId={key} />
                        </th>
                    ))}
                    <th>{/* Actions column */}</th>
                </tr>
            </thead>
            <tbody>
                {!sortedUsers.length && (
                    <tr>
                        <td colSpan={columns.length + 1}>
                            <em>
                                <Translate keyId="AGENCY.CLIENT.LOCATION.USERS.NO_USERS_ASSIGNED_TO_COMPANY" />
                            </em>
                        </td>
                    </tr>
                )}
                {sortedUsers.map(user => (
                    <UserRow
                        columns={columns}
                        extraActions={extraActions}
                        getEditUrl={getEditUrl}
                        key={user.id}
                        user={user}
                    />
                ))}
            </tbody>
        </table>
    );
};
UsersTable.displayName = 'UsersTable';

type UserRowProps = {
    extraActions?: (user: SanitizedUser) => React.ReactNode;
    columns: ColumnDefinition[];
    getEditUrl: (user: SanitizedUser) => string;
    user: SanitizedUser;
};
const UserRow: React.FunctionComponent<UserRowProps> = ({ columns, extraActions, getEditUrl, user }) => (
    <tr data-cy="client-users-table-row">
        {columns.map(({ property }) => (
            <td key={property}>
                <a href={getEditUrl(user)}>{user[property]}</a>
            </td>
        ))}
        <td className="text-right">
            <UserInviteStatusButton className="space-right" user={user} />
            {extraActions && extraActions(user)}
        </td>
    </tr>
);
UserRow.displayName = 'UserRow';

export default UsersTable;
