import {
    LocationId,
    ImageProcessingFailedEvent,
    ImageProcessingFinishedEvent,
    ImageProcessingPlannerCreatedEvent,
    IPublishImageRequest,
    IPublishImageResponse,
    PublishMultiImageRequest,
    GalleryPlannerDetails,
    WorkflowStartResult,
    UploadId,
    AssetIdAndLayerId,
    BuilderTemplateId,
    LegacyAssetIdAndLayerId,
    SocketMessageEvent,
    assertNever,
    PublishedArtifactGroupId,
} from '@deltasierra/shared';
import { SocketService } from '../../sockets/socketService';
import { $qSID } from '../../common/angularData';
import { SentryService } from '../../common/sentryService';
import IPromise = angular.IPromise;
import IQService = angular.IQService;

type PublishCallback<CD> = (locationId: LocationId, request: IPublishImageRequest<CD>) => IPromise<WorkflowStartResult>;

type MultiImagePublishCallback<CD> = (input: PublishMultiImageRequest<CD>) => IPromise<WorkflowStartResult>;

type PublishPhotoArgs<TChannelData> = {
    imageProcessingContext: { isProcessingOnServer: boolean };
    locationId: LocationId;
    uploadId: UploadId;
    templateId: BuilderTemplateId;
    plannerDetails: GalleryPlannerDetails | null;
    channelData: TChannelData;
    publishCallback: PublishCallback<TChannelData>;
    linkedAssetLibraryAssetIds: AssetIdAndLayerId[];
    publishedArtifactGroupId: PublishedArtifactGroupId | null;
    scheduledPublishGroupId: string | null;
    successEvent: ImagePublishServiceCompletionEvent;
};

type PublishMultiImageArgs<TChannelData> = {
    channelData: TChannelData;
    imageProcessingContext: { isProcessingOnServer: boolean };
    linkedAssetLibraryAssetIds: LegacyAssetIdAndLayerId[];
    locationId: string;
    plannerDetails: GalleryPlannerDetails | null;
    publishCallback: MultiImagePublishCallback<TChannelData>;
    publishedArtifactGroupId: PublishedArtifactGroupId | null;
    scheduledPublishGroupId: string | null;
    scheduledTime?: Date;
    successEvent?: ImagePublishServiceCompletionEvent;
    templateId: BuilderTemplateId;
    uploadIds: string[];
};

export enum ImagePublishServiceCompletionEvent {
    PlannerCreated = 'plannerCreated',
    Published = 'published',
}

export default class ImagePublishService {
    public static SID = 'ImagePublishService';

    public static readonly $inject: string[] = [$qSID, SocketService.SID, SentryService.SID];

    public constructor(
        private readonly $q: IQService,
        private readonly socketService: SocketService,
        private readonly sentryService: SentryService,
    ) {}

    public publishPhoto<TChannelData>({
        channelData,
        imageProcessingContext,
        linkedAssetLibraryAssetIds,
        locationId,
        plannerDetails,
        publishCallback,
        publishedArtifactGroupId,
        scheduledPublishGroupId,
        successEvent = ImagePublishServiceCompletionEvent.Published,
        templateId,
        uploadId,
    }: PublishPhotoArgs<TChannelData>): IPromise<any> {
        const requestBody: IPublishImageRequest<TChannelData> = {
            auditData: {
                builderTemplateId: templateId,
                linkedAssetLibraryAssetIds,
                plannerId: plannerDetails ? plannerDetails.id : undefined,
                publishedArtifactGroupId,
                scheduledPublishGroupId,
            },
            channelData,
            uploadId,
        };
        imageProcessingContext.isProcessingOnServer = true;

        const workflow = this.startSocketServiceWorkflow(() => publishCallback(locationId, requestBody), successEvent);
        return this.$q.resolve(workflow).finally(() => {
            imageProcessingContext.isProcessingOnServer = false;
        });
    }

    public publishMultiImage<TChannelData>({
        channelData,
        imageProcessingContext,
        linkedAssetLibraryAssetIds,
        locationId,
        plannerDetails,
        publishCallback,
        publishedArtifactGroupId,
        scheduledPublishGroupId,
        scheduledTime,
        successEvent = ImagePublishServiceCompletionEvent.Published,
        templateId,
        uploadIds,
    }: PublishMultiImageArgs<TChannelData>): IPromise<any> {
        const input: PublishMultiImageRequest<TChannelData> = {
            artifactIds: uploadIds,
            auditData: {
                builderTemplateId: templateId,
                deviceId: null,
                linkedAssetLibraryAssetIds,
                plannerId: plannerDetails ? plannerDetails.graphqlId : undefined,
                publishedArtifactGroupId,
                scheduledPublishGroupId,
            },
            channelData,
            locationId,
            scheduledTime,
        };
        imageProcessingContext.isProcessingOnServer = true;

        const workflow = this.startSocketServiceWorkflow(() => publishCallback(input), successEvent);
        return this.$q.resolve(workflow).finally(() => {
            imageProcessingContext.isProcessingOnServer = false;
        });
    }

    private startSocketServiceWorkflow(
        workflowTrigger: () => IPromise<WorkflowStartResult>,
        successEvent: ImagePublishServiceCompletionEvent,
    ) {
        let successEventMessage: SocketMessageEvent<IPublishImageResponse> | null = null;
        switch (successEvent) {
            case ImagePublishServiceCompletionEvent.Published:
                successEventMessage = ImageProcessingFinishedEvent;
                break;
            case ImagePublishServiceCompletionEvent.PlannerCreated:
                successEventMessage = ImageProcessingPlannerCreatedEvent;
                break;
            default:
                throw assertNever(successEvent);
        }

        return this.socketService.startWorkflow(
            workflowTrigger,
            successEventMessage,
            ImageProcessingFailedEvent,
            undefined,
            () => this.sentryService.captureException('Socket message timeout alert', {}),
        ) as IPromise<IPublishImageResponse>;
    }
}

angular.module('app').service(ImagePublishService.SID, ImagePublishService);
