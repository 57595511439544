import * as React from 'react';
import { ClientId, LocationId } from '@deltasierra/shared';
import { gql, useQuery } from '@apollo/client';
import { convertIdToUniversalNodeId } from '../../../../graphql/utils';
import { useAngularComponentRenderer, useAngularScope } from '../../../../common/componentUtils/angularRendering';
import { GetLocationForLocationNav, GetLocationForLocationNavVariables } from './__graphqlTypes/GetLocationForLocationNav';


const GET_LOCATION_FOR_LOCATION_NAV = gql`
    query GetLocationForLocationNav($id: ID!) {
        location(id: $id) {
            id
            title
            client {
                id
                title
            }
        }
    }
`;

export interface LocationNavBarProps {
    clientId: ClientId;
    locationId: LocationId;
}

type LocationNavScope = {
    client: { id: ClientId; title: string };
    location: { id: LocationId; title: string };
};

export const LocationNavBar: React.FC<LocationNavBarProps> = ({ clientId, locationId }) => {
    const id = convertIdToUniversalNodeId('location', locationId);
    const scope = useAngularScope<LocationNavScope>();
    const { data } = useQuery<GetLocationForLocationNav, GetLocationForLocationNavVariables>(GET_LOCATION_FOR_LOCATION_NAV, {
        variables: { id },
    });
    scope.current.client = { id: clientId, title: data?.location?.client.title ?? '' };
    scope.current.location = { id: locationId, title: data?.location?.title ?? '' };
    return useAngularComponentRenderer('<location-nav client="client" location="location"/>', scope);
};
LocationNavBar.displayName = 'LocationNavBar';
