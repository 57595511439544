/* eslint-disable camelcase */
import * as React from 'react';
import {
    Button,
    ConfirmButton,
    EmbeddedHtml,
    Loader,
    ModalBody,
    ModalFooter,
    ModalWithHeader,
    SvgLoading,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Timestamp,
} from '@deltasierra/components';
import { gql, useQuery, useMutation } from '@apollo/client';
import { t, AssignedLocation } from '@deltasierra/shared';
import styled from 'styled-components';
import { Translate } from '../../../../directives/Translate';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import {
    GetScheduledEmails,
    GetScheduledEmailsVariables,
    GetScheduledEmails_location_fitwareScheduledEmails_edges_node,
} from './__graphqlTypes/GetScheduledEmails';
import { DeleteScheduledEmail, DeleteScheduledEmailVariables } from './__graphqlTypes/DeleteScheduledEmail';

const DELETE_SCHEDULED_EMAIL = gql`
    mutation DeleteScheduledEmail($id: ID!) {
        deleteScheduledEmail(id: $id) {
            ... on DeleteScheduledEmailPayload {
                id
            }
            ... on ScheduledEmailNotFoundError {
                message
            }
            ... on ScheduledEmailAlreadyDeletedError {
                message
            }
            ... on DeleteScheduledEmailApiError {
                message
            }
        }
    }
`;

const StyledEmbeddedHtml = styled(EmbeddedHtml)`
    border: 1px black dashed;
`;

const ScheduledEmailViewButtonFragments = {
    scheduledEmail: gql`
        fragment ScheduledEmailViewButtonFragment on ScheduledEmail {
            id
            content
        }
    `,
};

type ScheduledEmailViewButtonProps = {
    location: AssignedLocation;
    scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node;
};

const ScheduledEmailViewButton: React.FC<ScheduledEmailViewButtonProps> & {
    fragments: typeof ScheduledEmailViewButtonFragments;
} = ({ location, scheduledEmail }) => {
    const [isShowingViewModal, updateIsShowingViewModal] = React.useState(false);

    const closeViewModal = () => updateIsShowingViewModal(false);

    return (
        <>
            <Button theme="primary" onClick={() => updateIsShowingViewModal(true)}>
                <Translate keyId="COMMON.VIEW" />
            </Button>
            <ModalWithHeader
                show={isShowingViewModal}
                size="lg"
                title={t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAIL')}
                onClose={closeViewModal}
            >
                <ModalBody>
                    <ScheduledEmailMiniRow location={location} scheduledEmail={scheduledEmail} />
                    <h6>
                        <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.PREVIEW" />
                    </h6>
                    <StyledEmbeddedHtml className="emailTemplateDisplay" html={scheduledEmail.content} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={closeViewModal}>
                        <Translate keyId="COMMON.CLOSE" />
                    </Button>
                </ModalFooter>
            </ModalWithHeader>
        </>
    );
};

ScheduledEmailViewButton.fragments = ScheduledEmailViewButtonFragments;

const ScheduledEmailRowCellsFragments = {
    scheduledEmail: gql`
        fragment ScheduledEmailRowCellsFragment on ScheduledEmail {
            id
            subject
            lists
            scheduleDate
        }
    `,
};

type ScheduledEmailRowCellsProps = {
    location: AssignedLocation;
    scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node;
};

const ScheduledEmailRowCells: React.FC<ScheduledEmailRowCellsProps> & {
    fragments: typeof ScheduledEmailRowCellsFragments;
} = ({ location, scheduledEmail }) => (
    <>
        <TableCell>{scheduledEmail.subject}</TableCell>
        <TableCell>
            {scheduledEmail.lists.map((listName, index, array) => (
                <div key={listName}>{`${listName}${index !== array.length - 1 ? ',' : ''}`}</div>
            ))}
        </TableCell>
        <TableCell>
            <Timestamp date={scheduledEmail.scheduleDate} format="long" timezone={location.timezone} />
        </TableCell>
    </>
);

ScheduledEmailRowCells.fragments = ScheduledEmailRowCellsFragments;

const ScheduledEmailMiniRowFragments = {
    scheduledEmail: gql`
        fragment ScheduledEmailMiniRowFragment on ScheduledEmail {
            id
            ...ScheduledEmailRowCellsFragment
        }
        ${ScheduledEmailRowCells.fragments.scheduledEmail}
    `,
};

type ScheduledEmailMiniRowProps = {
    location: AssignedLocation;
    scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node;
};

const ScheduledEmailMiniRow: React.FC<ScheduledEmailMiniRowProps> & {
    fragments: typeof ScheduledEmailRowCellsFragments;
} = ({ location, scheduledEmail }) => (
    <Table>
        <TableHeader>
            <TableHeaderCell>
                <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.SUBJECT" />
            </TableHeaderCell>
            <TableHeaderCell>
                <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.LISTS" />
            </TableHeaderCell>
            <TableHeaderCell>
                <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.SCHEDULED" />
            </TableHeaderCell>
        </TableHeader>
        <TableBody>
            <TableRow>
                <ScheduledEmailRowCells location={location} scheduledEmail={scheduledEmail} />
            </TableRow>
        </TableBody>
    </Table>
);

ScheduledEmailMiniRow.fragments = ScheduledEmailMiniRowFragments;

const ScheduledEmailRowFragments = {
    scheduledEmail: gql`
        fragment ScheduledEmailRowFragment on ScheduledEmail {
            id
            ...ScheduledEmailViewButtonFragment
            ...ScheduledEmailRowCellsFragment
            ...ScheduledEmailMiniRowFragment
        }
        ${ScheduledEmailViewButton.fragments.scheduledEmail}
        ${ScheduledEmailRowCells.fragments.scheduledEmail}
        ${ScheduledEmailMiniRow.fragments.scheduledEmail}
    `,
};

type ScheduledEmailRowProps = {
    deleteScheduledEmail: (scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node) => void;
    deleteScheduledEmailLoading: boolean;
    location: AssignedLocation;
    scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node;
};

const ScheduledEmailRow: React.FC<ScheduledEmailRowProps> & {
    fragments: typeof ScheduledEmailRowFragments;
} = ({ deleteScheduledEmail, deleteScheduledEmailLoading, location, scheduledEmail, ...props }) => (
    <TableRow {...props}>
        <ScheduledEmailRowCells location={location} scheduledEmail={scheduledEmail} />
        <TableCell className="text-right">
            <ScheduledEmailViewButton location={location} scheduledEmail={scheduledEmail} />
            <ConfirmButton
                confirmText={t('COMMON.DELETE')}
                disabled={deleteScheduledEmailLoading}
                message={
                    <>
                        <div className="alert alert-danger">
                            <p>
                                <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.DELETE_MESSAGE_LINE_1" />
                            </p>
                            <p>
                                <b>
                                    <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.DELETE_MESSAGE_LINE_2" />
                                </b>
                            </p>
                        </div>
                        <ScheduledEmailMiniRow location={location} scheduledEmail={scheduledEmail} />
                    </>
                }
                spaceLeft={true}
                theme="danger"
                onConfirm={() => deleteScheduledEmail(scheduledEmail)}
            >
                {deleteScheduledEmailLoading ? <SvgLoading size="small" /> : <Translate keyId="COMMON.DELETE" />}
            </ConfirmButton>
        </TableCell>
    </TableRow>
);

ScheduledEmailRow.fragments = ScheduledEmailRowFragments;

const GET_SCHEDULED_EMAILS = gql`
    query GetScheduledEmails($locationId: ID!) {
        location(id: $locationId) {
            id
            fitwareScheduledEmails {
                edges {
                    node {
                        id
                        ...ScheduledEmailRowFragment
                    }
                }
            }
        }
    }
    ${ScheduledEmailRow.fragments.scheduledEmail}
`;

function useDeleteScheduledEmail({ query, variables }: { query: any; variables: GetScheduledEmailsVariables }): {
    deleteScheduledEmail: (scheduledEmail: GetScheduledEmails_location_fitwareScheduledEmails_edges_node) => void;
    loading: boolean;
} {
    const mvNotifier = useAngularServiceContext('mvNotifier');

    const [deleteScheduledEmail, { loading }] = useMutation<DeleteScheduledEmail, DeleteScheduledEmailVariables>(
        DELETE_SCHEDULED_EMAIL,
        {
            onCompleted: data => {
                if (data.deleteScheduledEmail.__typename === 'DeleteScheduledEmailPayload') {
                    mvNotifier.success(t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.SCHEDULED_EMAIL_DELETED'));
                } else if (data.deleteScheduledEmail.__typename === 'ScheduledEmailAlreadyDeletedError') {
                    mvNotifier.expectedError(
                        t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.SCHEDULED_EMAIL_ALREADY_DELETED'),
                    );
                } else if (data.deleteScheduledEmail.__typename === 'ScheduledEmailNotFoundError') {
                    mvNotifier.expectedError(t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.SCHEDULED_EMAIL_NOT_FOUND'));
                } else if (data.deleteScheduledEmail.__typename === 'DeleteScheduledEmailApiError') {
                    mvNotifier.expectedError(
                        t('BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.DELETE_SCHEDULED_EMAIL_API_ERROR'),
                    );
                }
            },
        },
    );

    return {
        deleteScheduledEmail: scheduledEmail => {
            void deleteScheduledEmail({
                update: (cache, data) => {
                    // If we have not received a success result, we shouldn't remove it
                    if (data.data?.deleteScheduledEmail.__typename !== 'DeleteScheduledEmailPayload') {
                        return;
                    }

                    const currentEmailsData = cache.readQuery<GetScheduledEmails, GetScheduledEmailsVariables>({
                        query,
                        variables,
                    });

                    if (currentEmailsData === null || currentEmailsData.location === null) {
                        return;
                    }

                    cache.writeQuery<GetScheduledEmails, GetScheduledEmailsVariables>({
                        data: {
                            ...currentEmailsData,
                            location: {
                                ...currentEmailsData.location,
                                fitwareScheduledEmails: {
                                    ...currentEmailsData.location.fitwareScheduledEmails,
                                    edges: currentEmailsData.location?.fitwareScheduledEmails.edges.filter(
                                        edge => edge.node.id !== scheduledEmail.id,
                                    ),
                                },
                            },
                        },
                        query,
                        variables,
                    });
                },
                variables: {
                    id: scheduledEmail.id,
                },
            });
        },
        loading,
    };
}

type ScheduledEmailsTableProps = {
    location: AssignedLocation;
};

// eslint-disable-next-line react/display-name
export const ScheduledEmailsTable: React.FC<ScheduledEmailsTableProps> = ({ location }) => {
    const { data: getScheduledEmailsData, loading: getScheduledEmailsLoading } = useQuery<
        GetScheduledEmails,
        GetScheduledEmailsVariables
    >(GET_SCHEDULED_EMAILS, {
        variables: { locationId: location.graphqlId },
    });

    const { deleteScheduledEmail, loading: deleteScheduledEmailLoading } = useDeleteScheduledEmail({
        query: GET_SCHEDULED_EMAILS,
        variables: { locationId: location.graphqlId },
    });

    return (
        <Loader loading={getScheduledEmailsLoading}>
            <div className="table-responsive">
                <Table>
                    <TableHeader>
                        <TableHeaderCell>
                            <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.SUBJECT" />
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.LISTS" />
                        </TableHeaderCell>
                        <TableHeaderCell>
                            <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.TABLE.SCHEDULED" />
                        </TableHeaderCell>
                        <TableHeaderCell />
                    </TableHeader>
                    <TableBody>
                        {
                            // eslint-disable-next-line @typescript-eslint/no-extra-parens
                            getScheduledEmailsData?.location?.fitwareScheduledEmails?.edges.length ? (
                                getScheduledEmailsData.location.fitwareScheduledEmails.edges.map(
                                    ({ node: scheduledEmail }) => (
                                        <ScheduledEmailRow
                                            deleteScheduledEmail={deleteScheduledEmail}
                                            deleteScheduledEmailLoading={deleteScheduledEmailLoading}
                                            key={`scheduledEmailRow.${scheduledEmail.id}`}
                                            location={location}
                                            scheduledEmail={scheduledEmail}
                                        />
                                    ),
                                )
                            ) : (
                                <TableRow>
                                    <TableCell className="text-center" colSpan={4}>
                                        <Translate keyId="BUILD.MEMBER_ENGAGER.SCHEDULED_EMAILS_WELL.NO_SCHEDULED_EMAILS" />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </div>
        </Loader>
    );
};
