import { gql } from '@apollo/client';
import { DSList, DSListItem, DSListItemIcon, DSListItemText, DSTypography, Loading } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AssetPermission } from 'apps/app-web/__graphqlTypes/globalTypes';
import { AssetConnectionFragmentForFolderList } from './__graphqlTypes/AssetConnectionFragmentForFolderList';
import { LocationFragmentForFolderList } from './__graphqlTypes/LocationFragmentForFolderList';

const COLLECTION_CONNECTION_FRAGMENT_FOR_FOLDER_LIST = gql`
    fragment CollectionConnectionFragmentForFolderList on CollectionConnection {
        edges {
            node {
                __typename
                id
                title
                permissions
            }
        }
    }
`;

const fragments = {
    ASSET_CONNECTION_FRAGMENT_FOR_FOLDER_LIST: gql`
        fragment AssetConnectionFragmentForFolderList on AssetConnection {
            edges {
                node {
                    __typename
                    ... on AssetFolder {
                        id
                        title
                    }
                }
            }
        }
    `,
    LOCATION_FRAGMENT_FOR_FOLDER_LIST: gql`
        fragment LocationFragmentForFolderList on Location {
            id
            collections {
                ...CollectionConnectionFragmentForFolderList
            }
            clientCollections {
                ...CollectionConnectionFragmentForFolderList
            }
        }
        ${COLLECTION_CONNECTION_FRAGMENT_FOR_FOLDER_LIST}
    `,
};

export interface FolderListProps {
    folderSource: AssetConnectionFragmentForFolderList | LocationFragmentForFolderList | null;
    onFolderSelected?: (id: string) => void;
}

export function FolderList(props: FolderListProps): JSX.Element {
    let folderArray: ReadonlyArray<FolderListItem> = [];
    // Ewwwww
    if (props.folderSource?.__typename === 'AssetConnection') {
        folderArray = props.folderSource.edges.reduce<ReadonlyArray<FolderListItem>>((prev, { node }) => {
            if (node.__typename === 'AssetFolder') {
                return [...prev, { id: node.id, title: node.title }];
            }
            return prev;
        }, []);
    } else if (props.folderSource?.__typename === 'Location') {
        folderArray = [...props.folderSource.collections.edges, ...props.folderSource.clientCollections.edges]
            .map(({ node: { id, permissions, title } }) => ({ id, title, permissions }))
            .filter(folder => folder.permissions.includes(AssetPermission.UPDATE));
    }

    return (
        <ListContainer>
            {!props.folderSource && <Loading />}
            {!!props.folderSource && folderArray.length === 0 && (
                <DSTypography align="center" variant="h5">
                    {t('ASSET_LIBRARY.NO_ASSETS_IN_FOLDER')}
                </DSTypography>
            )}
            <DSList data-cy="asset-folder-list" dense>
                {folderArray.map(folder => (
                    <DSListItem key={folder.id} onClick={() => props.onFolderSelected?.(folder.id)}>
                        <DSListItemIcon>
                            <FolderIcon />
                        </DSListItemIcon>
                        <DSListItemText color="textPrimary">{folder.title}</DSListItemText>
                    </DSListItem>
                ))}
            </DSList>
        </ListContainer>
    );
}
FolderList.displayName = 'FolderList';
FolderList.fragments = fragments;

interface FolderListItem {
    id: string;
    title: string;
    permissions?: ReadonlyArray<AssetPermission>;
}

const ListContainer = styled.div`
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    height: 300px;
    margin-top: 8px;
    overflow-y: auto;
    padding: 0px 16px;
`;

const FolderIcon = styled.i.attrs({ className: 'fas fa-folder' })`
    color: #428bca;
    font-size: 18px;
`;
