import { ExternalFrontendRouter, QueryParameter, RouterOrigins } from '@deltasierra/frontend/routing/core';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { BuilderTemplateId } from '@deltasierra/shared';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';
import { useValidateTemplateId, ValidatedTemplate } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/extract-id-from-template-url';
import { ValidationState } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/validation-state.enum';
import { useAppFrontendRouter } from '../../../common/hooks/useAppFrontendRouter';

function getInitialUrl(
    id: number | string | null,
    clientId: string | null,
    locationId: string | null,
    frontendRouter: ExternalFrontendRouter | null,
) {
    if (isNullOrUndefined(id)) {
        return '';
    }
    if (typeof id === 'number') { // Builder Template Id
        return `${window.location.origin}/contentBuilder?template=${id}`;
    }
    if (typeof id === 'string' && clientId && locationId && frontendRouter) { // Buildable Template Id
        return frontendRouter.get(
            `/publish/email/?${new URLSearchParams({
                [QueryParameter.ClientId]: clientId,
                [QueryParameter.LocationId]: locationId,
                [QueryParameter.TemplateId]: id,
                [QueryParameter.Origin]: RouterOrigins.OLD_TEMPLATE_GALLERY,
            })}`,
        );
    }
}

export function useTemplateSelect(
    clientId: string | null,
    initialId: BuilderTemplateId | string | null,
    locationId: string | null,
    onChange?: (value: ValidatedTemplate | null) => void,
): [
    inputProps: { value: string; onChange: React.ChangeEventHandler<HTMLInputElement> },
    state: ValidationState,
    thumbnail?: string,
] {
    // Whether the hook is validating, valid, or invalid
    const [validation, setValidation] = React.useState(ValidationState.None);
    const [thumbnail, setThumbnail] = React.useState<string | undefined>(undefined);
    const onChangeRef = React.useRef(onChange);
    const frontendRouter = useAppFrontendRouter();

    const validate = useValidateTemplateId();

    // Runs validation and sets state
    const handleValidateTemplate = React.useCallback(
        async (url: string) => {
            if (url === '') {
                setValidation(ValidationState.None);
                setThumbnail(undefined);
                onChangeRef.current?.(null);
                return;
            }
            const result = await validate(url);
            if (result.success) {
                setValidation(ValidationState.Valid);
                setThumbnail(result.value.thumbnail);
                onChangeRef.current?.(result.value);
            } else {
                // Template doesn't exist or url was invalid (or something wlse went wrong ie network)
                setValidation(ValidationState.Invalid);
                setThumbnail(undefined);
                onChangeRef.current?.(null);
            }
        },
        [validate],
    );

    // Input props (with validation) fro the text field
    const { inputProps, searchTerm } = useControlledSearchInput({
        initialValue: getInitialUrl(initialId, clientId, locationId, frontendRouter),
        interval: 300,
    });

    React.useEffect(() => {
        void handleValidateTemplate(searchTerm);
    }, [handleValidateTemplate, searchTerm]);

    return [inputProps, validation, thumbnail];
}
