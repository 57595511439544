import { DSGrid, Loading } from '@deltasierra/components';
import { assertNever, BuilderTemplateId } from '@deltasierra/shared';
import { isNotNullOrUndefined, isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';
import { EndAdornment } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/EndAdornment';
import { ValidatedTemplate } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/TemplateSelect/extract-id-from-template-url';
import { PickerDisplay } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/picker-display.enum';
import { ValidationState } from '../../../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/validation-state.enum';
import { ExpressionBinding, OneWayBinding } from '../../../common/angularData';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { PlannerCategoryDropdown } from '../PlannerCategoryDropdown';
import { Grid } from './Grid';
import { PlannerTemplateSelector } from './PlannerTemplateSelector';
import { Selector } from './Selector';

function getInitialState(
    categoryId: number | null,
    templateId: BuilderTemplateId | null,
    buildableTemplateId: string | null,
): PickerDisplay {
    if (isNotNullOrUndefined(categoryId)) {
        return PickerDisplay.Category;
    } else if (isNotNullOrUndefined(templateId) || isNotNullOrUndefined(buildableTemplateId)) {
        return PickerDisplay.Template;
    }
    return PickerDisplay.None;
}

export interface PlannerCategoryOrTemplateSelectorProps {
    buildableTemplateId: string | null,
    clientId?: string;
    locationId?: string;
    categoryId: number | null;
    eventDate: Date;
    onChangePickerDisplayOption?: (pickerDisplayOption: PickerDisplay) => void;
    onSelectCategory?: (categoryId: number | null) => void;
    onSelectTemplate?: (templateId: BuilderTemplateId | null) => void;
    onSelectBuildableTemplate?: (templateContentBuildId: string | null) => void;
    setIsExpiring?: (isExpiring: boolean) => void;
    templateId: BuilderTemplateId | null;
}

export function PlannerCategoryOrTemplateSelector({
    buildableTemplateId,
    categoryId,
    clientId,
    eventDate,
    locationId,
    onChangePickerDisplayOption,
    onSelectBuildableTemplate,
    onSelectCategory,
    onSelectTemplate,
    setIsExpiring,
    templateId,
}: PlannerCategoryOrTemplateSelectorProps): JSX.Element {
    const [selectedOption, setSelectedOption] = React.useState<PickerDisplay>(
        getInitialState(categoryId, templateId, buildableTemplateId),
    );

    function handleBack() {
        onSelectCategory?.(null);
        onSelectTemplate?.(null);
        onSelectBuildableTemplate?.(null);
        onChangePickerDisplayOption?.(PickerDisplay.None);
        setSelectedOption(PickerDisplay.None);
    }

    function handleOptionSelect(option: PickerDisplay) {
        onChangePickerDisplayOption?.(option);
        setSelectedOption(option);
    }

    function onChangeTemplateData(value: ValidatedTemplate | null) {
        if (value?.type === 'BuilderTemplate') {
            onSelectTemplate?.(BuilderTemplateId.from(value.id));
        }
        if (value?.type === 'BuildableTemplate') {
            onSelectBuildableTemplate?.(value.id);
        }
        if (isNullOrUndefined(value)) {
            onSelectTemplate?.(null);
            onSelectBuildableTemplate?.(null);
        }
    }

    switch (selectedOption) {
        case PickerDisplay.Category:
            return (
                <Grid onBack={handleBack}>
                    {locationId && (
                        <>
                            <DSGrid item xs={true}>
                                <PlannerCategoryDropdown
                                    categoryId={categoryId}
                                    eventDate={eventDate}
                                    locationId={locationId}
                                    setIsExpiring={setIsExpiring}
                                    onChange={onSelectCategory}
                                />
                            </DSGrid>
                            <DSGrid item>
                                <EndAdornment state={categoryId ? ValidationState.Valid : ValidationState.Invalid} />
                            </DSGrid>
                        </>
                    )}
                </Grid>
            );
        case PickerDisplay.None:
            return <Selector value={selectedOption} onChange={handleOptionSelect} />;
        case PickerDisplay.Template:
            return (
                <Grid onBack={handleBack}>
                    <PlannerTemplateSelector
                        clientId={clientId}
                        initialId={templateId ?? buildableTemplateId}
                        locationId={locationId}
                        onChange={onChangeTemplateData}
                    />
                </Grid>
            );
        default:
            return assertNever(selectedOption);
    }
}
PlannerCategoryOrTemplateSelector.displayName = 'PlannerCategoryOrTemplateSelector';

// This exists because the mvPlannerEventCtrl loads the planner asynchronously.
// We don't have the correct initial state if we just ask it directly.
function SelectorOrLoading(
    props: PlannerCategoryOrTemplateSelectorProps & { areLinkedResourcesLoaded: boolean },
): JSX.Element {
    if (props.areLinkedResourcesLoaded) {
        return <PlannerCategoryOrTemplateSelector {...props} />;
    }
    return <Loading size="small" />;
}
SelectorOrLoading.displayName = 'SelectorOrLoading';

export const PlannerCategoryOrTemplateSelectorWithAngular = withAngularIntegration(
    SelectorOrLoading,
    'plannerCategoryOrTemplateSelector',
    {
        areLinkedResourcesLoaded: OneWayBinding,
        buildableTemplateId: OneWayBinding,
        categoryId: OneWayBinding,
        clientId: OneWayBinding,
        eventDate: OneWayBinding,
        locationId: OneWayBinding,
        onChangePickerDisplayOption: ExpressionBinding,
        onSelectBuildableTemplate: ExpressionBinding,
        onSelectCategory: ExpressionBinding,
        onSelectTemplate: ExpressionBinding,
        setIsExpiring: ExpressionBinding,
        templateId: OneWayBinding,
    },
);
