import {
    Button,
    DSTypography,
    FileUpload,
    Loading,
    ModalBody,
    ModalFooter,
    ModalWithHeader,
    PuppetCheckedInput,
    TagsInput,
    Translate,
} from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { DateOnlyPicker } from '../../../common/form/dates/DateOnlyPicker';
import { AssetModalFormElementContainer } from '../styled';
import { useUploadAssetsState } from './state';
import { useUploadAssets } from './useUploadAssets';
import { useThumbnailPoller } from './useThumbnailPoller';

export type UploadAssetFilesModalProps = {
    folderOrCollectionId: string;
    show: boolean;
    onClose?: () => void;
};

export const UploadAssetFilesModal: React.FC<UploadAssetFilesModalProps> = ({ onClose = noop, show, ...props }) => (
    <ModalWithHeader
        closeOnBackdropClick={false}
        show={show}
        title={t('COMMON.UPLOAD_FILES')}
        onClose={() => onClose()}
    >
        <UploadAssetFilesModalContent onClose={onClose} {...props} />
    </ModalWithHeader>
);
UploadAssetFilesModal.displayName = 'UploadAssetFilesModal';

type UploadAssetFilesModalContentProps = {
    folderOrCollectionId: string;
    onClose: () => void;
};

const UploadAssetFilesModalContent: React.FC<UploadAssetFilesModalContentProps> = ({
    folderOrCollectionId,
    onClose,
}) => {
    const [tags, setTags] = React.useState<Array<{ id: string; tags: string[] }>>([{ id: 'dummy', tags: [] }]);
    const [expiryChecked, setExpiryChecked] = React.useState(false);
    const [expires, setExpires] = React.useState<Date | null>(null);

    const [fileState, fileDispatch] = useUploadAssetsState();

    const { assetsSaving, assetsUploading, isUploading, onClickSave, onSelectFiles } = useUploadAssets({
        expires,
        fileDispatch,
        fileState,
        folderOrCollectionId,
        onClose,
        tags: tags[0]?.tags ?? null,
    });

    useThumbnailPoller({
        fileDispatch,
        fileState,
    });

    return (
        <>
            <ModalBody>
                <FileUpload
                    completedFiles={fileState.completed.map(({ file, upload: { thumb256x256url } }) => ({
                        file,
                        thumbnail: thumb256x256url ? thumb256x256url.toString() : undefined,
                    }))}
                    dataCy="file-upload-input"
                    failedFiles={fileState.failed}
                    processingFiles={fileState.processing}
                    onRemoveFile={file => fileDispatch({ payload: file, type: 'REMOVE' })}
                    onSelectFiles={onSelectFiles}
                />
                <div className=" form-horizontal row">
                    <TagsInput label={t('COMMON.TAGS')} tags={tags} onChange={setTags} />
                    <br />
                    <AssetModalFormElementContainer>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">
                                <Translate keyId="COMMON.EXPIRY" />
                            </label>
                            <div className="col-sm-10">
                                <PuppetCheckedInput
                                    checked={expiryChecked}
                                    label={t('ASSET_LIBRARY.AUTOMATICALLY_EXPIRE_THESE_ASSETS')}
                                    value={'expiry'}
                                    onChange={ev => setExpiryChecked(ev.target.checked)}
                                />
                            </div>
                        </div>
                        {expiryChecked && <DateOnlyPicker value={expires} onChange={val => setExpires(val)} />}
                    </AssetModalFormElementContainer>
                </div>
                {assetsUploading > 0 && (
                    <DSTypography>{t('ASSET_LIBRARY.UPLOADING_ASSETS', { num: assetsUploading })}</DSTypography>
                )}
                {assetsSaving > 0 && (
                    <DSTypography>{t('ASSET_LIBRARY.SAVING_ASSETS', { num: assetsSaving })}</DSTypography>
                )}
                {isUploading && <Loading inline size="small" />}
            </ModalBody>
            <ModalFooter>
                <Button disabled={isUploading} onClick={() => onClose()}>
                    {t('COMMON.CANCEL')}
                </Button>
                <Button
                    dataCy="save-asset-button"
                    disabled={isUploading || fileState.processing.length !== 0 || fileState.completed.length <= 0}
                    theme="success"
                    onClick={onClickSave}
                >
                    {t('COMMON.SAVE')}
                </Button>
            </ModalFooter>
        </>
    );
};
UploadAssetFilesModalContent.displayName = 'UploadAssetFilesModalContent';
