import { gql, useQuery } from '@apollo/client';
import { Column, Loading, Row } from '@deltasierra/components';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AssetLibraryActions, AssetLibraryContext } from '../../context';
import { selectedAssets } from '../../../graphql';
import { useCurrentAssetContext } from '../../contexts';
import { AssetIdSwitch } from '../CollectionAssets/AssetIdSwitch';
import { NoCollectionsMessage } from '../NoCollectionsMessage';
import { CollectionListSection } from '../CollectionListSection';
import {
    GetCollectionsForAssetLibraryWithLocation,
    GetCollectionsForAssetLibraryWithLocationVariables,
} from './__graphqlTypes/GetCollectionsForAssetLibraryWithLocation';
import { LocationFragmentForAssetLibraryWithLocation } from './__graphqlTypes/LocationFragmentForAssetLibraryWithLocation';

const fragments = {
    LOCATION_FRAGMENT_FOR_ASSET_LIBRARY_WITH_LOCATION: gql`
        fragment LocationFragmentForAssetLibraryWithLocation on Location {
            id
            legacyId
        }
    `,
};

const GET_COLLECTIONS_FOR_ASSET_LIBRARY_WITH_LOCATION = gql`
    query GetCollectionsForAssetLibraryWithLocation($locationId: ID!) {
        location(id: $locationId) {
            id
            title
            client {
                id
                title
            }
            ...LocationFragmentForCollectionListSection
            ...LocationFragmentForAssetIdSwitch
            ...LocationFragmentForNoCollectionsMessage
        }
    }
    ${CollectionListSection.fragments.LOCATION_FRAGMENT_FOR_COLLECTION_LIST_SECTION}
    ${AssetIdSwitch.fragments.LOCATION_FRAGMENT_FOR_ASSET_ID_SWITCH}
    ${NoCollectionsMessage.fragments.LOCATION_FRAGMENT_FOR_NO_COLLECTIONS_MESSAGE}
`;

export interface AssetLibraryWithLocationProps {
    enableLightboxPreview: boolean;
    location: LocationFragmentForAssetLibraryWithLocation;
    actions?: AssetLibraryActions;
    initialAssetId?: string | null;
}

/**
 * AssetLibraryWithLocation
 * This is a full asset library, with collection selection, asset display, action dropdown etc
 * It does not however, have a location picker or anything setting it into a DS page
 * It is supposed to be rendered by `AssetLibraryPage` or `AssetLibraryModal`, both of which can provide the required location
 * This way, the asset library does not need to worry if a current location exists, it can do what it does best...
 * Which is librarying assets.
 *
 * @param props - AssetLibraryWithLocationProps
 * @param props.enableLightboxPreview - Should users be allowed to view files in a lightbox modal
 * @param props.location - A fragment of a Location, use provided fragment
 * @param props.initialAssetId - An asset id, used to pre-select an asset
 * @returns AssetLibraryWithLocation
 */
export function AssetLibraryWithLocation({
    enableLightboxPreview,
    initialAssetId,
    ...props
}: AssetLibraryWithLocationProps): JSX.Element {
    const [currentAsset, setCurrentAsset] = useCurrentAssetContext();

    const { data: collectionsData, loading: collectionsLoading } = useQuery<
        GetCollectionsForAssetLibraryWithLocation,
        GetCollectionsForAssetLibraryWithLocationVariables
    >(GET_COLLECTIONS_FOR_ASSET_LIBRARY_WITH_LOCATION, {
        fetchPolicy: 'network-only',
        variables: { locationId: props.location.id },
    });

    React.useEffect(() => {
        selectedAssets([]);
        if (!currentAsset) {
            setCurrentAsset(
              initialAssetId ||
              collectionsData?.location?.clientCollections?.edges?.[0]?.node?.id ||
              collectionsData?.location?.collections?.edges?.[0]?.node?.id,
            );
          }
    }, [collectionsData, setCurrentAsset, currentAsset, initialAssetId]);

    const actions = React.useMemo<AssetLibraryActions>(
        (): AssetLibraryActions => ({
            toggleAssetSelected: asset => {
                const selected = selectedAssets();
                if (selected.find(({ id }) => asset.id === id)) {
                    selectedAssets(selected.filter(({ id }) => asset.id !== id));
                } else {
                    selectedAssets([...selected, asset]);
                }
            },
        }),
        [],
    );

    if (!collectionsData?.location || collectionsLoading) {
        return <Loading />;
    }

    return (
        <Routes>
            <Route
                element={
                    <NoCollectionsMessage location={collectionsData.location}>
                        <Row>
                            <Column md={3}>
                                <CollectionListSection location={collectionsData.location} />
                            </Column>
                            <Column md={9}>
                                <AssetLibraryContext.Provider value={props.actions ?? actions}>
                                    <AssetIdSwitch
                                        enableLightboxPreview={enableLightboxPreview}
                                        location={collectionsData.location}
                                    />
                                </AssetLibraryContext.Provider>
                            </Column>
                        </Row>
                    </NoCollectionsMessage>
                }
                path=":collection?"
            />
        </Routes>
    );
}
AssetLibraryWithLocation.displayName = 'AssetLibraryWithLocation';
AssetLibraryWithLocation.fragments = fragments;
