import { DSBreadcrumbs } from '@deltasierra/components';
import * as React from 'react';
import { PathBreadcrumb, PathBreadcrumbProps } from './PathBreadcrumb';

export interface PathBreadcrumbsProps {
    breadcrumbs: PathBreadcrumbProps[];
}

/**
 * PathBreadcrumbs provides a list of links representing the url path
 * For each breadcrumb provide:
 * external - if the url is outside of ReactRouter, this will create an anchor
 * href (without an external) - to create a ReactRouter Link to a page within the router
 * display (only) - this displays just text that is not navigable, usually used for current page
 *
 * @param props - PathBreadcrumbsProps
 * @returns Breadcrumbs that returns links to the path provided
 */
export function PathBreadcrumbs(props: PathBreadcrumbsProps): JSX.Element {
    return (
        <DSBreadcrumbs aria-label="path">
            {props.breadcrumbs.map((crumb, index) => (
                <PathBreadcrumb {...crumb} key={`${index}${crumb.display}`} />
            ))}
        </DSBreadcrumbs>
    );
}
PathBreadcrumbs.displayName = 'PathBreadcrumbs';
