import { ExternalAuthApp, UpdateExternalAuthApp, CreateNewAuthApp } from '@deltasierra/shared';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';
import { InteractionUtils } from '../../common/interactionUtils';
import { ExternalAuthService } from '../externalAuthService';
import { $scopeSID } from '../../common/angularData';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import IScope = angular.IScope;

export interface ExternalAuthAppDetailsLocals {
    appToUpdate: () => ExternalAuthApp | null;
}

export class AuthAppDetailsController {

    readonly createNewAuthApp = this.interactionUtils.createFuture('Create new Auth App', () => {
        const payload: CreateNewAuthApp = {
            name: this.appDetails.name!,
            contactEmail: this.appDetails.contactEmail!,
            tokenEndpoint: this.appDetails.tokenEndpoint!,
            secret: this.appDetails.secret!,
        };
        return this.externalAuthService.createExternalAuthApp(payload).then(app => this.$modalInstance.close(app));
    });

    readonly updateAuthApp = this.interactionUtils.createFuture('Update Auth App', () => {
        const payload: UpdateExternalAuthApp = {
            name: this.appDetails.name!,
            contactEmail: this.appDetails.contactEmail!,
            tokenEndpoint: this.appDetails.tokenEndpoint!,
            secret: this.appDetails.secret || undefined,
        };
        return this.externalAuthService.updateExternalAuthApp(this.appToUpdate!.id, payload).then(app => this.$modalInstance.close(app));
    });

    isEditing = false;

    appDetails : {
        name : string | null;
        contactEmail : string | null;
        tokenEndpoint : string | null;
        secret : string | null;
    } = {
        name: null,
        contactEmail: null,
        tokenEndpoint: null,
        secret: null,
    };

    static readonly $inject : string[] = [
        $scopeSID,
        $modalInstanceSID,
        ('appToUpdate' as keyof ExternalAuthAppDetailsLocals),
        InteractionUtils.SID,
        ExternalAuthService.SID,
    ];

    constructor(
        private readonly $scope: IScope & { close: () => void },
        private readonly $modalInstance: ModalInstance,
        public readonly appToUpdate: ExternalAuthApp | null,
        private readonly interactionUtils: InteractionUtils,
        private readonly externalAuthService: ExternalAuthService,
    ) {
        if (this.appToUpdate) {
            this.isEditing = !!this.appToUpdate;
            this.appDetails = this.appToUpdate;
        }
        this.$scope.close = () => this.$modalInstance.dismiss();
    }

    close() {
        this.$modalInstance.dismiss();
    }
}
