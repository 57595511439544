import * as React from 'react';
import { createPortal } from 'react-dom';
import { noop } from '@deltasierra/object-utilities';
import { Omit } from '@deltasierra/shared';
import { Translate, TranslateProps } from '../../directives/Translate';
import { Button } from '../Button';

export type ModalProps = {
    children: React.ReactNode;
    onClose?: () => void;
    show: boolean;
};

/**
 * This is the base modal component. All other modals should extend from this.
 *
 * @example
 * <Modal show={this.state.showModal} onClose={() => this.setState({ showModal: false })} >
 *     <ModalHeader>This is a modal!</ModalHeader>
 *     <ModalBody></ModalBody>
 *     <ModalFooter>
 *         <Button onClick={() => this.setState({ showModal: false })}>Close Modal</Button>
 *     </ModalFooter>
 * </Modal>
 * @param props
 * @returns React.ReactElement
 * @see ModalHeader
 * @see ModalBody
 * @see ModalFooter
 */
export const Modal: React.FunctionComponent<ModalProps> = React.memo(({ children, onClose = noop, show }) => {
    const [shown, setShown] = React.useState(false);
    const [className, setClassName] = React.useState<'' | 'in'>('');

    React.useEffect(() => {
        let timeoutId: any; // The 'any' here is to get around an issue with tcat and the return value of setTimeout
        if (show) {
            setShown(true);
            timeoutId = setTimeout(() => setClassName('in'), 50);
        } else {
            setClassName('');
            timeoutId = setTimeout(() => setShown(false), 300);
        }
        return () => clearTimeout(timeoutId);
    }, [show]);

    return createPortal(
        <>
            {shown && (
                <>
                    <div
                        className={`modal-backdrop fade ${className}`}
                        style={{ display: 'block' }}
                        onClick={onClose}
                    />
                    <div className={`modal fade ${className}`} style={{ display: 'block' }} onClick={onClose}>
                        <div className="modal-dialog" onClick={event => event.stopPropagation()}>
                            <div className="modal-content">{children}</div>
                        </div>
                    </div>
                </>
            )}
        </>,
        document.body,
    );
});
Modal.displayName = 'Modal';

export type ModalHeaderProps = {
    title?: TranslateProps;
    children?: React.ReactNode;
};

/**
 * Header for a modal. You can specify either a title or children props.
 *
 * @example
 * // Simple usage with a translated title
 * <ModalHeader title={{ keyId='COMMON.MODAL.TITLE' }}></ModalHeader>
 *
 * @example
 * // Using custom content
 * <ModalHeader>
 *     <img src="/my-cool-image.png" alt="Modal title" />
 * </ModalHeader>
 * @param props - Props
 * @param props.title - title
 * @param props.children - React.ReactNode
 * @returns React.ReactElement
 */
export const ModalHeader: React.FunctionComponent<ModalHeaderProps> = ({ children, title }) => (
    <div className="modal-header">
        {title && (
            <h3 className="modal-title">
                <Translate {...title} />
            </h3>
        )}
        {!title && children}
    </div>
);
ModalHeader.displayName = 'ModalHeader';

export const ModalBody: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <div className="modal-body">{children}</div>
);
ModalBody.displayName = 'ModalBody';

export const ModalFooter: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <div className="modal-footer">{children}</div>
);
ModalFooter.displayName = 'ModalFooter';

export type ModalWithHeader = ModalProps & {
    title: TranslateProps;
};

/**
 * Simplified modal component with a title.
 *
 * @example
 * <ModalWithHeader title={{ keyId: 'COMMON.MY_MODAL_TITLE' }}>
 *     // Insert modal contents...
 * </ModalWithHeader>
 * @param props - Props
 * @returns React.ReactElement
 */
export const ModalWithHeader: React.FunctionComponent<ModalWithHeader> = React.memo(
    ({ children, onClose, show, title }) => (
        <Modal show={show} onClose={onClose}>
            <ModalHeader title={title} />
            {children}
        </Modal>
    ),
);
ModalWithHeader.displayName = 'BaseModal';

export type ConfirmModalProps = Omit<ModalWithHeader, 'children'> & {
    cancelText?: TranslateProps;
    confirmText: TranslateProps;
    message: TranslateProps;
    onCancel?: () => void;
    onConfirm?: () => void;
};

/**
 * Confirmation modal component.
 *
 * @param props - Props
 * @returns React.ReactElement
 */
export const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = props => {
    const { message, onCancel = noop, onClose = noop, onConfirm = noop } = props;

    const onCancelOrDefault: () => void = React.useCallback(() => {
        onCancel();
        onClose();
    }, [onCancel, onClose]);

    const onConfirmOrDefault: () => void = React.useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    return (
        <ModalWithHeader {...props}>
            <ModalBody>
                <Translate {...message} />
            </ModalBody>
            <ModalFooter>
                <Button label={props.cancelText || { keyId: 'COMMON.CANCEL' }} onClick={onCancelOrDefault} />
                <Button
                    data-cy="confirm-button"
                    label={props.confirmText}
                    theme="primary"
                    onClick={onConfirmOrDefault}
                />
            </ModalFooter>
        </ModalWithHeader>
    );
};
ConfirmModal.displayName = 'ConfirmModal';
