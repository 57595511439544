import { gql, useQuery } from '@apollo/client';
import { Dropdown } from '@deltasierra/components';
import { noop } from '@deltasierra/object-utilities';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { relayConnectionToArray } from '../../../graphql/utils';
import { PlannerCategoryFragment } from './PlannerCategoryFragment';
import {
    GetCategorysForPlannerPage,
    GetCategorysForPlannerPageVariables,
} from './__graphqlTypes/GetCategorysForPlannerPage';
import { PlannerCategoryFragment as PlannerCategory } from './__graphqlTypes/PlannerCategoryFragment';

const GET_CATEGORIES_FOR_PLANNER_PAGE = gql`
    query GetCategorysForPlannerPage($locationId: ID!) {
        location(id: $locationId) {
            id
            builderTemplateCategoriesConnection {
                edges {
                    node {
                        id
                        ...PlannerCategoryFragment
                    }
                }
            }
        }
    }
    ${PlannerCategoryFragment}
`;

export interface PlannerCategoryDropdownProps {
    locationId: string;
    categoryId: number | null;
    eventDate: Date;
    onChange?: (categoryId: number | null) => void;
    setIsExpiring?: (isExpiring: boolean) => void;
}

export function PlannerCategoryDropdown({
    categoryId = null,
    eventDate,
    locationId,
    onChange = noop,
    setIsExpiring = noop,
}: PlannerCategoryDropdownProps): JSX.Element {
    const { data } = useQuery<GetCategorysForPlannerPage, GetCategorysForPlannerPageVariables>(
        GET_CATEGORIES_FOR_PLANNER_PAGE,
        {
            variables: {
                locationId,
            },
        },
    );
    const [selectedCategory, setSelectedCategory] = React.useState<PlannerCategory | null>(null);
    const [filter, setFilter] = React.useState('');

    const dropdownItems: PlannerCategory[] = React.useMemo(
        () =>
            relayConnectionToArray(data?.location?.builderTemplateCategoriesConnection).filter(({ title }) =>
                title.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
            ),
        [data?.location?.builderTemplateCategoriesConnection, filter],
    );

    const nullOption = { legacyId: null, title: 'None' };

    React.useEffect(() => {
        // Setting selected category state based on existing category id
        if (categoryId && dropdownItems.length > 0) {
            const preselectedCategory = dropdownItems.find(item => item.legacyId === categoryId);
            setSelectedCategory(preselectedCategory ? preselectedCategory : null);
        }
    }, [categoryId, dropdownItems]);

    const checkExpiring = (category: PlannerCategory | null) => {
        if (category === null) {
            setIsExpiring(false);
            return;
        }
        const isExpiring = category && category.endDate !== null && new Date(category.endDate) < eventDate;
        setIsExpiring(isExpiring);
    };

    return (
        <Dropdown
            filterable
            getOptionTemplate={item => <p>{item.title}</p>}
            getSelectedTemplate={item => <>{item.title}</>}
            getValue={value => value.title}
            items={[nullOption, ...dropdownItems]}
            menuStyle={{ maxHeight: '300px', overflowY: 'auto', width: '100%' }}
            selectText={t('PLAN.ASSOCIATED_BUILDER_RESOURCES.SELECT_A_TEMPLATE_CATEGORY')}
            value={selectedCategory}
            onFilterChange={setFilter}
            onSelect={value => {
                onChange(value.legacyId);
                const selected = dropdownItems.find(item => item.legacyId === value.legacyId);
                setSelectedCategory(selected ?? null);
                checkExpiring(selected ?? null);
            }}
        />
    );
}

PlannerCategoryDropdown.displayName = 'PlannerCategoryDropdown';
