import { User, Roles, canPublish, canUsePlanner, LocationId } from '@deltasierra/shared';


const BOOTSTRAPPED_USER_OBJECT_KEY = 'bootstrappedUserObject';

export class MvIdentity {
    public static readonly SID = 'mvIdentity';

    public static readonly $inject: string[] = ['$window', '$rootScope'];

    public currentUser: User | undefined;

    public readonly DEFAULT_PROFILE_PICTURE_URL: string = '/img/user-avatar.png';

    public constructor($window: ng.IWindowService, private $rootScope: ng.IRootScopeService) {
        if ($window[BOOTSTRAPPED_USER_OBJECT_KEY]) {
            this.currentUser = JSON.parse(
                Buffer.from($window[BOOTSTRAPPED_USER_OBJECT_KEY], 'base64').toString('utf8'),
                (key, value: unknown) => (key === '__proto__' ? undefined : value),
            );
        }

        this.$rootScope.$on('unauthenticated', () => {
            this.currentUser = undefined;
        });
    }

    public isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    public hasRole(role: Roles): boolean {
        return this.isAuthenticated() && (this.currentUser?.roles || []).indexOf(role) > -1;
    }

    public isAuthorized(role: Roles): boolean {
        return this.hasRole(role);
    }

    public isConnectToAccess(): boolean {
        return this.hasRole(Roles.connectToAccess);
    }

    public isAdmin(): boolean {
        return this.hasRole(Roles.admin);
    }

    public isManager(): boolean {
        return this.hasRole(Roles.manager);
    }

    public isClient(): boolean {
        return this.hasRole(Roles.client);
    }

    public isAnalyst(): boolean {
        return this.hasRole(Roles.analyst);
    }

    public isBuilder(): boolean {
        return this.hasRole(Roles.builder);
    }

    public isSpecialRequester(): boolean {
        return this.hasRole(Roles.specialRequester);
    }

    public canPublish(): boolean {
        return !!this.currentUser && canPublish(this.currentUser);
    }

    public canAccessBuilder(): boolean {
        return this.isManager() || this.isAuthorized(Roles.builder);
    }

    public canAccessPlanner(): boolean {
        return !!this.currentUser && canUsePlanner(this.currentUser);
    }

    public getFirstName(): string | null {
        return this.currentUser ? this.currentUser.firstName : null;
    }

    public getDefaultLocationId(): LocationId | null {
        return this.currentUser ? this.currentUser.defaultLocationId : null;
    }

    public getProfilePictureUrl(preferThumbnail = true): string | null {
        if (this.isAuthenticated()) {
            const profilePicture = this.currentUser!.profilePicture;
            let url: string | null | undefined =
                profilePicture && ((preferThumbnail && profilePicture.thumb256x256url) || profilePicture.url);
            url ||= this.DEFAULT_PROFILE_PICTURE_URL;
            return url;
        } else {
            return null;
        }
    }
}

angular.module('app').service(MvIdentity.SID, MvIdentity);
