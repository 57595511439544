import * as React from 'react';
import { Trigger } from 'bootstrap';
import { t } from '@deltasierra/shared';
import { OneWayBinding, OptionalOneWayBinding } from '../../../../common/angularData';
import { usePopover } from '../../../../common/popover';
import { withAngularIntegration } from '../../../../common/componentUtils/reactComponentRegistration';

type AngularExposedHelpIconWithPopoverProp = {
    text: string;
    helpDocLink?: string;
    trigger?: Trigger;
};

const AngularExposedHelpIconWithPopover: React.FunctionComponent<AngularExposedHelpIconWithPopoverProp> = ({
    helpDocLink,
    text,
    trigger,
}) => {
    const ref = usePopover({
        content: (
            <>
                <div>{text}</div>
                {helpDocLink && (
                    <div>
                        <a href={helpDocLink} rel="noreferrer" target="noopener noreferrer _blank">
                            {t('COMMON.LEARN_MORE')}
                        </a>
                    </div>
                )}
            </>
        ),
        trigger,
    });
    return <i className="fa fa-question-circle hint-icon" ref={ref} style={{ cursor: 'pointer' }} />;
};

export default withAngularIntegration(AngularExposedHelpIconWithPopover, 'angularExposedHelpIconWithPopover', {
    helpDocLink: OptionalOneWayBinding,
    text: OneWayBinding,
    trigger: OptionalOneWayBinding,
});
