/* eslint-disable camelcase */

import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Dropdown, FormGroup, Loader, WizardStepProps } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { Translate } from '../../../../../directives/Translate';
import {
    GetMailchimpLists,
    GetMailchimpListsVariables,
    GetMailchimpLists_mailchimp_Mailchimp_lists_edges_node,
} from './__graphqlTypes/GetMailchimpLists';
import {
    GetMailchimpListSegments,
    GetMailchimpListSegmentsVariables,
    GetMailchimpListSegments_mailchimp_Mailchimp_listSegments_edges_node,
} from './__graphqlTypes/GetMailchimpListSegments';

const GET_MAILCHIMP_LISTS = gql`
    query GetMailchimpLists($mailchimpId: ID!) {
        mailchimp(id: $mailchimpId) {
            ... on Mailchimp {
                id
                lists {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const GET_MAILCHIMP_LIST_SEGMENTS = gql`
    query GetMailchimpListSegments($mailchimpId: ID!, $listId: String!) {
        mailchimp(id: $mailchimpId) {
            ... on Mailchimp {
                id
                ... on Mailchimp {
                    id
                    listSegments(listId: $listId) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export type MailchimpListDropdownProps = {
    mailchimpId: string;
    onSelect: (list: GetMailchimpLists_mailchimp_Mailchimp_lists_edges_node) => void;
    selected?: GetMailchimpLists_mailchimp_Mailchimp_lists_edges_node;
};

export const MailchimpListDropdown: React.FC<MailchimpListDropdownProps> = ({ mailchimpId, onSelect, selected }) => {
    const { data, loading } = useQuery<GetMailchimpLists, GetMailchimpListsVariables>(GET_MAILCHIMP_LISTS, {
        variables: {
            mailchimpId,
        },
    });

    const listOptions =
        data?.mailchimp.__typename === 'Mailchimp' && data.mailchimp.lists.edges.length
            ? data.mailchimp.lists.edges.map(edge => edge.node)
            : undefined;

    return (
        <>
            <Loader loading={loading}>
                {!listOptions && (
                    <div className="alert alert-warning">
                        <Translate keyId="BUILD.PUBLISH.MAILCHIMP.NO_LISTS_FOUND_SET_UP_YOUR_MAILING_LISTS" />
                    </div>
                )}
                {listOptions && (
                    <Dropdown
                        getOptionTemplate={listNode => <>{listNode.name}</>}
                        getValue={listNode => listNode.id}
                        items={listOptions}
                        menuStyle={{ maxHeight: '25vh', overflowY: 'scroll', width: '100%' }}
                        value={selected || null}
                        onSelect={onSelect}
                    />
                )}
            </Loader>
        </>
    );
};

export type MailchimpListSegmentDropdownProps = {
    listId: string;
    mailchimpId: string;
    onSelect: (listSegment?: GetMailchimpListSegments_mailchimp_Mailchimp_listSegments_edges_node) => void;
    selected?: GetMailchimpListSegments_mailchimp_Mailchimp_listSegments_edges_node;
};

export const MailchimpListSegmentDropdown: React.FC<MailchimpListSegmentDropdownProps> = ({
    listId,
    mailchimpId,
    onSelect,
    selected,
}) => {
    const { data, loading } = useQuery<GetMailchimpListSegments, GetMailchimpListSegmentsVariables>(
        GET_MAILCHIMP_LIST_SEGMENTS,
        {
            variables: {
                listId,
                mailchimpId,
            },
        },
    );

    const listSegmentOptions =
        data?.mailchimp.__typename === 'Mailchimp' && data.mailchimp.listSegments.edges.length
            ? data.mailchimp.listSegments.edges.map(edge => edge.node)
            : undefined;

    return (
        <>
            <Loader loading={loading}>
                {!listSegmentOptions && (
                    <div className="alert alert-warning">
                        <Translate keyId="BUILD.PUBLISH.MAILCHIMP.NO_SEGMENTS_FOUND" />
                    </div>
                )}
                {listSegmentOptions && (
                    <Dropdown
                        clearable
                        getOptionTemplate={listSegmentNode => <>{listSegmentNode.name}</>}
                        getValue={listSegmentNode => listSegmentNode.id.toString()}
                        items={listSegmentOptions}
                        menuStyle={{ maxHeight: '25vh', overflowY: 'scroll', width: '100%' }}
                        value={selected || null}
                        onClear={() => onSelect(undefined)}
                        onSelect={onSelect}
                    />
                )}
            </Loader>
        </>
    );
};

export type PublishToMailchimpStep1Data = {
    selectedList: GetMailchimpLists_mailchimp_Mailchimp_lists_edges_node;
    selectedListSegment?: GetMailchimpListSegments_mailchimp_Mailchimp_listSegments_edges_node;
};

export type PublishToMailchimpStep1Props = WizardStepProps<PublishToMailchimpStep1Data> & {
    mailchimpId: string;
    initialData?: PublishToMailchimpStep1Data;
};

export const PublishToMailchimpStep1: React.FC<PublishToMailchimpStep1Props> = ({
    CancelButton,
    initialData,
    mailchimpId,
    NextButton,
    StepCount,
}) => {
    const [selectedList, updateSelectedList] = React.useState<
        GetMailchimpLists_mailchimp_Mailchimp_lists_edges_node | undefined
    >(initialData?.selectedList);
    const [selectedListSegment, updateSelectedListSegment] = React.useState<
        GetMailchimpListSegments_mailchimp_Mailchimp_listSegments_edges_node | undefined
    >(initialData?.selectedListSegment);

    return (
        <>
            <FormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.LISTS')}>
                <MailchimpListDropdown
                    mailchimpId={mailchimpId}
                    selected={selectedList}
                    onSelect={list => updateSelectedList(list)}
                />
            </FormGroup>
            {selectedList && (
                <FormGroup fullWidth label={t('BUILD.PUBLISH.MAILCHIMP.SEGMENTS')}>
                    <MailchimpListSegmentDropdown
                        listId={selectedList.id}
                        mailchimpId={mailchimpId}
                        selected={selectedListSegment}
                        onSelect={listSegment => updateSelectedListSegment(listSegment)}
                    />
                </FormGroup>
            )}
            <FormGroup fullWidth>
                <div className="pull-left">
                    <StepCount />
                </div>
                <div className="pull-right">
                    <CancelButton />
                    <NextButton
                        dataToSave={selectedList ? { selectedList, selectedListSegment } : undefined}
                        disabled={!selectedList}
                    />
                </div>
            </FormGroup>
        </>
    );
};
