/**
 * A collection of components to assist with async operations.
 */

import * as React from 'react';
import { Loading } from '../Loading';
import { FutureStateWithoutPending, FutureState } from '../futures/types';

export type FutureLoadingSuspenseProps<TData extends any[]> =
    | {
        /**
         * A render prop to invoke once all the data has been fetched.
         *
         * @param args - The data that was fetched
         */
        children: (...args: TData) => any; // This is typed 'any' because it conflicts with how children is usually defined
        /**
         * The data retrievals to wait for
         */
        waitFor: { [K in keyof TData]: FutureState<TData[K]> };
        /**
         * Optional element to display when one of the data fetch requests fail.
         */
        error?: () => React.ReactElement;
        /**
         * Optionally override the loading indicator. This defaults to the Loading component.
         *
         * @see Loading
         */
        loading?: () => React.ReactElement;
    }
    | { waitFor: Array<FutureState<any>>; children: () => any; error?: () => React.ReactElement; loading?: () => React.ReactElement };

/**
 * Display a loading icon until *all* the data has been fetched successfully.
 *
 * @param root0
 * @param root0.children
 * @param root0.error
 * @param root0.loading
 * @param root0.waitFor
 * @example
 * const MyComponent: React.FunctionComponent = () => {
 *     const fetchUsers = useFetchUsers();
 *     const fetchTemplates = useFetchTemplates();
 *     return (
 *         <DataLoadingSuspense waitFor={[fetchUsers, fetchTemplates]}>
 *             {(users, templates) => (
 *                 <>
 *                     <div>Users: {users}</div>
 *                     <div>Templates: {templates}</div>
 *                 </>
 *             )}
 *         </DataLoadingSuspense>
 *     )
 * }
 * @returns React.ReactElement
 */
export function FutureLoadingSuspense<TData extends any[]>({ children, error, loading, waitFor }: FutureLoadingSuspenseProps<TData>) {
    if (waitFor.some(x => x.isFailed)) {
        return error ? error() : <></>;
    } else if (waitFor.some(x => x.isLoading || x.isPending)) {
        return loading ? loading() : <Loading />;
    } else if (waitFor.every(x => x.isFinished)) {
        const results = waitFor.map(response => response.isFinished && response.value) as TData;
        return children(...results);
    }
}

export type DataLoadingSuspenseBaseProps<TData extends any[]> = {
    /**
     * A render prop to invoke once all the data has been fetched.
     *
     * @param args - The data that was fetched
     */
    children: (...args: TData) => any; // This is typed 'any' because it conflicts with how children is usually defined
    /**
     * The data retrievals to wait for
     */
    waitFor: { [K in keyof TData]: FutureStateWithoutPending<TData[K]> };
};

export type WithError = {
    /**
     * Optional element to display when one of the data fetch requests fail.
     */
    error?: () => React.ReactElement;
};

export type WithLoading = {
    /**
     * Optionally override the loading indicator. This defaults to the Loading component.
     *
     * @see Loading
     */
    loading?: () => React.ReactElement;
};

export type DataLoadingSuspenseWithErrorProps<TData extends any[]> = DataLoadingSuspenseBaseProps<TData> & WithError;
export type DataLoadingSuspenseWithLoadingProps<TData extends any[]> = DataLoadingSuspenseBaseProps<TData> & WithLoading;
export type DataLoadingSuspenseProps<TData extends any[]> = DataLoadingSuspenseBaseProps<TData> & WithError & WithLoading;

/**
 * Display a loading icon until *all* the data has been fetched successfully.
 *
 * @example
 * const MyComponent: React.FunctionComponent = () => {
 *     const fetchUsers = useFetchUsers();
 *     const fetchTemplates = useFetchTemplates();
 *     return (
 *         <DataLoadingSuspense
 *             waitFor={[fetchUsers, fetchTemplates]}
 *             loading={() => <div>{'Loading...'}</div}
 *         >
 *             {(users, templates) => (
 *                 <>
 *                     <div>Users: {users}</div>
 *                     <div>Templates: {templates}</div>
 *                 </>
 *             )}
 *         </DataLoadingSuspense>
 *     )
 * }
 * @returns React.ReactElement
 */

export function DataLoadingSuspenseWithLoading<TData extends any[]>({
    children,
    loading,
    waitFor,
}: DataLoadingSuspenseWithLoadingProps<TData>) {
    if (waitFor.some(x => x.isLoading)) {
        return loading ? loading() : <Loading />;
    } else if (waitFor.every(x => x.isFinished)) {
        const results = waitFor.map(response => response.isFinished && response.value) as TData;
        return children(...results);
    }
}

/**
 * Display an error component or nothing if any of the data fetching fails.
 *
 * @example
 * const MyComponent: React.FunctionComponent = () => {
 *     const fetchUsers = useFetchUsers();
 *     const fetchTemplates = useFetchTemplates();
 *     return (
 *         <DataLoadingSuspense
 *             waitFor={[fetchUsers, fetchTemplates]}
 *             error={() => <div>{'There was an error!'}</div}
 *         >
 *             {(users, templates) => (
 *                 <>
 *                     <div>Users: {users}</div>
 *                     <div>Templates: {templates}</div>
 *                 </>
 *             )}
 *         </DataLoadingSuspense>
 *     )
 * }
 * @returns React.ReactElement
 */

export function DataLoadingSuspenseWithError<TData extends any[]>({ children, error, waitFor }: DataLoadingSuspenseWithErrorProps<TData>) {
    if (waitFor.some(x => x.isFailed)) {
        return error ? error() : <></>;
    } else if (waitFor.every(x => x.isFinished)) {
        const results = waitFor.map(response => response.isFinished && response.value) as TData;
        return children(...results);
    }
}

/**
 * Display a loading icon until *all* the data has been fetched successfully.
 *
 * @param root0
 * @param root0.children
 * @param root0.error
 * @param root0.loading
 * @param root0.waitFor
 * @example
 * const MyComponent: React.FunctionComponent = () => {
 *     const fetchUsers = useFetchUsers();
 *     const fetchTemplates = useFetchTemplates();
 *     return (
 *         <DataLoadingSuspense waitFor={[fetchUsers, fetchTemplates]}>
 *             {(users, templates) => (
 *                 <>
 *                     <div>Users: {users}</div>
 *                     <div>Templates: {templates}</div>
 *                 </>
 *             )}
 *         </DataLoadingSuspense>
 *     )
 * }
 * @returns React.ReactElement
 */
export function DataLoadingSuspense<TData extends any[]>({ children, error, loading, waitFor }: DataLoadingSuspenseProps<TData>) {
    if (waitFor.some(x => x.isFailed)) {
        return error ? error() : <></>;
    } else if (waitFor.some(x => x.isLoading)) {
        return loading ? loading() : <Loading />;
    } else if (waitFor.every(x => x.isFinished)) {
        const results = waitFor.map(response => response.isFinished && response.value) as TData;
        return children(...results);
    }
}
