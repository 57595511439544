import { ApolloClient, useApolloClient } from '@apollo/client';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import * as Sentry from '@sentry/browser';
import { BuilderTemplateApi, justParams, Result, resultFailure, resultSuccess } from '@deltasierra/shared';
import { isNotNullOrUndefined, isNullOrUndefined } from '@deltasierra/type-utilities';
import * as React from 'react';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { invokeApiRoute } from '../../../../../common/httpUtils';
import { GET_BUILDABLE_TEMPLATE } from './GetBuildableTemplate.query';
import { GetBuildableTemplate, GetBuildableTemplateVariables } from './__graphqlTypes/GetBuildableTemplate';

const TEMPLATE_PARAM = 'template';
const BUILDABLE_TEMPLATE_ID_PARAM = QueryParameter.TemplateId;

function extractTemplateIdIdFromTemplateUrl(url: string): number | null {
    const urlObject = new URL(url)
    const params = urlObject.searchParams;
    const path = urlObject.pathname;
    const templateId = params.get(TEMPLATE_PARAM);
    if (
        isNotNullOrUndefined(templateId) &&
        !isNaN(Number(templateId)) &&
        (path === '/contentBuilder' || path === '/emailBuilder')
    ) {
        return Number(templateId);
    }
    return null;
}

function extractBuildableTemplateIdFromTemplateUrl(url: string): string | null {
    const urlObject = new URL(url)
    const params = urlObject.searchParams;
    const buildableTemplateId = params.get(BUILDABLE_TEMPLATE_ID_PARAM);
    if (isNotNullOrUndefined(buildableTemplateId)) {
        return buildableTemplateId;
    }
    return null;
}

export type ValidatedTemplateType = 'BuildableTemplate' | 'BuilderTemplate';

export type BuilderTemplate = {
    id: number;
    type: 'BuilderTemplate';
    graphqlId: string;
    thumbnail?: string;
 }

 export type BuildableTemplate = {
    id: string;
    type: 'BuildableTemplate';
    graphqlId: string;
    thumbnail?: string;
 };

 export type ValidatedTemplate = BuildableTemplate | BuilderTemplate;

async function getTemplateOrNull(
    httpService: ng.IHttpService,
    templateId: number,
): Promise<Result<null, BuilderTemplate>> {
    try {
        const response = await invokeApiRoute(
            httpService,
            BuilderTemplateApi.getBuilderTemplate,
            justParams({ templateId }),
        );
        if (response) {
            const { compositeImage, graphqlId, id } = response;
            return resultSuccess({
                graphqlId,
                id,
                thumbnail: compositeImage?.thumb256x256url ?? undefined,
                type: 'BuilderTemplate',
            });
        }
        return resultFailure(null);
    } catch (error) {
        Sentry.captureException(error);
        return resultFailure(null);
    }
}

async function getBuildableTemplateOrNull(
    buildableTemplateId: string,
    apolloClient: ApolloClient<unknown>,
): Promise<Result<null, BuildableTemplate>> {
    try {
        const response = await apolloClient.query<
            GetBuildableTemplate,
            GetBuildableTemplateVariables
        >({
            query: GET_BUILDABLE_TEMPLATE,
            variables: {
                id: buildableTemplateId,
            },
        });
        if (response) {
            const { data: { buildableEmailTemplate } } = response;
            if (isNullOrUndefined(buildableEmailTemplate)) {
                return resultFailure(null);
            }
            if (buildableEmailTemplate.__typename === 'BuildableEmailTemplate') {
                const { currentVersion: { thumbnailUrl }, id } = buildableEmailTemplate;
                return resultSuccess({
                    graphqlId: id,
                    id,
                    thumbnail: thumbnailUrl ?? undefined,
                    type: 'BuildableTemplate',
                });
            }
        }
        return resultFailure(null);
    } catch {
        return resultFailure(null);
    }
}

export function useValidateTemplateId(): (
    url: string,
) => Promise<Result<null, ValidatedTemplate>> {
    const httpService = useAngularServiceContext('$http');
    const apolloClient = useApolloClient();

    const validate = React.useCallback(
        async (url: string) => {
            try {
                const templateId = extractTemplateIdIdFromTemplateUrl(url);
                if (isNotNullOrUndefined(templateId)) {
                    return await getTemplateOrNull(httpService, templateId);
                }
                const builderTemplateId = extractBuildableTemplateIdFromTemplateUrl(url);
                if (isNotNullOrUndefined(builderTemplateId)) {
                    return await getBuildableTemplateOrNull(builderTemplateId, apolloClient);
                }
                return resultFailure(null);
            } catch {
                return resultFailure(null);
            }
        },
        [apolloClient, httpService],
    );

    return validate;
}
