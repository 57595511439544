import { DSAlert, DSAlertTitle, Translate } from '@deltasierra/components';
import { withStyles } from '@material-ui/core';
import * as React from 'react';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';

const StyledDSAlert = withStyles({
    root: {
        alignItems: 'flex-start',
        marginBottom: '2rem',
        boxShadow: 'none',
        fontSize: 14,
    },
    icon: {
        fontSize: 22,
    },
    message: {
        fontSize: 14,
        color: 'inherit',
    },
    standardInfo: {
        backgroundColor: '#F0EAFF',
        color: '#5223D7',
        '& .icon': {
            color: '#6B30FA',
        },
    },
})(DSAlert);

const StyledDSAlertTitle = withStyles({
    root: {
        paddingTop: 2,
        fontSize: 16,
        color: 'inherit',
    },
})(DSAlertTitle);

function AlertFitwareLimitations(): JSX.Element {
    return (
        <StyledDSAlert classes={{ icon: 'icon' }} severity="info">
            <StyledDSAlertTitle>
                <Translate keyId="REPORT.FITWARE_MEMBER_ENGAGER.REPORT_LIMITATIONS.TITLE" />
            </StyledDSAlertTitle>
            <Translate keyId="REPORT.FITWARE_MEMBER_ENGAGER.REPORT_LIMITATIONS.MESSAGE" />
        </StyledDSAlert>
    );
}
AlertFitwareLimitations.displayName = 'AlertFitwareLimitations';

export const AlertFitwareLimitationsWithAngular = withAngularIntegration(
    AlertFitwareLimitations,
    'alertFitwareLimitations',
    {},
);
