import * as React from 'react';
import { ModalWithHeader } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding, ExpressionBinding } from '../../common/angularData';
import LocationDetailsFormikWrapper, { FormValues } from './LocationDetailsModalFormikWrapper';
import LocationDetailsModalBody from './LocationDetailsModalBody';

export type LocationDetailsModalProps = {
    show: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    onValidationError: (error: Error) => void;
};

const LocationDetailsModal: React.FC<LocationDetailsModalProps> = ({ onCancel, onSubmit, onValidationError, show }) => {
    const renderBody = (props: FormikProps<FormValues>) => <LocationDetailsModalBody {...props} onCancel={onCancel} />;

    return (
        <ModalWithHeader show={show} title={t('ACCOUNT.DETAILS.LOCATION_DETAILS')}>
            {show && <LocationDetailsFormikWrapper render={renderBody} onFinish={onSubmit} onValidationError={onValidationError} />}
        </ModalWithHeader>
    );
};
LocationDetailsModal.displayName = 'LocationDetailsModal';

export const LocationDetailsModalWithAngular = withAngularIntegration(LocationDetailsModal, 'locationDetailsModal', {
    onCancel: ExpressionBinding,
    onSubmit: ExpressionBinding,
    onValidationError: ExpressionBinding,
    show: OneWayBinding,
});

export default LocationDetailsModal;
