import { gql } from '@apollo/client/core';

export const GET_BUILDABLE_TEMPLATE = gql`
    query GetBuildableTemplate($id: ID!) {
        buildableEmailTemplate(id: $id) {
            __typename
            ... on BuildableTemplate {
                id
                currentVersion {
                    id
                    thumbnailUrl
                }
            }
        }
    }
`;