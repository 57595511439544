/* eslint-disable max-lines-per-function, max-lines, max-statements */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    AssetShareTarget,
    AssetShareTargetGroup,
    ClientPicker,
    DSButton,
    DSConfirmButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSGrid,
    DSLoadMoreButton,
    DSPaper,
    DSTextField,
    DSTypography,
    Loading,
    LocationPicker,
    SelectedClientsOrLocations,
} from '@deltasierra/components';
import { useControlledSearchInput } from '@deltasierra/react-hooks';
import { filterUniqueBy } from '@deltasierra/array-utilities';
import { t } from '@deltasierra/shared';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import * as React from 'react';
import { DeleteCollectionsInput, EditCollectionInput } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import { relayConnectionToArray } from '../../../graphql/utils';
import { useCurrentAssetContext } from '../../contexts';
import { ClientFragmentForEditCollectionModal } from './__graphqlTypes/ClientFragmentForEditCollectionModal';
import {
    DeleteCollectionForEditCollectionModal,
    DeleteCollectionForEditCollectionModalVariables,
} from './__graphqlTypes/DeleteCollectionForEditCollectionModal';
import {
    EditCollectionForEditCollectionModal,
    EditCollectionForEditCollectionModalVariables,
} from './__graphqlTypes/EditCollectionForEditCollectionModal';
import {
    GetClientsAndLocationsForEditCollectionModal,
    GetClientsAndLocationsForEditCollectionModalVariables,
    GetClientsAndLocationsForEditCollectionModal_me_locations_edges_node,
} from './__graphqlTypes/GetClientsAndLocationsForEditCollectionModal';
import {
    GetClientsForEditCollectionModal,
    GetClientsForEditCollectionModalVariables,
} from './__graphqlTypes/GetClientsForEditCollectionModal';
import {
    GetCurrentClientForEditCollectionModal,
    GetCurrentClientForEditCollectionModalVariables,
} from './__graphqlTypes/GetCurrentClientForEditCollectionModal';

const LOCATION_FRAGMENT_FOR_EDIT_COLLECTION_MODAL = gql`
    fragment LocationFragmentForEditCollectionModal on Location {
        id
        title
        client {
            id
        }
        collectionLimits {
            current
            max
        }
    }
`;

const CLIENT_FRAGMENT_FOR_EDIT_COLLECTION_MODAL = gql`
    fragment ClientFragmentForEditCollectionModal on Client {
        id
        title
        legacyId
        collectionLimits {
            current
            max
        }
    }
`;

const GET_CLIENTS_FOR_EDIT_COLLECTION_MODAL_QUERY = gql`
    query GetClientsForEditCollectionModal($after: String, $first: Int, $title: String) {
        me {
            id
            agency {
                id
                clients(after: $after, first: $first, filter: { title: $title }) {
                    edges {
                        node {
                            id
                            ...ClientFragmentForEditCollectionModal
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_EDIT_COLLECTION_MODAL}
`;

const GET_CURRENT_CLIENT_FOR_EDIT_COLLECTION_MODAL_QUERY = gql`
    query GetCurrentClientForEditCollectionModal($id: ID!) {
        client(id: $id) {
            id
            ...ClientFragmentForEditCollectionModal
        }
    }
    ${CLIENT_FRAGMENT_FOR_EDIT_COLLECTION_MODAL}
`;

const GET_LOCATIONS_FOR_EDIT_COLLECTION_MODAL_QUERY = gql`
    query GetClientsAndLocationsForEditCollectionModal(
        $locationId: ID!
        $hasLocation: Boolean! = false
        $collectionId: ID!
    ) {
        me {
            id
            locations(first: 10000) {
                edges {
                    node {
                        id
                        ...LocationFragmentForEditCollectionModal
                    }
                }
            }
        }
        hasCurrentLocation @client @export(as: "hasLocation")
        currentLocationId @client @export(as: "locationId")
        location(id: $locationId) @include(if: $hasLocation) {
            id
            client {
                id
            }
        }
        collection(id: $collectionId) {
            __typename
            ... on Collection {
                id
                title
                sharedWith {
                    __typename
                    ... on SharedWithLocations {
                        locations {
                            edges {
                                node {
                                    id
                                    ...LocationFragmentForEditCollectionModal
                                }
                            }
                        }
                    }
                    ... on SharedWithClients {
                        clients {
                            edges {
                                node {
                                    id
                                    ...ClientFragmentForEditCollectionModal
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${CLIENT_FRAGMENT_FOR_EDIT_COLLECTION_MODAL}
    ${LOCATION_FRAGMENT_FOR_EDIT_COLLECTION_MODAL}
`;

const EDIT_COLLECTION_MUTATION = gql`
    mutation EditCollectionForEditCollectionModal($input: EditCollectionInput!) {
        editCollection(input: $input) {
            __typename
            ... on Collection {
                id
                title
            }
        }
    }
`;

const DELETE_COLLECTIONS_MUTATION = gql`
    mutation DeleteCollectionForEditCollectionModal($input: DeleteCollectionsInput!) {
        deleteCollections(input: $input) {
            __typename
            ... on DeleteCollectionsPayload {
                ids
            }
        }
    }
`;

export type EditCollectionModalProps = {
    collectionId: string;
    show: boolean;
    onClose: () => void;
};

export const EditCollectionModal: React.FC<EditCollectionModalProps> = ({ collectionId, onClose, show }) => (
    <DSDialog fullWidth maxWidth="md" open={show} onClose={onClose}>
        <DSDialogTitle>{t('COMMON.EDIT_COLLECTION')}</DSDialogTitle>
        {show && <EditCollectionModalContent collectionId={collectionId} onClose={onClose} />}
    </DSDialog>
);
EditCollectionModal.displayName = 'EditCollectionModal';

type EditCollectionModalContentProps = {
    onClose: () => void;
    collectionId: string;
};

// OptTitleField - an 'optimized' form field that debounces sending the change event to formik
// Explain: No one likes this hack less than me, but for now, the rerenders formik was causing when you type
// A title into the text field was slowing down the UI.  We really cannot slow down typing because it is
// Very obvious.  This hack is to debounce sending the title to formik using a 'search bar'.
// TODO: embiggen the performance on this page
type OptTitleProps = { initialValue: string; onChange: (val: string) => void };

const OptTitleField = React.forwardRef<HTMLInputElement, OptTitleProps>((props, ref) => {
    const { inputProps } = useControlledSearchInput({
        initialValue: props.initialValue,
        onSearchTermValueChange: props.onChange,
    });

    return <DSTextField autoFocus fullWidth inputRef={ref} placeholder="Name" variant="outlined" {...inputProps} />;
});
OptTitleField.displayName = 'OptTitleField';

const EditCollectionModalContent: React.FC<EditCollectionModalContentProps> = ({ collectionId, onClose }) => {
    const notifier = useAngularServiceContext('mvNotifier');

    const [editCollection] = useMutation<
        EditCollectionForEditCollectionModal,
        EditCollectionForEditCollectionModalVariables
    >(EDIT_COLLECTION_MUTATION);
    const [deleteCollection] = useMutation<
        DeleteCollectionForEditCollectionModal,
        DeleteCollectionForEditCollectionModalVariables
    >(DELETE_COLLECTIONS_MUTATION);

    const [, setCurrentAsset] = useCurrentAssetContext();

    const [searchFilter, setSearchFilter] = React.useState<string | undefined>('');

    const [fetchCurrentClient, { data: currentClientData, loading: currentClientLoading }] = useLazyQuery<
        GetCurrentClientForEditCollectionModal,
        GetCurrentClientForEditCollectionModalVariables
    >(GET_CURRENT_CLIENT_FOR_EDIT_COLLECTION_MODAL_QUERY, {
        notifyOnNetworkStatusChange: true,
    });

    const [fetchClients, { called: hasCalledFetchClients, data: clientsData, fetchMore, loading: clientsLoading }] =
        useLazyQuery<GetClientsForEditCollectionModal, GetClientsForEditCollectionModalVariables>(
            GET_CLIENTS_FOR_EDIT_COLLECTION_MODAL_QUERY,
            {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-and-network',
                notifyOnNetworkStatusChange: true,
                variables: {
                    first: 100,
                    title: searchFilter,
                },
            },
        );

    const { data, loading: locationsLoading } = useQuery<
        GetClientsAndLocationsForEditCollectionModal,
        GetClientsAndLocationsForEditCollectionModalVariables
    >(GET_LOCATIONS_FOR_EDIT_COLLECTION_MODAL_QUERY, {
        fetchPolicy: 'no-cache',
        variables: { collectionId } as any,
    });

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(
        clientsData?.me.agency.clients,
        async (after, first = 100) => fetchMore?.({ variables: { after, first } }),
    );

    const currentLocationId = data?.location?.id ?? null;
    const currentClientId = data?.location?.client.id ?? null;

    React.useEffect(() => {
        if (currentClientId) {
            void fetchCurrentClient({ variables: { id: currentClientId } });
        }
    }, [currentClientId, fetchCurrentClient]);

    React.useEffect(() => {
        if (!hasCalledFetchClients) {
            void fetchClients();
        }
    }, [hasCalledFetchClients, fetchClients]);

    const clients: Array<AssetShareTarget & AssetShareTargetGroup> = React.useMemo(
        () =>
            [
                ...currentClientData?.client ? [currentClientData.client] : [],
                ...relayConnectionToArray(clientsData?.me.agency.clients),
            ]
                .filter(filterUniqueBy(client => client.id))
                .map((client): AssetShareTarget & AssetShareTargetGroup => ({
                    currentCollections: client.collectionLimits.current,
                    id: client.id,
                    items: relayConnectionToArray(data?.me.locations)
                        .filter(location => location.client.id === client.id)
                        .map(
                            (location): AssetShareTarget => ({
                                currentCollections: location.collectionLimits.current,
                                id: location.id,
                                maxCollections: location.collectionLimits.max,
                                title: location.title,
                            }),
                        ),
                    maxCollections: client.collectionLimits.max,
                    title: client.title,
                })),
        [clientsData?.me.agency.clients, currentClientData, data?.me.locations],
    );

    // This is hacky but it solves having to do another query.
    // It should be refactored in the future along with the rest of this component
    const [cachedClients, setCachedClients] = React.useState<ClientFragmentForEditCollectionModal[]>([]);
    React.useEffect(() => {
        setCachedClients(existing => {
            const newArr = [...existing];
            const toPush = relayConnectionToArray(clientsData?.me.agency.clients).filter(
                client => !newArr.some(exist => exist.id === client.id),
            );
            newArr.push(...toPush);
            if (
                data?.collection.__typename === 'Collection' &&
                data.collection.sharedWith.__typename === 'SharedWithClients'
            ) {
                const toPushCollection = relayConnectionToArray(data.collection.sharedWith.clients).filter(
                    client => !newArr.some(exist => exist.id === client.id),
                );
                newArr.push(...toPushCollection);
            }
            return newArr;
        });
    }, [clientsData?.me.agency.clients, data]);

    const [cachedLocations, setCachedLocations] = React.useState<
        // eslint-disable-next-line camelcase
        GetClientsAndLocationsForEditCollectionModal_me_locations_edges_node[]
    >([]);
    React.useEffect(() => {
        setCachedLocations(existing => {
            const newArr = [...existing];
            const toPush = relayConnectionToArray(data?.me.locations).filter(
                location => !newArr.some(exist => exist.id === location.id),
            );
            newArr.push(...toPush);
            if (
                data?.collection.__typename === 'Collection' &&
                data.collection.sharedWith.__typename === 'SharedWithLocations'
            ) {
                const toPushCollection = relayConnectionToArray(data.collection.sharedWith.locations).filter(
                    location => !newArr.some(exist => exist.id === location.id),
                );
                newArr.push(...toPushCollection);
            }

            return newArr;
        });
    }, [data?.me.locations, data]);

    const initialValues =
        data?.collection.__typename === 'Collection'
            ? {
                  modelType: data.collection.sharedWith.__typename === 'SharedWithLocations' ? 'location' : 'client',
                  selectedClientIds:
                      data.collection.sharedWith.__typename === 'SharedWithClients'
                          ? relayConnectionToArray(data.collection.sharedWith.clients).map(item => item.id)
                          : [],
                  selectedLocationIds:
                      data.collection.sharedWith.__typename === 'SharedWithLocations'
                          ? relayConnectionToArray(data.collection.sharedWith.locations).map(item => item.id)
                          : [],
                  title: data?.collection.__typename === 'Collection' ? data.collection.title : '',
              }
            : {
                  modelType: 'client',
                  selectedClientIds: [],
                  selectedLocationIds: [],
                  title: '',
              };

    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        formikHelpers.setSubmitting(true);
        const input: EditCollectionInput = {
            id: collectionId,
            permissionLevel: values.modelType,
            sharedWithIds: values.modelType === 'client' ? values.selectedClientIds : values.selectedLocationIds,
            title: values.title,
        };

        const { data: createCollectionResponse } = await editCollection({
            variables: {
                input,
            },
        });
        if (createCollectionResponse?.editCollection.__typename !== 'Collection') {
            notifier.unexpectedError(t('COMMON.FAILED_TO', { description: t('COMMON.OP_CREATE_COLLECTION') }));
            formikHelpers.setSubmitting(false);
        }

        onClose();
        return Promise.resolve();
    };

    const handleDelete = async () => {
        const input: DeleteCollectionsInput = {
            ids: [collectionId],
        };

        await deleteCollection({
            update: (cache, { data: result }) => {
                if (result?.deleteCollections.__typename === 'DeleteCollectionsPayload') {
                    const clientOrLocation =
                        initialValues.modelType === 'client'
                            ? { __typename: 'Client', id: currentClientId }
                            : { __typename: 'Location', id: currentLocationId };
                    cache.modify<{ collections: any }>({
                        fields: {
                            collections(existingRef: { edges: [{ node: { id: string; title: string } }] }): any {
                                if (result.deleteCollections.__typename === 'DeleteCollectionsPayload') {
                                    return {
                                        ...existingRef,
                                        edges: existingRef.edges.filter(
                                            ({ node }) =>
                                                result.deleteCollections.__typename === 'DeleteCollectionsPayload' &&
                                                !result.deleteCollections.ids.includes(node.id),
                                        ),
                                    };
                                }
                                return existingRef;
                            },
                        },
                        id: cache.identify(clientOrLocation),
                        optimistic: true,
                    });
                    result.deleteCollections.ids.forEach(id =>
                        cache.evict({ id: cache.identify({ __typename: 'Collection', id }) }),
                    );
                }
                cache.gc();
            },
            variables: { input },
        });
        // This kills the crab
        setTimeout(() => setCurrentAsset(undefined), 500);
    };

    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};
        if (!values.title) {
            errors.title = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }
        return errors;
    };

    if (!currentClientId || !currentLocationId) {
        return <Loading />;
    }
    return (
        <Formik<typeof initialValues>
            initialValues={initialValues}
            validate={handleValidation}
            validateOnMount
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        <DSDialogContent>
                            <DSGrid container direction="column">
                                <DSGrid
                                    alignItems="center"
                                    container
                                    direction="row"
                                    item
                                    style={{ margin: '4px 0px' }}
                                >
                                    <DSGrid item justifyContent="flex-end" md={2} sm={3} xs={12}>
                                        <DSTypography component="p" variant="h6">
                                            {t('COMMON.NAME')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={10} sm={9} xs={12}>
                                        <OptTitleField
                                            initialValue={formik.values.title}
                                            onChange={value => formik.setFieldValue('title', value)}
                                        />
                                    </DSGrid>
                                </DSGrid>
                                <DSGrid container direction="row" item style={{ margin: '4px 0px' }}>
                                    <DSGrid
                                        item
                                        justifyContent="flex-end"
                                        md={2}
                                        sm={3}
                                        style={{ margin: '14px 0px' }}
                                        xs={12}
                                    >
                                        <DSTypography component="p" variant="h6">
                                            {formik.values.modelType === 'client'
                                                ? t('COMMON.CLIENTS')
                                                : t('COMMON.LOCATIONS')}
                                        </DSTypography>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                border: '1px solid #eee',
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            {formik.values.modelType === 'client' ? (
                                                <>
                                                    <ClientPicker
                                                        clients={clients}
                                                        disabled={[currentClientId]}
                                                        selected={formik.values.selectedClientIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedClientIds', value)
                                                        }
                                                        onSearchTermChange={setSearchFilter}
                                                    />
                                                    {(clientsLoading || currentClientLoading) && <Loading />}
                                                    {hasNextPage && (
                                                        <DSLoadMoreButton
                                                            gutterTop
                                                            loading={clientsLoading}
                                                            onClick={async () => handleFetchMore()}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <LocationPicker
                                                        clients={clients}
                                                        disabled={[currentLocationId]}
                                                        selected={formik.values.selectedLocationIds}
                                                        onChange={value =>
                                                            formik.setFieldValue('selectedLocationIds', value)
                                                        }
                                                    />
                                                    {(clientsLoading || currentClientLoading || locationsLoading) && (
                                                        <Loading />
                                                    )}
                                                    {hasNextPage && (
                                                        <DSLoadMoreButton
                                                            gutterTop
                                                            loading={
                                                                clientsLoading ||
                                                                currentClientLoading ||
                                                                locationsLoading
                                                            }
                                                            onClick={async () => handleFetchMore(100000)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </DSPaper>
                                    </DSGrid>
                                    <DSGrid item md={5} sm={5} xs={12}>
                                        <DSPaper
                                            elevation={0}
                                            style={{
                                                height: '240px',
                                                overflowY: 'scroll',
                                                padding: '4px 8px 0px 8px',
                                            }}
                                        >
                                            <SelectedClientsOrLocations
                                                items={
                                                    formik.values.modelType === 'client'
                                                        ? cachedClients.filter(
                                                              client =>
                                                                  formik.values.selectedClientIds.indexOf(client.id) >=
                                                                  0,
                                                          )
                                                        : cachedLocations.filter(
                                                              item =>
                                                                  formik.values.selectedLocationIds.indexOf(item.id) >=
                                                                  0,
                                                          )
                                                }
                                                title={
                                                    formik.values.modelType === 'client'
                                                        ? t('ASSET_LIBRARY.SELECTED_CLIENTS')
                                                        : t('ASSET_LIBRARY.SELECTED_LOCATIONS')
                                                }
                                            />
                                        </DSPaper>
                                    </DSGrid>
                                </DSGrid>
                            </DSGrid>
                        </DSDialogContent>
                        <DSDialogActions>
                            <DSConfirmButton
                                color="error"
                                disabled={formik.isSubmitting}
                                message={`${t('COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COLLECTION')}  ${t(
                                    'ASSET_LIBRARY.DELETE_COLLECTION_NOTE',
                                )}`}
                                style={{ marginRight: 'auto' }}
                                variant="contained"
                                onConfirm={handleDelete}
                            >
                                {t('COMMON.DELETE')}
                            </DSConfirmButton>
                            <DSButton
                                color="default"
                                disabled={formik.isSubmitting}
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t('COMMON.CANCEL')}
                            </DSButton>
                            <DSButton
                                color="primary"
                                disabled={!formik.isValid || formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                {t('COMMON.EDIT')}
                            </DSButton>
                        </DSDialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
};
EditCollectionModalContent.displayName = 'CreateCollectionModalContent';
