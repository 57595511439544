/// <reference path="../../../../typings/browser.d.ts" />
import { UpdateProfileDto, Upload, User } from '@deltasierra/shared';
import { $routeSID, $timeoutSID, IRoute } from '../../common/angularData';
import { debounceFactorySID, IDebounce } from '../../common/debounceFactory';
import { MvNotifier } from '../../common/mvNotifier';
import { UploadContext } from '../../common/uploadService';
import { I18nService } from '../../i18n/i18nService';
import { MvAuth } from '../mvAuth';
import { MvIdentity } from '../mvIdentity';

type AllowedFieldNames = Array<keyof User | 'profilePicture' | 'profilePictureId'>;

const ALLOWABLE_FIELDS: AllowedFieldNames = [
    'id', // Required for profile picture directive
    'username',
    'firstName',
    'lastName',
    'title',
    'timezone',
    'marketingUpdatesEnabled',
    'specialRequestNotificationsEnabled',
    'localeCode',
    'profilePictureId',
    'profilePicture',
    'printUnits',
    'defaultLocationId',
];

export class ProfileController {
    public static readonly SID = 'ProfileController';

    public static readonly $inject: string[] = [
        $routeSID,
        $timeoutSID,
        MvAuth.SID,
        MvIdentity.SID,
        MvNotifier.SID,
        I18nService.SID,
        debounceFactorySID,
    ];

    public uploadContext = new UploadContext();

    public userData: UpdateProfileDto & { profilePicture: Upload | undefined } = {
        localeCode: undefined,
        profilePicture: undefined,
        profilePictureId: undefined,
    };

    public update = this.debounce(async () => this.updateUserProfile(), 500, true);


    public constructor(
        private $route: IRoute,
        private $timeout: ng.ITimeoutService,
        private auth: MvAuth,
        private identity: MvIdentity,
        private notifier: MvNotifier,
        private i18n: I18nService,
        private debounce: IDebounce,
    ) {
        this.setTimezone = this.setTimezone.bind(this);
        this.cloneUserData();
    }

    public setTimezone(newTimezone: string): void {
        this.userData.timezone = newTimezone;
    }

    private async updateUserProfile(): Promise<void> {
        this.userData.profilePictureId = (this.userData.profilePicture && this.userData.profilePicture.id) || null;
        try {
            await this.auth.updateCurrentUser(this.userData);
            this.cloneUserData();

            this.notifier.notify(this.i18n.text.profile.userAccountUpdated());

            if (this.userData.localeCode !== this.i18n.getLocaleCode()) {
                this.notifier.notify(this.i18n.text.profile.userLocaleChangedPageWillReload());

                await this.$timeout(2000);
                await this.i18n.setLocaleCode(this.userData.localeCode!);
            } else {
                this.$route.reload();
            }
        } catch (error) {
            this.notifier.unexpectedError(error);
        }
    }

    private cloneUserData() {
        this.copyProperties(ALLOWABLE_FIELDS, this.identity.currentUser!, this.userData);
    }

    private copyProperties(properties: AllowedFieldNames, src: User, dst: UpdateProfileDto) {
        for (const propertyName of properties) {
            (dst as any)[propertyName] = (src as any)[propertyName];
        }
    }
}

angular.module('app').controller(ProfileController.SID, ProfileController);
