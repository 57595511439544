/// <reference path="../../../../typings/browser.d.ts" />
import { IAngularEvent, ILocationService, IRootScopeService, IScope } from 'angular';
import { MvIdentity } from '../../account/mvIdentity';
import { $locationSID, $rootScopeSID, $routeSID, $scopeSID, EVENT_DESTROY, IRoute } from '../../common/angularData';
import { AngularEventDefinition } from '../../common/events';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n';
import { ShoppingCartApiClient } from '../shoppingCartApiClient';

export const shoppingCartItemChangeEvent = new AngularEventDefinition<{}>(
    'shoppingCartItemChange',
    'A shopping cart item has been added or deleted for this user',
);

export class ShoppingCartBadgerController {
    public static SID = 'ShoppingCartBadgerController';

    public static readonly $inject: string[] = [
        $rootScopeSID,
        $scopeSID,
        InteractionUtils.SID,
        MvIdentity.SID,
        ShoppingCartApiClient.SID,
        $routeSID,
        $locationSID,
        I18nService.SID,
    ];


    public readonly fetchItemCount = this.interactionUtils.createFuture(
        this.i18n.text.shoppingCart.fetchItemCount(),
        () =>
            this.shoppingCartApiClient.getOrdersWithItemCount('pending').then(orders => {
                // We want to reset the count before calculating it
                this.itemCount = 0;
                for (const order of orders) {
                    this.itemCount += order.itemCount;
                }
            }),
    );


    public itemCount = 0;

    protected readonly eventDeregisters: Function[] = [];


    public constructor(
        protected readonly $rootScope: IRootScopeService,
        protected readonly $scope: IScope,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvIdentity: MvIdentity,
        protected readonly shoppingCartApiClient: ShoppingCartApiClient,
        protected readonly $route: IRoute,
        protected readonly $location: ILocationService,
        protected readonly i18n: I18nService,
    ) {}

   public $onInit(): void {
        this.$scope.$on(EVENT_DESTROY, () => this.deregisterEvents());
        this.fetchItemCount.run({});
        this.listenToShoppingCartItemChangeEvent(event => this.handleShoppingCartItemChangeEvent());
    }

    public onClickCart() {
        if (this.$location.path() === '/checkout') {
            this.$route.reload();
        } else {
            this.$location.path('/checkout');
        }
    }

    protected handleShoppingCartItemChangeEvent(): void {
        this.fetchItemCount.run({});
    }

    protected listenToShoppingCartItemChangeEvent(listener: (event: IAngularEvent) => any) {
        const deregister = shoppingCartItemChangeEvent.on(this.$scope, listener);
        this.eventDeregisters.push(deregister);
    }

    protected deregisterEvents() {
        this.eventDeregisters.forEach(deregister => deregister());
        this.eventDeregisters.length = 0;
    }
}
