/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/// <reference path="../../../typings/browser.d.ts" />
import { gql } from '@apollo/client';
import { contains } from '@deltasierra/array-utilities';
import { noop } from '@deltasierra/object-utilities';
import {
    AgencyOptionsFilter,
    AssignedLocation,
    BuilderDocumentFormat,
    BuilderTemplateCategoryId,
    BuilderTemplateId,
    ClientId,
    DEFAULT_GALLERY_CHUNK_SIZE,
    EditableFieldsFilter,
    GalleryPlannerDetails,
    LocationId,
    TEMPLATES_PER_CATEGORY_CHUNK_SIZE,
    assertNever,
    linkToBuildableTemplateBuilder,
    once,
    t,
} from '@deltasierra/shared';
import { BuildableTemplateStatus } from '../../../__graphqlTypes/globalTypes';
import { MvIdentity } from '../account/mvIdentity';
import { Debouncer } from '../common/Debouncer';
import { $locationSID, $qSID, $scopeSID, $timeoutSID } from '../common/angularData';
import {
    GetClientFeatures,
    GetClientFeaturesVariables,
    GetClientFeatures_client_features,
} from '../common/clientFeatures/__graphqlTypes/GetClientFeatures';
import { GET_CLIENT_FEATURES_QUERY } from '../common/clientFeatures/client-features.query';
import { GET_CONFIG_QUERY } from '../common/config';
import { GetConfig } from '../common/config/__graphqlTypes/GetConfig';
import { DataUtils } from '../common/dataUtils';
import { InteractionUtils } from '../common/interactionUtils';
import { GET_LOCATION_FEATURES_QUERY } from '../common/locationFeatures';
import {
    GetLocationFeatures,
    GetLocationFeatures_location_features,
} from '../common/locationFeatures/__graphqlTypes/GetLocationFeatures';
import { MvNotifier } from '../common/mvNotifier';
import { SentryService } from '../common/sentryService';
import { GET_USER_FEATURES_QUERY } from '../common/userFeatures';
import { GetUserFeatures, GetUserFeatures_me_features } from '../common/userFeatures/__graphqlTypes/GetUserFeatures';
import { BuilderTemplateApiClient } from '../contentBuilder/builderTemplateApiClient';
import {
    MvBuilderTemplateFormatResource,
    mvBuilderTemplateFormatResourceSID,
} from '../contentBuilder/mvBuilderTemplateFormatResource';
import {
    BuildableTemplateDeleteRequestedMetadata,
    BuildableTemplateDuplicateRequestedMetadata,
    BuildableTemplateTogglePublishStatusRequestedMetadata,
    TemplateUpdatedMetadata,
    buildableTemplateDeleteRequestedEvent,
    buildableTemplateDuplicateRequestedEvent,
    buildableTemplateTogglePublishStatusRequestedEvent,
    templateDeletedEvent,
    templateDraftModeUpdatedEvent,
    templateMetadataUpdatedEvent,
} from '../contentBuilder/templateThumbnailDirective/admin/templateAdminEvents';
import { GraphqlService } from '../graphql/GraphqlService';
import { convertIdToUniversalNodeId, relayConnectionToArray } from '../graphql/utils';
import { I18nService } from '../i18n';
import { IntroDataService } from '../intro/introDataService';
import { IntroService } from '../intro/introService';
import { IntroWrapper } from '../intro/introWrapper';
import { PlannerUIService } from '../planner/plannerUIService';
import { CREATE_EMAIL_TEMPLATE } from './CreateEmailTemplate.mutation';
import { DELETE_INDIVIDUAL_TEMPLATE } from './DeleteIndividualTemplate.mutation';
import { DUPLICATE_TEMPLATE } from './DuplicateTemplate.mutation';
import { TOGGLE_PUBLISH_STATUS } from './TogglePublishStatus.mutation';
import { CreateEmailTemplate, CreateEmailTemplateVariables } from './__graphqlTypes/CreateEmailTemplate';
import { DeleteIndividualTemplate, DeleteIndividualTemplateVariables } from './__graphqlTypes/DeleteIndividualTemplate';
import { DuplicateTemplate, DuplicateTemplateVariables } from './__graphqlTypes/DuplicateTemplate';
import {
    GetBuilderTemplateCategoriesForGallery_location_builderTemplateCategoriesConnection_edges_node as CategoryNode,
    GetBuilderTemplateCategoriesForGallery,
} from './__graphqlTypes/GetBuilderTemplateCategoriesForGallery';
import { ToggleTemplateStatus, ToggleTemplateStatusVariables } from './__graphqlTypes/ToggleTemplateStatus';
import {
    TemplateGroup,
    TemplateGroupType,
    TemplateGroupTypeCategory,
    TemplateGroupTypeEnum,
    mapBuildableTemplateToTemplateGroupItem,
    mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem,
} from './templateGroup';
import IQService = angular.IQService;
import ILocationService = angular.ILocationService;
import IScope = angular.IScope;
import IPromise = angular.IPromise;
import ITimeoutService = angular.ITimeoutService;

type ClientAndUserFeatures = {
    // eslint-disable-next-line camelcase
    clientFeatures: GetClientFeatures_client_features;
    // eslint-disable-next-line camelcase
    locationFeatures: GetLocationFeatures_location_features;
    // eslint-disable-next-line camelcase
    userFeatures: GetUserFeatures_me_features;
};

export interface GalleryQueryParams {
    limit: number;
    previousId?: number;
    previousDate?: Date;
    categories?: BuilderTemplateCategoryId[];
    uncategorised?: boolean;
    formats?: number[];
    location?: LocationId;
    client?: ClientId;
    tags?: string;
    exactTag?: string;
    platforms?: number[];
    editableFields?: EditableFieldsFilter;
    onlyLocationDraft?: boolean;
    recent?: boolean;
    hideDraft?: boolean;
    hidePublished?: boolean;
    mobile?: boolean;
    multiImage?: boolean;
}

export type GalleryParams = {
    includeOldEmailTemplatesFallback?: boolean;
};

const LOAD_AMOUNT = TEMPLATES_PER_CATEGORY_CHUNK_SIZE;

const GET_BUILDER_TEMPLATE_CATEGORIES_FOR_GALLERY = gql`
    query GetBuilderTemplateCategoriesForGallery($id: ID!) {
        location(id: $id) {
            id
            builderTemplateCategoriesConnection {
                edges {
                    node {
                        id
                        legacyId
                        title
                        order
                    }
                }
            }
        }
    }
`;

type SimpleCategory = {
    id: number;
    title: string;
};

export class MvBuilderTemplateGalleryCtrl {
    public static readonly SID = 'mvBuilderTemplateGalleryCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $locationSID,
        $timeoutSID,
        I18nService.SID,
        DataUtils.SID,
        MvIdentity.SID,
        MvNotifier.SID,
        BuilderTemplateApiClient.SID,
        PlannerUIService.SID,
        InteractionUtils.SID,
        IntroService.SID,
        IntroDataService.SID,
        GraphqlService.SID,
        SentryService.SID,
        mvBuilderTemplateFormatResourceSID,
    ];

    public selectedFormatIds: number[] = [];

    public selectedGroupTypes: TemplateGroupType[] = [];

    public location?: AssignedLocation;

    public tags = '';

    public editableFields: EditableFieldsFilter = {
        image: false,
        text: false,
        video: false,
    };

    public agencyOptions: AgencyOptionsFilter = {
        allClients: false,
        hideDraft: false,
        hideMobile: false,
        hidePublished: false,
        multiImage: false,
        onlyLocationDraft: false,
        onlyMobile: false,
    };

    public plannerId?: number;

    public locationId?: number | string;

    public collectionId?: number;

    public platformIds: number[] = [];

    public loading = {
        plannerDetails: false,
    };

    public introBuild?: IntroWrapper;

    public categories: SimpleCategory[] = [];

    public plannerDetails?: GalleryPlannerDetails;

    public templateGroups: TemplateGroup[] = [];

    public includeOldEmailTemplatesFallback: boolean = false;

    public showIncludeOldEmailTemplatesFallback: boolean = false;

    // eslint-disable-next-line @typescript-eslint/ban-types
    public searchDebouncer = new Debouncer<any, {}>(
        this.$timeout,
        this.$q,
        this.i18nService.text.common.search(),
        () => this.$q.resolve(this.onCriteriaChange()),
        1000,
    );

    public fetchCategories = this.interactionUtils.createFuture(this.i18nService.text.common.fetchData(), async () =>
        this.fetchCategoriesForLocation()
            .then(categories => {
                this.categories = categories.map(category => ({ id: category.legacyId, title: category.title }));
            })
            .then(() => this.removeUnavailableCategories())
            .then(categoriesChanged => {
                if (!categoriesChanged) {
                    // DS-2465: changing client can trigger a search twice, if a category was selected.
                    return this.onCriteriaChange();
                }
            })
            .then(
                once(() =>
                    this.$timeout(() => {
                        angular.element('body').scroll();
                    }, 700),
                ),
            ),
    );

    private clientAndUserFeaturesPromise: {
        clientId: ClientId;
        locationId: LocationId;
        promise: Promise<ClientAndUserFeatures>;
    } | null = null;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: IScope,
        private readonly $q: IQService,
        private readonly $location: ILocationService,
        private readonly $timeout: ITimeoutService,
        private readonly i18nService: I18nService,
        private readonly dataUtils: DataUtils,
        public readonly identity: MvIdentity,
        private readonly notifier: MvNotifier,
        private readonly builderTemplateApiClient: BuilderTemplateApiClient,
        private readonly plannerUIService: PlannerUIService,
        private readonly interactionUtils: InteractionUtils,
        private readonly introService: IntroService,
        private readonly introDataService: IntroDataService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
        private readonly mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
    ) {
        this.getQueryData();

        void this.fetchCategoriesForLocation();
        // The first templates will be loaded once the location has been loaded

        this.toggleIncludeOldEmailTemplatesFallback = this.toggleIncludeOldEmailTemplatesFallback.bind(this);

        this.$scope.$watch(
            () => this.location?.id,
            (locationId: LocationId | undefined) => {
                if (locationId && this.location?.clientId) {
                    this.getOrCreatePromiseForClientAndUserFeatures(this.location.clientId, locationId);
                }
            },
        );
    }

    public updateLocation(location: AssignedLocation): ng.IPromise<void> {
        const previousLocation = this.location;
        this.location = location;
        const promises = [];
        if (!previousLocation) {
            this.initWatchers();
            this.triggerIntro();
        }
        if (
            !previousLocation ||
            previousLocation.clientId !== location.clientId ||
            previousLocation.id !== location.id
        ) {
            promises.push(this.loadClientSpecificData());
        }
        return this.$q.all(promises).then(noop);
    }

    public updateFormat(formatIds: number[], platformIds: number[]): void {
        this.selectedFormatIds = formatIds;
        this.platformIds = platformIds;
    }

    public onPlannerDetailsLoaded(plannerDetails: GalleryPlannerDetails): void {
        this.plannerDetails = plannerDetails;
    }

    public loadMore(group: TemplateGroup): IPromise<void> {
        const queryParams: GalleryQueryParams = {
            editableFields: this.editableFields,
            limit: TemplateGroupTypeEnum.SearchResults ? DEFAULT_GALLERY_CHUNK_SIZE : LOAD_AMOUNT,
            location: this.location?.id,
        };

        if (this.platformIds.length > 0) {
            queryParams.platforms = this.platformIds;
        }
        if (this.selectedFormatIds.length > 0) {
            queryParams.formats = this.selectedFormatIds;
        }
        const agencyOptionParams = this.getAgencyOptionQueryParams();
        if (this.tags) {
            queryParams.tags = this.tags;
        }

        const adHocGalleryParams: GalleryParams = {
            includeOldEmailTemplatesFallback: this.includeOldEmailTemplatesFallback,
        };

        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.Category:
                queryParams.categories = [group.groupType.categoryId];
                break;
            case TemplateGroupTypeEnum.RecentlyAdded:
                queryParams.recent = true;
                break;
            case TemplateGroupTypeEnum.SearchResults:
                if (this.selectedGroupTypes && this.selectedGroupTypes.length > 0) {
                    const selectedCategoryIds = this.selectedGroupTypes
                        .filter((gt): gt is TemplateGroupTypeCategory => gt.type === TemplateGroupTypeEnum.Category)
                        .map((gt: TemplateGroupTypeCategory) => gt.categoryId);
                    if (selectedCategoryIds.length > 0) {
                        queryParams.categories = selectedCategoryIds;
                    } else {
                        const recent = this.selectedGroupTypes.some(
                            gt => gt.type === TemplateGroupTypeEnum.RecentlyAdded,
                        );
                        if (recent) {
                            queryParams.recent = true;
                        }
                    }
                }
                break;
            case TemplateGroupTypeEnum.Uncategorised:
                queryParams.uncategorised = true;
                break;
            case TemplateGroupTypeEnum.Format:
                queryParams.formats = [group.groupType.formatId];
                break;
            default:
                throw assertNever(group.groupType);
        }

        return group.loadMore({ ...queryParams, ...agencyOptionParams }, adHocGalleryParams);
    }

    public goToBuilder(params: { print?: 1; video?: 1; planner?: number; collection?: number }, path: string): void {
        if (this.plannerId) {
            params.planner = this.plannerId;
        }
        if (this.collectionId) {
            params.collection = this.collectionId;
        }
        this.$location.path(path).search(params);
    }

    public newImageTemplate(): void {
        const params = {};
        this.goToBuilder(params, '/contentBuilder');
    }

    public toggleIncludeOldEmailTemplatesFallback(value: boolean): void {
        this.includeOldEmailTemplatesFallback = value;
        this.loadClientSpecificData();
    }

    public async newEmailTemplate(): Promise<void> {
        if (!this.location?.clientId) {
            return;
        }

        const { clientFeatures, locationFeatures, userFeatures } =
            await this.getOrCreatePromiseForClientAndUserFeatures(this.location.clientId, this.location.id);

        const isV2BuildableTemplatesEnabled =
            userFeatures.templateGalleryV2 ||
            clientFeatures.templateGallery ||
            locationFeatures.templateGalleryV2 ||
            false;

        if (isV2BuildableTemplatesEnabled) {
            await this.createNewBuildableEmailTemplate(this.location);
        } else {
            const params = {};
            this.goToBuilder(params, '/emailBuilder');
        }
    }

    public newPrintTemplate(): void {
        const params = {
            print: 1 as const,
        };
        this.goToBuilder(params, '/contentBuilder');
    }

    public newVideoTemplate(): void {
        const params = {
            video: 1 as const,
        };
        this.goToBuilder(params, '/contentBuilder');
    }

    public clickSearch(): IPromise<any> {
        return this.searchDebouncer.run({});
    }

    public requestANewTemplate(): void {
        const params = {
            locationId: this.location!.id,
            new: true,
            title: 'Digital Stack Template Request',
        };
        this.$location.path('/specialRequests').search(params);
    }

    public constructPlannerUrl(): string {
        if (this.plannerDetails) {
            return this.plannerUIService.getPlannerUrl(this.plannerDetails);
        } else {
            throw new Error("Can't construct planner URL without any planner details");
        }
    }

    public onCategoriesChanged(groupTypes: TemplateGroupType[]): void {
        this.selectedGroupTypes = groupTypes;
    }

    public onEditableFieldsChanged(editableFields: EditableFieldsFilter): void {
        this.editableFields = editableFields;
    }

    public onAgencyOptionsChanged(agencyOptions: AgencyOptionsFilter): ng.IPromise<void> | void {
        const shouldLoadClientData = this.agencyOptions.allClients !== agencyOptions.allClients;

        this.agencyOptions = agencyOptions;

        if (shouldLoadClientData) {
            return this.loadClientSpecificData();
        }
    }

    public onTemplateGroupInView(group: TemplateGroup): ng.IPromise<void> | void {
        if (group.templates.length === 0 && group.fetchTemplates.isPending()) {
            return this.loadMore(group);
        }
    }

    public lastChildInViewCallback(group: TemplateGroup): ng.IPromise<void> | void {
        if (!group.allLoaded) {
            return this.loadMore(group);
        }
    }

    public shouldShowGroup(group: TemplateGroup): boolean {
        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.SearchResults:
                return true;
            case TemplateGroupTypeEnum.Uncategorised:
            case TemplateGroupTypeEnum.Category:
            case TemplateGroupTypeEnum.RecentlyAdded:
            case TemplateGroupTypeEnum.Format:
                return (
                    group.fetchTemplates.isPending() || group.fetchTemplates.isRunning() || group.templates.length > 0
                );
            default:
                throw assertNever(group.groupType);
        }
    }

    public shouldShowNoResults(): boolean {
        return (
            !this.fetchCategories.isRunning() &&
            (this.templateGroups.length === 0 || this.templateGroups.every(group => !this.shouldShowGroup(group)))
        );
    }

    public shouldShowPrintPackagesBanner(): boolean {
        return !this.introService.isIntroActive('build');
    }

    public shouldShowTemplateMenu(): boolean {
        return !this.introService.isIntroActive('build');
    }

    public clickTemplateGroupHeading(group: TemplateGroup): void {
        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.SearchResults:
            case TemplateGroupTypeEnum.Uncategorised:
                break;
            case TemplateGroupTypeEnum.RecentlyAdded:
            case TemplateGroupTypeEnum.Category:
            case TemplateGroupTypeEnum.Format:
                this.selectedGroupTypes.length = 0;
                this.selectedGroupTypes.push(group.groupType);
                break;
            default:
                throw assertNever(group.groupType);
        }
    }

    public clickTemplateContainer(): void {
        // HACK: next page isn't loading properly when the user clicks the template
        if (this.introService.isIntroActive('build')) {
            this.introService.loadNextPage();
        }
    }

    protected convertCategoryIdsToGroupTypes(
        builderTemplateCategoryIds: BuilderTemplateCategoryId[],
    ): TemplateGroupType[] {
        return builderTemplateCategoryIds.map(
            (categoryId): TemplateGroupTypeCategory => ({
                categoryId,
                type: TemplateGroupTypeEnum.Category,
            }),
        );
    }

    protected async fetchCategoriesForLocation(): Promise<CategoryNode[]> {
        if (!this.location) {
            return Promise.resolve([]);
        }
        const client = this.graphqlService.getClient();
        return client
            .query<GetBuilderTemplateCategoriesForGallery>({
                fetchPolicy: 'network-only',
                query: GET_BUILDER_TEMPLATE_CATEGORIES_FOR_GALLERY,
                variables: { id: this.location.graphqlId },
            })
            .then(({ data }) =>
                data.location
                    ? relayConnectionToArray(data?.location.builderTemplateCategoriesConnection).sort(
                          (btcA, btcB) => btcA.order - btcB.order,
                      )
                    : [],
            );
    }

    protected convertGroupTypesToCategoryIds(): BuilderTemplateCategoryId[] {
        return this.selectedGroupTypes
            .filter(
                (groupType): groupType is TemplateGroupTypeCategory =>
                    groupType.type === TemplateGroupTypeEnum.Category,
            )
            .map((groupType: TemplateGroupTypeCategory) => groupType.categoryId);
    }

    protected convertGroupTypesToRecentlyAdded(): boolean {
        return (
            this.selectedGroupTypes.filter(groupType => groupType.type === TemplateGroupTypeEnum.RecentlyAdded).length >
            0
        );
    }

    protected removeUnavailableCategories(): boolean {
        const availableCategoryIds = this.categories.map(category => category.id);
        const originalSelectedGroupTypes = this.selectedGroupTypes;
        this.selectedGroupTypes = this.selectedGroupTypes.filter(
            groupType =>
                groupType.type !== TemplateGroupTypeEnum.Category ||
                contains(availableCategoryIds, groupType.categoryId),
        );
        return originalSelectedGroupTypes.length !== this.selectedGroupTypes.length;
    }

    protected resetAllGroups(): void {
        for (const group of this.templateGroups) {
            group.reset();
        }
    }

    protected removeTemplateById(builderTemplateId: BuilderTemplateId): void {
        for (const group of this.templateGroups) {
            this.dataUtils.removeById(builderTemplateId, group.templates); // Only removes the first
            this.dataUtils.removeById(builderTemplateId, group.templatesWithoutLocationInfo);
        }
    }

    protected updateTemplateMetadata(metadata: TemplateUpdatedMetadata): void {
        for (const group of this.templateGroups) {
            const index = this.dataUtils.indexOfBy('id', group.templates, metadata.builderTemplate.id);
            if (index > -1) {
                // The template exists in the group
                if (
                    // If the template was removed from this group's category, remove the template
                    group.groupType.type === TemplateGroupTypeEnum.Category &&
                    !this.dataUtils.existsBy('id', metadata.builderTemplate.categories, group.groupType.categoryId)
                ) {
                    group.templates.splice(index, 1);
                } else {
                    // Otherwise, replace the whole template
                    group.templates[index] = mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem(
                        metadata.builderTemplate,
                    );
                }
            } else if (
                // If the template was added to this group's category, add the template.
                group.groupType.type === TemplateGroupTypeEnum.Category &&
                this.dataUtils.existsBy('id', metadata.builderTemplate.categories, group.groupType.categoryId)
            ) {
                // Re-load the template from the server if there's no other templates and we've loaded everything.
                // (Need to do this, rather than just inserting the template,
                // Because the "in-view" callback will trigger
                //  Once the template is pushed to the array and the category subsequently comes into view, causing a
                //  Duplicate.)
                if (group.templates.length === 0 && group.allLoaded) {
                    group.reset();
                    void this.loadMore(group);
                }
                // We don't want to add the template if it's out of the currently loaded date range.
                for (let i = 0; i < group.templates.length; i++) {
                    // Tslint:disable-line:prefer-for-of
                    const template = group.templates[i];
                    if (template.createdAt <= metadata.builderTemplate.createdAt) {
                        group.templates.splice(
                            i,
                            0,
                            mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem(metadata.builderTemplate),
                        );
                        break;
                    }
                }
            }
        }
    }

    protected updateTemplateDraftMode(metadata: TemplateUpdatedMetadata): void {
        for (const group of this.templateGroups) {
            const template = this.dataUtils.findBy('id', group.templates, metadata.builderTemplate.id);
            if (template) {
                // The template exists in the group
                template.isDraft = metadata.builderTemplate.isDraft;
            }
        }
    }

    private getAgencyOptionQueryParams() {
        const agencyQueryParams: Pick<
            GalleryQueryParams,
            'client' | 'hideDraft' | 'hidePublished' | 'mobile' | 'multiImage' | 'onlyLocationDraft'
        > = {};
        if (!this.agencyOptions.allClients) {
            agencyQueryParams.client = this.location!.clientId;
        }
        if (this.agencyOptions.hideDraft) {
            agencyQueryParams.hideDraft = this.agencyOptions.hideDraft;
        }
        if (this.agencyOptions.hidePublished) {
            agencyQueryParams.hidePublished = this.agencyOptions.hidePublished;
        }
        if (this.agencyOptions.onlyLocationDraft) {
            agencyQueryParams.onlyLocationDraft = this.agencyOptions.onlyLocationDraft;
        }
        if (this.agencyOptions.onlyMobile) {
            agencyQueryParams.mobile = true;
        } else if (this.agencyOptions.hideMobile) {
            agencyQueryParams.mobile = false;
        }
        if (this.agencyOptions.multiImage) {
            agencyQueryParams.multiImage = true;
        }
        return agencyQueryParams;
    }

    // eslint-disable-next-line max-statements
    private getQueryData() {
        const queryData = this.$location.search();
        const plannerId = queryData.planner;
        const locationId = queryData.location;
        const collectionId = queryData.collection;
        const categoryIds: string = queryData.category;
        const platformIds: string = queryData.platform;
        const formatIds: string = queryData.format;
        const fields: string = queryData.fields;
        const recentlyAdded = Boolean(queryData.recent);
        this.plannerId = plannerId ? parseInt(plannerId, 10) : plannerId;
        this.locationId = locationId ? parseInt(locationId, 10) : locationId;
        this.collectionId = collectionId ? parseInt(collectionId, 10) : collectionId;
        if (recentlyAdded) {
            this.selectedGroupTypes.push({
                type: TemplateGroupTypeEnum.RecentlyAdded,
            });
        } else {
            this.selectedGroupTypes.push(
                ...this.convertCategoryIdsToGroupTypes(
                    this.parseQueryToArray<BuilderTemplateCategoryId>(String(categoryIds)),
                ),
            );
        }
        this.platformIds.push(...this.parseQueryToArray(String(platformIds)));
        this.selectedFormatIds.push(...this.parseQueryToArray(String(formatIds)));
        this.tags = queryData.search || '';
        if (fields) {
            this.setEditableFieldsFromArray(fields.split(','));
        }
        // Re-set the query data, now that we've sanitised it
        this.setQueryData();
    }

    private parseQueryToArray<T extends number>(query: string): T[] {
        if (query) {
            return query
                .split(',')
                .map(id => parseInt(id, 10) as T)
                .filter(id => !isNaN(id));
        }

        return [];
    }

    private setQueryData() {
        const fields = this.getEditableFieldsAsArray();
        const categories = this.convertGroupTypesToCategoryIds();
        const recentlyAdded = this.convertGroupTypesToRecentlyAdded();
        this.$location.search('search', this.tags || null);
        this.$location.search('location', this.locationId || null);
        this.$location.search('fields', (fields.length && fields.join(',')) || (null as any));
        this.$location.search('platform', (this.platformIds.length && this.platformIds.join(',')) || (null as any));
        this.$location.search(
            'format',
            (this.selectedFormatIds.length && this.selectedFormatIds.join(',')) || (null as any),
        );
        if (recentlyAdded) {
            this.$location.search('recent', recentlyAdded);
            this.$location.search('category', null);
        } else {
            this.$location.search('recent', null);
            this.$location.search('category', (categories.length && categories.join(',')) || (null as any));
        }
    }

    private getEditableFieldsAsArray(): string[] {
        return Object.keys(this.editableFields)
            .filter((x): x is keyof EditableFieldsFilter => this.editableFields.hasOwnProperty(x))
            .filter((field: keyof EditableFieldsFilter) => this.editableFields[field]);
    }

    private setEditableFieldsFromArray(fields: string[]): void {
        for (const field of fields) {
            if (this.editableFields.hasOwnProperty(field)) {
                this.editableFields[field as keyof EditableFieldsFilter] = true;
            }
        }
    }

    private initWatchers() {
        // eslint-disable-next-line @typescript-eslint/ban-types
        function checkForChangedValue<T>(fn: Function) {
            return (newValue: T, oldValue: T) => {
                if (newValue !== oldValue) {
                    return fn();
                }
            };
        }
        const debounceFn = this.searchDebouncer.asFunction();
        this.$scope.$watchCollection(() => this.selectedGroupTypes, checkForChangedValue(debounceFn));
        this.$scope.$watchCollection(() => this.platformIds, checkForChangedValue(debounceFn));
        this.$scope.$watchCollection(() => this.selectedFormatIds, checkForChangedValue(debounceFn));
        this.$scope.$watchCollection(() => this.editableFields, checkForChangedValue(debounceFn));
        this.$scope.$watchCollection(() => this.agencyOptions, checkForChangedValue(debounceFn));
        this.$scope.$watch(
            () => this.location,
            checkForChangedValue(() => {
                this.templateGroups.forEach(templateGroup => {
                    void templateGroup.refreshTemplates.run({ location: this.location });
                });
            }),
        );
        templateDeletedEvent.on(this.$scope, (event, data) => this.removeTemplateById(data.builderTemplateId));
        templateMetadataUpdatedEvent.on(this.$scope, (event, data) => this.updateTemplateMetadata(data));
        templateDraftModeUpdatedEvent.on(this.$scope, (event, data) => this.updateTemplateDraftMode(data));
        buildableTemplateDuplicateRequestedEvent.on(this.$scope, (event, data) =>
            this.onBuildableTemplateDuplicateRequested(data),
        );
        buildableTemplateDeleteRequestedEvent.on(this.$scope, (event, data) =>
            this.onBuildableTemplateDeleteRequested(data),
        );
        buildableTemplateTogglePublishStatusRequestedEvent.on(this.$scope, (event, data) =>
            this.onBuildableTemplateTogglePublishStatusRequested(data),
        );
    }

    private onCriteriaChange(): void {
        // We pretend that certain criteria is filtering, instead of performing a fresh search.
        // Filtering criteria will still group categories.
        if (this.hasNonFilterCriteria()) {
            this.templateGroups = [
                new TemplateGroup(
                    this.$q,
                    this.interactionUtils,
                    this.i18nService,
                    this.builderTemplateApiClient,
                    this.introService,
                    this.introDataService,
                    this.graphqlService,
                    this.sentryService,
                    this.mvBuilderTemplateFormatResource,
                    this.identity,
                    this.i18nService.text.common.searchResults(),
                    {
                        type: TemplateGroupTypeEnum.SearchResults,
                    },
                    this.location,
                ),
            ];
        } else {
            // Re-generate the template groups, so we can avoid race conditions where results from
            // Previous searches return after more recent searches.
            this.createTemplateGroups();
        }
        this.setQueryData();
        return this.search();
    }

    private hasNonFilterCriteria() {
        return this.selectedGroupTypes.length > 0 || !!this.tags;
    }

    private loadClientSpecificData(): IPromise<any> {
        return this.fetchCategories.run({});
    }

    private createTemplateGroups() {
        const templateGroups = [
            new TemplateGroup(
                this.$q,
                this.interactionUtils,
                this.i18nService,
                this.builderTemplateApiClient,
                this.introService,
                this.introDataService,
                this.graphqlService,
                this.sentryService,
                this.mvBuilderTemplateFormatResource,
                this.identity,
                this.i18nService.text.build.recentlyAdded(),
                {
                    type: TemplateGroupTypeEnum.RecentlyAdded,
                },
                this.location,
            ),
        ].concat(
            this.categories.map(
                category =>
                    new TemplateGroup(
                        this.$q,
                        this.interactionUtils,
                        this.i18nService,
                        this.builderTemplateApiClient,
                        this.introService,
                        this.introDataService,
                        this.graphqlService,
                        this.sentryService,
                        this.mvBuilderTemplateFormatResource,
                        this.identity,
                        category.title,
                        {
                            categoryId: category.id as BuilderTemplateCategoryId,
                            type: TemplateGroupTypeEnum.Category,
                        },
                        this.location,
                    ),
            ),
        );

        templateGroups.push(
            new TemplateGroup(
                this.$q,
                this.interactionUtils,
                this.i18nService,
                this.builderTemplateApiClient,
                this.introService,
                this.introDataService,
                this.graphqlService,
                this.sentryService,
                this.mvBuilderTemplateFormatResource,
                this.identity,
                this.i18nService.text.common.other(),
                {
                    type: TemplateGroupTypeEnum.Uncategorised,
                },
                this.location,
            ),
        );

        this.templateGroups = templateGroups;
    }

    private triggerIntro() {
        this.introBuild = this.introService.setUpAndStartIntro('build', this.$scope);
    }

    private search(): void {
        return this.resetAllGroups();
    }

    private async getClientAndUserFeatures(
        clientId?: ClientId,
        locationId?: LocationId,
    ): Promise<ClientAndUserFeatures> {
        const gqlClient = this.graphqlService.getClient();

        const [clientFeaturesResult, userFeaturesResult, locationFeaturesResult] = await Promise.all([
            gqlClient.query<GetClientFeatures, GetClientFeaturesVariables>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_CLIENT_FEATURES_QUERY,
                variables: { clientId: clientId ? convertIdToUniversalNodeId('client', clientId) : '' },
            }),
            gqlClient.query<GetUserFeatures>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_USER_FEATURES_QUERY,
            }),
            gqlClient.query<GetLocationFeatures>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_LOCATION_FEATURES_QUERY,
                variables: { locationId: locationId ? convertIdToUniversalNodeId('location', locationId) : '' },
            }),
        ]);

        if (clientFeaturesResult.errors || !clientFeaturesResult.data.client) {
            throw new Error('Failed to fetch client features');
        }

        if (locationFeaturesResult.errors || !locationFeaturesResult.data.location) {
            throw new Error('Failed to fetch location features');
        }

        if (userFeaturesResult.errors) {
            throw new Error('Failed to fetch user features');
        }

        const isV2BuildableTemplatesEnabled =
            userFeaturesResult.data.me.features.templateGalleryV2 ||
            clientFeaturesResult.data.client.features.templateGallery ||
            locationFeaturesResult.data.location.features.templateGalleryV2 ||
            false;

        this.showIncludeOldEmailTemplatesFallback =
            isV2BuildableTemplatesEnabled &&
            // Reference the setting for the user first
            (userFeaturesResult.data.me.features.showOldEmailTemplatesOption ||
                // Otherwise reference the setting for the client
                clientFeaturesResult.data.client.features.showOldEmailTemplatesOption ||
                // Otherwise the location
                locationFeaturesResult.data.location.features.showOldEmailTemplatesOption ||
                // And finally fallback to false
                false);

        return {
            clientFeatures: clientFeaturesResult.data.client.features,
            locationFeatures: locationFeaturesResult.data.location.features,
            userFeatures: userFeaturesResult.data.me.features,
        };
    }

    private getOrCreatePromiseForClientAndUserFeatures(
        clientId: ClientId,
        locationId: LocationId,
    ): Promise<ClientAndUserFeatures> {
        if (
            !this.clientAndUserFeaturesPromise ||
            this.clientAndUserFeaturesPromise.clientId !== clientId ||
            this.clientAndUserFeaturesPromise.locationId !== locationId
        ) {
            this.clientAndUserFeaturesPromise = {
                clientId,
                locationId,
                promise: this.getClientAndUserFeatures(clientId, locationId),
            };
        }

        return this.clientAndUserFeaturesPromise.promise;
    }

    private async createNewBuildableEmailTemplate(location: AssignedLocation): Promise<void> {
        const gqlClient = this.graphqlService.getClient();

        const newTemplateResult = await gqlClient.mutate<CreateEmailTemplate, CreateEmailTemplateVariables>({
            mutation: CREATE_EMAIL_TEMPLATE,
            variables: { input: { clientId: location.clientGraphqlId, title: 'Untitled Template' } },
        });

        if (newTemplateResult.data?.createEmailTemplate.__typename === 'CreateEmailTemplateError') {
            this.sentryService.captureException(
                t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'),
                newTemplateResult.data.createEmailTemplate,
            );
            this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
        }

        if (newTemplateResult.data?.createEmailTemplate.__typename === 'CreateEmailTemplateSuccess') {
            const environmentConfig = await this.getEnvironmentConfig();

            window.location.assign(
                linkToBuildableTemplateBuilder({
                    clientId: location.clientGraphqlId,
                    documentFormat: BuilderDocumentFormat.email,
                    frontendUrl: environmentConfig.config.appFrontendUrl,
                    templateId: newTemplateResult.data.createEmailTemplate.emailTemplate.id,
                }),
            );
        }
    }

    private async getEnvironmentConfig(): Promise<GetConfig> {
        const gqlClient = this.graphqlService.getClient();

        const configResult = await gqlClient.query<GetConfig>({
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            query: GET_CONFIG_QUERY,
        });

        if (configResult.errors) {
            throw new Error('Failed to fetch config');
        }

        return configResult.data;
    }

    private async onBuildableTemplateDuplicateRequested(
        data: BuildableTemplateDuplicateRequestedMetadata,
    ): Promise<void> {
        const gqlClient = this.graphqlService.getClient();

        try {
            // Find the original template, if this doesn't exist then something is horribly wrong
            const originalTemplate = this.templateGroups
                .flatMap(group => group.templates)
                .find(template => template.buildableTemplateId === data.templateId);

            if (!originalTemplate) {
                this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
                return;
            }

            const duplicateTemplateResult = await gqlClient.mutate<DuplicateTemplate, DuplicateTemplateVariables>({
                mutation: DUPLICATE_TEMPLATE,
                variables: {
                    input: {
                        clientId: convertIdToUniversalNodeId('client', data.clientId),
                        id: data.templateId,
                    },
                },
            });

            if (duplicateTemplateResult.data?.duplicateTemplate?.__typename === 'DuplicateTemplateError') {
                this.sentryService.captureException(
                    t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'),
                    duplicateTemplateResult.data.duplicateTemplate,
                );
                this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
                return;
            }

            if (duplicateTemplateResult.data?.duplicateTemplate?.__typename === 'DuplicateTemplateSuccess') {
                this.notifier.success(t('BUILD.TEMPLATE_ADMIN.DUPLICATE_TEMPLATE_SUCCESS'));

                const duplicatedTemplate = duplicateTemplateResult.data.duplicateTemplate.buildableTemplate;
                const duplicatedTemplateGroupItem = mapBuildableTemplateToTemplateGroupItem(
                    duplicatedTemplate,
                    data.clientId,
                    [], // We copy formats from the original template to avoid unnecessary API calls
                );

                duplicatedTemplateGroupItem.formats = originalTemplate.formats;

                // Add the duplicated template to all groups that contained the original, as well as the Recently Added group
                this.templateGroups.forEach(group => {
                    if (
                        group.templates.some(template => template.graphqlId === data.templateId) ||
                        group.groupType.type === TemplateGroupTypeEnum.RecentlyAdded
                    ) {
                        group.templates.unshift(duplicatedTemplateGroupItem);
                    }
                });
            }
        } catch (error) {
            this.sentryService.captureException(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'), error);
            this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
        }
    }

    private async onBuildableTemplateDeleteRequested(data: BuildableTemplateDeleteRequestedMetadata): Promise<void> {
        const gqlClient = this.graphqlService.getClient();

        try {
            const { data: result } = await gqlClient.mutate<
                DeleteIndividualTemplate,
                DeleteIndividualTemplateVariables
            >({
                mutation: DELETE_INDIVIDUAL_TEMPLATE,
                variables: {
                    input: {
                        ids: [data.templateId],
                    },
                },
            });

            if (result?.deleteTemplate?.__typename === 'DeleteTemplateError') {
                this.sentryService.captureException(
                    t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'),
                    result.deleteTemplate,
                );
                this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
                return;
            }

            if (result?.deleteTemplate?.__typename === 'DeleteTemplateSuccess') {
                this.notifier.success(t('BUILD.TEMPLATE_ADMIN.DELETE_TEMPLATE_SUCCESS'));

                // Remove the template from all groups
                this.templateGroups.forEach(group => {
                    const index = group.templates.findIndex(template => template.graphqlId === data.templateId);
                    if (index !== -1) {
                        group.templates.splice(index, 1);
                    }
                });
            }
        } catch (error) {
            this.sentryService.captureException(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'), error);
            this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
        }
    }

    private async onBuildableTemplateTogglePublishStatusRequested(
        data: BuildableTemplateTogglePublishStatusRequestedMetadata,
    ): Promise<void> {
        const gqlClient = this.graphqlService.getClient();

        try {
            const { data: result } = await gqlClient.mutate<ToggleTemplateStatus, ToggleTemplateStatusVariables>({
                mutation: TOGGLE_PUBLISH_STATUS,
                variables: {
                    input: {
                        id: data.templateId,
                        status: data.isDraft ? BuildableTemplateStatus.published : BuildableTemplateStatus.unpublished,
                    },
                },
            });

            if (result?.saveTemplate?.__typename === 'SaveTemplateError') {
                this.sentryService.captureException(
                    t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'),
                    result.saveTemplate,
                );
                this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
                return;
            }

            if (result?.saveTemplate?.__typename === 'SaveTemplateSuccess') {
                this.notifier.success(
                    data.isDraft
                        ? t('BUILD.TEMPLATE_ADMIN.TOGGLE_TEMPLATE_STATUS_PUBLISHED_SUCCESS')
                        : t('BUILD.TEMPLATE_ADMIN.TOGGLE_TEMPLATE_STATUS_UNPUBLISHED_SUCCESS'),
                );

                // Update the template's publish status in all groups
                for (const group of this.templateGroups) {
                    const template = this.dataUtils.findBy('graphqlId', group.templates, data.templateId);
                    if (template) {
                        template.isDraft = !data.isDraft;
                    }
                }
            }
        } catch (error) {
            this.sentryService.captureException(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'), error);
            this.notifier.unexpectedError(t('ERRORS.PUBLISH.ERRORS.GENERIC_ERROR_MESSAGE'));
        }
    }
}
