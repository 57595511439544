import {
    CreateStripeChargeBody,
    CurrencyNumber,
    StripeChargeDto,
    StripeChargeErrorResponse,
    translate,
} from '@deltasierra/shared';
import * as React from 'react';
import { ReactStripeElements } from 'react-stripe-elements';
import BasicModal from '../../../common/commonModalService/BasicModal';
import StripeCheckoutStandaloneForm from './StripeCheckoutStandaloneForm';
import { StripeChargePropsData } from './StripeCheckoutWrapper';

interface StripeCheckoutFormProps {
    amountInCents: CurrencyNumber;
    chargeProcessing: boolean;
    chargeResult: StripeChargeDto | StripeChargeErrorResponse | null;
    createCharge: (data: CreateStripeChargeBody) => void;
    createChargeData: StripeChargePropsData;
    disabled: boolean;
    onSuccess: (chargeResult: StripeChargeDto) => void;
    stripe?: ReactStripeElements.StripeProps;
}

interface StripeCheckoutFormState {
    completed: boolean;
    failureMessage?: string;
    localeCode: string;
    unknownError?: boolean;
}

class StripeCheckoutForm extends React.PureComponent<StripeCheckoutFormProps, StripeCheckoutFormState> {
    public constructor(props: StripeCheckoutFormProps) {
        super(props);
        this.state = { completed: false, localeCode: 'en-AU' };
        if (this.props.createChargeData.localeCode) {
            this.state = { completed: false, localeCode: this.props.createChargeData.localeCode };
        }
    }

    public componentDidUpdate(): void {
        if (this.props.chargeResult && !this.state.completed) {
            const res = this.props.chargeResult;

            if (this.isStripeChargeErrorResponse(res) && res.message) {
                this.setState({ failureMessage: res.message });
            } else if (!this.isStripeChargeErrorResponse(res) && res.status === 'succeeded') {
                this.setState({ completed: true }, () => {
                    this.props.onSuccess(res);
                });
            } else {
                this.setState({ unknownError: true });
            }
        }
    }

    public isStripeChargeErrorResponse(
        res: StripeChargeDto | StripeChargeErrorResponse,
    ): res is StripeChargeErrorResponse {
        if ((res as StripeChargeErrorResponse).type) {
            return true;
        }
        return false;
    }

    public render(): JSX.Element {
        const modalTitle = translate('SHOPPING_CART.CHECKOUT.PRINT_ORDER');
        const buttonText = translate('SHOPPING_CART.CHECKOUT.COMPLETE_PAYMENT');

        return (
            <div className="stripe-payment">
                <div className="stripe-checkout">
                    <BasicModal buttonDisabled={this.props.disabled} buttonText={buttonText} modalTitle={modalTitle}>
                        <StripeCheckoutStandaloneForm
                            amountInCents={this.props.amountInCents}
                            chargeProcessing={this.props.chargeProcessing}
                            chargeResult={this.props.chargeResult}
                            createCharge={this.props.createCharge}
                            createChargeData={this.props.createChargeData}
                            disabled={this.props.disabled}
                            stripe={this.props.stripe}
                            onSuccess={this.props.onSuccess}
                        />
                    </BasicModal>
                </div>
            </div>
        );
    }

    // Reference: https://stripe.com/docs/recipes/elements-react
    // The token id is used as the source

    public submit = async (): Promise<void> => {
        if (this.props.stripe) {
            const { token } = await this.props.stripe.createToken();
            if (token) {
                this.props.createCharge({
                    amountInCents: this.props.amountInCents,
                    currency: this.props.createChargeData.currency,
                    localeCode: this.state.localeCode,
                    printProviderShoppingCartOrderId: this.props.createChargeData.printProviderShoppingCartOrderId,
                    source: token.id,
                    stripeConfigurationId: this.props.createChargeData.stripeConfigurationId,
                });
            }
        }
    };

}

export default StripeCheckoutForm;
