import { DSAutocomplete, DSAutocompleteProps, DSTextField, DSTextFieldProps } from '@deltasierra/components';
import { FormikProps, useField } from 'formik';
import React from 'react';

type WithMappingFunctionsForOptionValue<
    FormikPropsT,
    ValueKey extends keyof FormikPropsT,
    AutocompleteT,
    DisableClearable extends boolean | undefined,
> = {
    getOptionValue?: (
        option: AutocompleteT,
    ) => DisableClearable extends true ? NonNullable<FormikPropsT[ValueKey]> : FormikPropsT[ValueKey];
};

type WithConditionalMappingFunctionsForOptionValue<
    FormikPropsT,
    ValueKey extends keyof FormikPropsT,
    AutocompleteT,
    DisableClearable extends boolean | undefined,
> = WithMappingFunctionsForOptionValue<FormikPropsT, ValueKey, AutocompleteT, DisableClearable> &
    (AutocompleteT extends FormikPropsT[ValueKey]
        ? // eslint-disable-next-line @typescript-eslint/ban-types
          {}
        : Required<WithMappingFunctionsForOptionValue<FormikPropsT, ValueKey, AutocompleteT, DisableClearable>>);

export type DSAutocompleteFormikProps<
    FormikPropsT,
    ValueKey extends keyof FormikPropsT,
    AutocompleteT,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> = Omit<
    DSAutocompleteProps<AutocompleteT, Multiple, DisableClearable, FreeSolo>,
    'name' | 'onBlur' | 'onChange' | 'renderInput' | 'value'
> &
    Pick<DSTextFieldProps, 'error' | 'helperText' | 'InputProps' | 'label'> &
    WithConditionalMappingFunctionsForOptionValue<FormikPropsT, ValueKey, AutocompleteT, DisableClearable> & {
        formik?: FormikProps<FormikPropsT>;
        name: ValueKey;
    };

export function DSAutocompleteFormik<
    FormikPropsT,
    ValueKey extends keyof FormikPropsT,
    AutocompleteT,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    disableClearable,
    /**
     * We pass formik through for type safety only
     */
    error,
    formik: _formik,
    freeSolo,
    getOptionValue,
    helperText: _helperText,
    InputProps,
    label,
    multiple,
    name,
    ...props
}: DSAutocompleteFormikProps<
    FormikPropsT,
    ValueKey,
    AutocompleteT,
    Multiple,
    DisableClearable,
    FreeSolo
>): JSX.Element {
    const [field, meta, helpers] = useField(name as string);

    const onChange = React.useCallback<
        Exclude<DSAutocompleteProps<AutocompleteT, Multiple, DisableClearable, FreeSolo>['onChange'], undefined>
    >(
        (_event, value) => {
            if (getOptionValue) {
                if (value === null) {
                    if (!disableClearable) {
                        helpers.setValue(null);
                    }
                } else {
                    helpers.setValue(getOptionValue(value as AutocompleteT));
                }
            } else {
                helpers.setValue(value as AutocompleteT);
            }
        },
        [getOptionValue, helpers, disableClearable],
    );


    const value: DSAutocompleteProps<AutocompleteT, Multiple, DisableClearable, FreeSolo>['value'] =
        React.useMemo(() => {
            if (getOptionValue) {
                return field.value === null
                    ? null
                    : props.options.find(option => getOptionValue(option) === field.value);
            } else {
                return field.value;
            }
        }, [field.value, getOptionValue, props.options]);

    const showError = !!meta.error && meta.touched;

    const helperText = (showError ? meta.error : undefined) ?? _helperText;

    return (
        <DSAutocomplete
            {...props}
            disableClearable={disableClearable}
            freeSolo={freeSolo}
            multiple={multiple}
            renderInput={params => (
                <DSTextField
                    {...params}
                    InputProps={{ ...params.InputProps, ...InputProps }}
                    error={showError || error}
                    helperText={helperText}
                    label={label}
                    name={field.name}
                />
            )}
            value={value}
            onBlur={field.onBlur}
            onChange={onChange}
        />
    );
}
DSAutocompleteFormik.displayName = 'DSAutocompleteFormik';
