import { justBody, t, LoginApi, SanitizedUser } from '@deltasierra/shared';
import * as React from 'react';
import { Translate } from '../../directives/Translate';
import { OneWayBinding } from '../angularData';
import { Button, ConfirmButton } from '../Button';
import { useAngularServiceContext } from '../componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../componentUtils/reactComponentRegistration';
import { useFuture } from '../futures';
import { invokeApiRoute } from '../httpUtils';
import UserInviteStatusButton from './UserInviteStatusButton';

export type ResetUserPasswordOrResendInviteButtonProps = {
    user: Pick<SanitizedUser, 'hasAcceptedInvite' | 'id' | 'username'>;
};

const ResetUserPasswordOrResendInviteButton: React.FunctionComponent<ResetUserPasswordOrResendInviteButtonProps> = ({ user }) => {
    const { isSending, resend } = useResetPassword(user);
    const { hasAcceptedInvite } = user;

    return (
        <>
            {!hasAcceptedInvite && <UserInviteStatusButton block size="md" user={user} />}
            {hasAcceptedInvite && isSending && <Button block disabled label={{ keyId: 'COMMON.SENDING' }} theme="info" />}
            {hasAcceptedInvite && !isSending && (
                <ConfirmButton
                    block
                    confirmMessage={{ keyId: 'LOGIN.SEND_PASSWORD_RESET_EMAIL_CONFIRMATION_MODAL.BODY' }}
                    confirmText={{ keyId: 'COMMON.SEND' }}
                    confirmTitle={{ keyId: 'COMMON.ARE_YOU_SURE' }}
                    theme="info"
                    onClick={() => resend()}
                >
                    <span className="fas fa-envelope icon-space" />
                    <span>
                        <Translate keyId="LOGIN.SEND_PASSWORD_RESET" />
                    </span>
                </ConfirmButton>
            )}
        </>
    );
};

function useResetPassword({ username }: Pick<SanitizedUser, 'username'>) {
    const $http = useAngularServiceContext('$http');
    const notifier = useAngularServiceContext('mvNotifier');
    const response = useFuture(
        async () => {
            await invokeApiRoute($http, LoginApi.requestResetPassword, justBody({ username }));
            notifier.notify(t('LOGIN.PASSWORD_RESET_INSTRUCTIONS_SENT'));
        },
        [$http, notifier, username],
        { description: t('LOGIN.REQUEST_PASSWORD_RESET') },
    );
    return {
        isSending: response.state.isRunning,
        resend: response.invoke,
    };
}

export default withAngularIntegration(ResetUserPasswordOrResendInviteButton, 'resetUserPasswordOrResendInviteButton', {
    user: OneWayBinding,
});
