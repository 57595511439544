import { gql } from '@apollo/client';

export const GET_USER_FEATURES_QUERY = gql`
    query GetUserFeatures {
        me {
            id
            features {
                dashboardV2
                enableEmailTemplateDivider
                enableEmailTemplatePlaceholder
                enableEmailTemplateSpacer
                showOldEmailTemplatesOption
                templateGalleryV2
            }
        }
    }
`;
