import {
    CreateStripeChargeBody,
    CurrencyNumber,
    getCurrencyString,
    StripeChargeDto,
    StripeChargeErrorResponse,
} from '@deltasierra/shared';
import * as React from 'react';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Translate } from '../../../directives/Translate';
import { StripeChargePropsData } from './StripeCheckoutWrapper';

interface StripeCheckoutStandaloneFormProps {
    amountInCents: CurrencyNumber;
    chargeProcessing: boolean;
    chargeResult: StripeChargeDto | StripeChargeErrorResponse | null;
    createCharge: (data: CreateStripeChargeBody) => void;
    createChargeData: StripeChargePropsData;
    disabled: boolean;
    onSuccess: (chargeResult: StripeChargeDto) => void;
    stripe?: ReactStripeElements.StripeProps;
}

interface StripeCheckoutStandaloneFormState {
    completed: boolean;
    failureMessage?: string;
    localeCode: string;
    unknownError?: boolean;
}

class StripeCheckoutStandaloneForm extends React.PureComponent<
    StripeCheckoutStandaloneFormProps,
    StripeCheckoutStandaloneFormState
> {
    public constructor(props: StripeCheckoutStandaloneFormProps) {
        super(props);
        this.state = { completed: false, localeCode: 'en-AU' };
        if (this.props.createChargeData.localeCode) {
            this.state = { completed: false, localeCode: this.props.createChargeData.localeCode };
        }
    }

    public componentDidUpdate(): void {
        if (this.props.chargeResult && !this.state.completed) {
            const res = this.props.chargeResult;

            if (this.isStripeChargeErrorResponse(res) && res.message) {
                this.setState({ failureMessage: res.message });
            } else if (!this.isStripeChargeErrorResponse(res) && res.status === 'succeeded') {
                this.setState({ completed: true }, () => {
                    this.props.onSuccess(res);
                });
            } else {
                this.setState({ unknownError: true });
            }
        }
    }

    public isStripeChargeErrorResponse(
        res: StripeChargeDto | StripeChargeErrorResponse,
    ): res is StripeChargeErrorResponse {
        if ((res as StripeChargeErrorResponse).type) {
            return true;
        }
        return false;
    }

    public render(): JSX.Element {
        const paymentAmount = getCurrencyString(
            this.props.amountInCents,
            this.props.createChargeData.currency.toUpperCase(),
            undefined,
            true,
        );

        return (
            <div className="stripe-checkout">
                <span>
                    <Translate keyId="SHOPPING_CART.CHECKOUT.CARD_DETAILS" />
                </span>
                {/* Note that ZIP and postal code validation depends on your customer’s billing country. */}
                {/* https://stripe.com/docs/stripe-js/reference#postal-code-formatting */}
                <CardElement hidePostalCode={true} />
                {this.state.failureMessage && !this.props.chargeProcessing && (
                    <small className="error pad-bottom">{this.state.failureMessage}</small>
                )}
                {this.state.unknownError && !this.props.chargeProcessing && (
                    <small className="error pad-bottom">
                        <Translate keyId="SHOPPING_CART.CHECKOUT.PAYMENT_PENDING" />
                    </small>
                )}
                {this.props.chargeProcessing && <div className="loading-small" />}
                {!this.props.chargeProcessing && !this.state.completed && (
                    <button className="btn btn-primary full-width" onClick={async () => this.submit()}>
                        {/* Replace amount value with a proper filter like angular's money filter */}
                        <Translate
                            keyId="SHOPPING_CART.CHECKOUT.PAY_AMOUNT"
                            options={{
                                amount: paymentAmount,
                            }}
                        />
                    </button>
                )}
                {!this.props.chargeProcessing && this.state.completed && (
                    <h4>
                        <Translate keyId="SHOPPING_CART.CHECKOUT.PAYMENT_SUCCESSFUL" />
                    </h4>
                )}
            </div>
        );
    }

    // Reference: https://stripe.com/docs/recipes/elements-react
    // The token id is used as the source

    public submit = async (): Promise<void> => {
        if (this.props.stripe) {
            const { token } = await this.props.stripe.createToken();
            if (token) {
                this.props.createCharge({
                    amountInCents: this.props.amountInCents,
                    currency: this.props.createChargeData.currency,
                    description: this.props.createChargeData.description,
                    localeCode: this.state.localeCode,
                    locationMapId: this.props.createChargeData.locationMapId,
                    printProviderShoppingCartOrderId: this.props.createChargeData.printProviderShoppingCartOrderId,
                    source: token.id,
                    stripeConfigurationId: this.props.createChargeData.stripeConfigurationId,
                });
            }
        }
    };

}

export default injectStripe(StripeCheckoutStandaloneForm);
