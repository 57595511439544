import * as React from 'react';
import { IInstagramPublishData, InstagramDirectPublishFormData, PublishMultiImageRequest } from '@deltasierra/shared';

import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { MultiImagePublishCallback, MultiImagePublishCallbackArgs } from '../types';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { ImagePublishServiceCompletionEvent } from '../ImagePublishService';
import { useCanPostToInstagramForLocation, useImageProcessingContext } from '../common/hooks';

const usePublishPhotoCallback = () => {
    const instagramPublishService = useAngularServiceContext('instagramPublishService');
    return React.useCallback(
        async (input: PublishMultiImageRequest<IInstagramPublishData>) =>
            instagramPublishService.publishPhotoDirect(input),
        [instagramPublishService],
    );
};

export const useInstagramImagePublishCallback = (
    publishController: MvContentBuilderPublishCtrl,
): MultiImagePublishCallback<InstagramDirectPublishFormData> => {
    const imagePublishService = useAngularServiceContext('ImagePublishService');
    const i18n = useAngularServiceContext('I18nService');
    const identity = useAngularServiceContext('mvIdentity');
    const canPostToLocation = useCanPostToInstagramForLocation();
    const publishPhotoCallback = usePublishPhotoCallback();
    const ImageProcessingContext = useImageProcessingContext();
    const currentUser = identity.currentUser;

    return React.useCallback<MultiImagePublishCallback<InstagramDirectPublishFormData>>(
        async ({
            commonData,
            locationGraphqlId,
            locationId,
            publishedArtifactGroupId,
            scheduledPublishGroupId,
            uploadIds,
        }: MultiImagePublishCallbackArgs<InstagramDirectPublishFormData>) => {
            const canPostToLocationResult = await canPostToLocation(locationId);
            if (currentUser && canPostToLocationResult) {
                return imagePublishService.publishMultiImage<IInstagramPublishData>({
                    channelData: {
                        ...commonData,
                        assignedUserId: currentUser.id,
                        notificationPhoneNumber: null,
                    },
                    imageProcessingContext: ImageProcessingContext.current,
                    linkedAssetLibraryAssetIds: publishController.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        layerId: value.layerId,
                        legacyAssetId: value.asset.id,
                    })),
                    locationId: locationGraphqlId,
                    plannerDetails: publishController.plannerDetails || null,
                    publishCallback: publishPhotoCallback,
                    publishedArtifactGroupId,
                    scheduledPublishGroupId,
                    scheduledTime: commonData.scheduledTime || undefined,
                    successEvent:
                        commonData.scheduledTime !== null
                            ? ImagePublishServiceCompletionEvent.PlannerCreated
                            : ImagePublishServiceCompletionEvent.Published,
                    templateId: publishController.templateId,
                    uploadIds,
                });
            } else {
                throw new Error(i18n.text.build.publish.instagram.accessCheckFailure());
            }
        },
        [
            ImageProcessingContext,
            canPostToLocation,
            currentUser,
            i18n.text.build.publish.instagram,
            imagePublishService,
            publishController.contentBuilder.linkedAssetLibraryAsset,
            publishController.plannerDetails,
            publishController.templateId,
            publishPhotoCallback,
        ],
    );
};
