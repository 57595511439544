import { useMutation } from '@apollo/client';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { COPY_TEMPLATE_MUTATION } from './CopyTemplateModal.mutations';
import {
    CopyBuilderTemplateMutation,
    CopyBuilderTemplateMutationVariables,
} from './__graphqlTypes/CopyBuilderTemplateMutation';

export const useCopyTemplate = (): {
    handleCancel: () => void;
    handleClickCopy: React.MouseEventHandler<Element>;
    handleCopyMutation: (
        clientIds: string[],
        builderTemplateId: string,
        includeCategories: boolean,
        isCopyAsUnpublished: boolean,
    ) => Promise<void>;
    loading: boolean;
    showModal: boolean;
} => {
    const notifier = useAngularServiceContext('mvNotifier');
    const [showModal, setShowModal] = React.useState(false);

    const [copyTemplateToClients, { loading }] = useMutation<
        CopyBuilderTemplateMutation,
        CopyBuilderTemplateMutationVariables
    >(COPY_TEMPLATE_MUTATION);

    const handleClickCopy: React.MouseEventHandler = () => {
        setShowModal(true);
    };

    const handleCopyMutation = async (
        clientIds: string[],
        builderTemplateId: string,
        includeCategories: boolean,
        isCopyAsUnpublished: boolean,
    ) => {
        const result = await copyTemplateToClients({
            variables: {
                input: {
                    builderTemplateId,
                    clientIds,
                    includeCategories,
                    isCopyAsUnpublished,
                },
            },
        });
        if (result.data?.copyBuilderTemplate.__typename === 'CopyBuilderTemplateSuccess') {
            const { edges } = result.data.copyBuilderTemplate.clients;
            if (edges.length > 5) {
                const firstFiveClientTitles = edges
                    .slice(0, 5)
                    .map(({ node }) => node.title)
                    .join(', ');
                const remainingClientCount = edges.slice(5).length;
                notifier.success(
                    t('BUILD.TEMPLATE_ADMIN.COPY_SUCCESS_EXTENDED', {
                        clients: firstFiveClientTitles,
                        remainingClientCount,
                    }),
                );
            } else {
                notifier.success(
                    t('BUILD.TEMPLATE_ADMIN.COPY_SUCCESS', {
                        clients: edges.map(({ node }) => node.title).join(', '),
                    }),
                );
            }
        } else if (result.data?.copyBuilderTemplate.__typename === 'CopyBuilderTemplateError') {
            Sentry.captureMessage(result.data?.copyBuilderTemplate.message);
            notifier.unexpectedError(t('BUILD.TEMPLATE_ADMIN.COPY_ERROR'));
        } else {
            notifier.unexpectedError(t('ERRORS.GENERIC'));
        }

        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return {
        handleCancel,
        handleClickCopy,
        handleCopyMutation,
        loading,
        showModal,
    };
};
