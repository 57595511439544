import * as React from 'react';
import { t, Upload } from '@deltasierra/shared';
import { DSGrid, DSTypography } from '@deltasierra/components';
import { UploadButton } from '../../../campaign/components/UploadSection/UploadButton';
import { UploadBlock } from '../UploadBlock';

type EditLocationLogoProps = {
    logo: Upload | null;
    deleteLogo: () => void;
    uploadLogo: (file: File) => Promise<void>;
};
export function EditLocationLogo({ deleteLogo, logo, uploadLogo }: EditLocationLogoProps): JSX.Element {
    const [uploading, setUploading] = React.useState(false);
    const onChange: React.ChangeEventHandler<HTMLInputElement> = async (ev): Promise<void> => {
        if (ev.target.files && ev.target.files.length > 0) {
            setUploading(true);
            return uploadLogo(Array.from(ev.target.files)[0]).finally(() => setUploading(false));
        }
        return Promise.resolve();
    };

    return (
        <DSGrid container style={{ marginTop: 10 }}>
            <DSGrid item md={2} sm={3} xs={12}>
                <DSTypography component="label" variant="inherit">
                    {t('ACCOUNT.DETAILS.LOCATION_LOGO')}
                </DSTypography>
            </DSGrid>
            <DSGrid item>
                {!logo && <UploadButton disabled={uploading} onChange={onChange} />}
                {logo && (
                    <UploadBlock
                        deleteUpload={deleteLogo}
                        height={128}
                        titleVisible={false}
                        upload={logo}
                        width={128}
                    />
                )}
            </DSGrid>
        </DSGrid>
    );
}
EditLocationLogo.displayName = 'EditLocationLogo';
