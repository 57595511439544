import { Untyped, ClientExternalLink, ClientId } from '@deltasierra/shared';


import { InteractionUtils } from '../../common/interactionUtils';
import { DataUtils } from '../../common/dataUtils';
import { I18nService } from '../../i18n/i18nService';
import { MvClient } from '../mvClient';
import { $qSID, $routeParamsSID, IRouteParams } from '../../common/angularData';
import IQService = angular.IQService;

export class ClientExternalLinksController {
    static SID = 'ClientExternalLinksController';

    private readonly retrieveExternalLinks = this.interactionUtils.createFuture(this.i18n.text.agency.client.links.getLinks(), () => this.clientService.getClientLinks(this.client_id)
            .then(links => {
                this.links = links.sort((a, b) => a.order - b.order);
            }));

    public readonly addNewLink = this.interactionUtils.createFuture(this.i18n.text.agency.client.links.createNewLink(), () => this.clientService.createNewClientLink(this.client_id, this.newLink)
            .then(link => {
                this.links!.push(link);
                this.newLink = {
                    title: null,
                    url: null,
                    description: null,
                };
            }));

    public readonly deleteLink = this.interactionUtils.createFuture(this.i18n.text.agency.client.links.deleteLink(), (context: { link: ClientExternalLink }) => this.clientService.deleteClientLink(context.link.clientId, context.link.id)
            .then(deletedLink => {
                this.links = this.links!.filter(link => link.id != deletedLink.id);
            }));

    private readonly updateClientLinksOrder = this.interactionUtils.createFuture(this.i18n.text.agency.client.links.updateOrder(), () => {
        const ids = this.dataUtils.pluckByGetter(link => link.id, this.links!);

        return this.clientService.updateClientLinksOrder(ids, this.client_id)
            .then(() => {
                for (const link of this.links!) {
                    link.order = ids.indexOf(link.id);
                }
            });
    });

    client_id : ClientId;

    links? : ClientExternalLink[];

    newLink = {
        title: null as Untyped,
        url: null as Untyped,
        description: null as Untyped,
    };

    static readonly $inject : string[] = [
        $routeParamsSID,
        $qSID,
        InteractionUtils.SID,
        DataUtils.SID,
        I18nService.SID,
        MvClient.SID,
    ];

    constructor(
        $routeParams : IRouteParams,
        $q : IQService,
        private readonly interactionUtils : InteractionUtils,
        private readonly dataUtils : DataUtils,
        private readonly i18n : I18nService,
        private readonly clientService: MvClient,
    ) {
        this.client_id = ClientId.from(parseInt($routeParams.id, 10));
        void this.retrieveExternalLinks.run({});
    }

    moveOrderUp(link: ClientExternalLink) {
        const wasMoved = this.dataUtils.moveUp(link, this.links!);
        if (wasMoved) {
            return this.updateClientLinksOrder.run({});
        }
    }

    moveOrderDown(link: ClientExternalLink) {
        const wasMoved = this.dataUtils.moveDown(link, this.links!);
        if (wasMoved) {
            return this.updateClientLinksOrder.run({});
        }
    }
}

angular.module('app').controller(ClientExternalLinksController.SID, ClientExternalLinksController);
