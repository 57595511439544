/* eslint-disable consistent-return */
import { Upload } from '@deltasierra/shared';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useUploadAssetsState } from './state';

const POLL_INTERVAL = 5000;
const BATCH_TO_POLL_FOR = 5;

type UseThumbnailPollerOptions = {
    fileDispatch: ReturnType<typeof useUploadAssetsState>[1];
    fileState: ReturnType<typeof useUploadAssetsState>[0];
};

export function useThumbnailPoller({ fileDispatch, fileState }: UseThumbnailPollerOptions) {
    const uploadService = useAngularServiceContext('uploadService');

    const [uploadsToPollFor, setUploadsToPollFor] = useState<Upload[]>([]);

    /**
     * This is a hacky attempt at polling for the updated thumbnail from the backend.
     * It really messes with the load times if there is a lot of things attempting to
     * refetch the thumbnail for.
     */
    useEffect(() => {
        if (!(uploadsToPollFor.length > 0)) {
            return;
        }

        const timeoutHandle = setTimeout(async () => {
            const firstX = uploadsToPollFor.slice(0, BATCH_TO_POLL_FOR);
            try {
                await Promise.all(
                    firstX.map(async ({ id: uploadId, key }) => {
                        const { thumb256x256url } = await uploadService.getUploadThumbnail(uploadId, key);
                        if (thumb256x256url !== null) {
                            fileDispatch({
                                payload: { id: uploadId, thumb256x256url },
                                type: 'UPDATE_FILE_THUMBNAIL',
                            });
                        }
                    }),
                );
            } catch (error) {
                Sentry.captureException(error);
            } finally {
                // Finally remove all these uploads from the list
                setUploadsToPollFor(newUploadsToPollFor =>
                    newUploadsToPollFor.filter(newUpload => !firstX.some(xUpload => xUpload.id === newUpload.id)),
                );
            }
        }, POLL_INTERVAL);
        return () => {
            clearTimeout(timeoutHandle);
        };
    }, [fileDispatch, uploadService, uploadsToPollFor]);

    useEffect(() => {
        const completedFilesWithoutThumbnails = fileState.completed
            .filter(({ upload }) => upload.thumb256x256url === null)
            .filter(({ upload }) => {
                // Thumbnails are only generated for image & video files
                const isVideoFormat = upload.contentType.includes('video');
                return upload.isImage || isVideoFormat;
            });
        if (completedFilesWithoutThumbnails.length > 0) {
            setUploadsToPollFor(newUploadsToPollFor => [
                ...newUploadsToPollFor,
                ...completedFilesWithoutThumbnails
                    .map(({ upload }) => upload)
                    .filter(cUpload => !newUploadsToPollFor.some(newUpload => newUpload.id === cUpload.id)),
            ]);
        }
    }, [fileState.completed]);
}
