import * as React from 'react';
import { noop } from '@deltasierra/object-utilities';
import { useAngularComponentRenderer, useAngularScope } from '../../componentUtils/angularRendering';

type DatePickerPopupScope = {
    date: Date | null;
    isOpen: boolean;
    minDate?: Date | null;
    onChange: () => void;
};

export type DateOnlyPickerProps = {
    value: Date | null;
    min?: Date | null;
    onChange?: (value: Date | null) => void;
};

export const DateOnlyPicker: React.FunctionComponent<DateOnlyPickerProps> = ({ min, onChange = noop, value }) => {
    const scope = useAngularScope<DatePickerPopupScope>({ date: value, isOpen: false, minDate: min });
    scope.current.onChange = () => {
        onChange(scope.current.date);
    };
    const html = `
<div class="input-group">
    <input
        class="form-control"
        type="text"
        datepicker-popup="yyyy-MM-dd"
        ng-model="date"
        ng-change="onChange()"
        is-open="isOpen"
        close-text="Close"
        min-date="minDate"
    />
    <span class="input-group-btn">
        <button class="btn btn-default" type="button" ng-click="isOpen = true">
            <i class="glyphicon glyphicon-calendar"></i>
        </button>
    </span>
</div>
`;
    return useAngularComponentRenderer(html, scope);
};

DateOnlyPicker.displayName = 'DateOnlyPicker';
