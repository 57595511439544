import * as React from 'react';
import { Translate, TranslateProps } from '../../directives/Translate';

export type ToggleProps = {
    disabled?: boolean;
    label: TranslateProps;
    onChange: (newValue: boolean) => void;
    value: boolean;
};

export const Toggle: React.FunctionComponent<ToggleProps> = ({ disabled, label, onChange, value }) => (
    <div className="checkbox unselectable">
        <label>
            <input checked={value} disabled={disabled} type="checkbox" value="toggle" onChange={event => onChange(event.target.checked)} />
            <span>
                <Translate {...label} />
            </span>
        </label>
    </div>
);
