/* eslint-disable sort-keys,max-lines,max-lines-per-function */
import { Untyped, convertDateStringsToDates, Roles } from '@deltasierra/shared';
import { translate } from '@deltasierra/i18n';
import type {
    ICompileProvider,
    ILocationProvider,
    ISCEDelegateProvider,
    IHttpProvider,
    IHttpResponseTransformer,
    IExceptionHandlerService,
} from 'angular';
import { isTranslatableError } from './common/exceptions';
import { MvNotifier } from './common/mvNotifier';
import { MvAuth } from './account/mvAuth';
import { DashboardController } from './main/dashboard';
import { mvAgencyListCtrlSID } from './agencies/mvAgencyListCtrl';
import { MvBuilderTemplateGalleryCtrl } from './templateGallery/mvBuilderTemplateGalleryCtrl';
import { networkDropoutInterceptorSID } from './common/networkDropoutInterceptor';
import { ScheduledSiteMaintenanceCtrl } from './admin/scheduledSiteMaintenanceCtrl';
import BannerNotificationController from './admin/bannerNotification/controller';
import { MvSubscriptionListCtrl } from './admin/subscriptions/mvSubscriptionListCtrl';
import { MvClientSubscriptionDetailCtrl } from './admin/subscriptions/mvClientSubscriptionDetailCtrl';
import { MvSubscriptionLocationListCtrl } from './admin/subscriptions/mvSubscriptionLocationListCtrl';
import { MvLocationSubscriptionDetailCtrl } from './admin/subscriptions/mvLocationSubscriptionDetailCtrl';
import { RecurringPaymentDetailCtrl } from './admin/subscriptions/recurringPaymentDetailCtrl';
import { TasksCtrl } from './admin/tasksCtrl';
import { AgencyContactDetailsController } from './agency/contactDetails';
import { PayableServicesController } from './admin/payableServices/controller';
import { ExternalAuthAppsController } from './admin/externalAuthApps/controller';
import { ExternalAuthAppDetailsController } from './admin/externalAuthApps/details/controller';
import { MapsReportController } from './admin/mapsReport/controller';
import { AgencyClientUsersController, AgencySKUGroupsController, SKUDetailsController } from './agency';
import { MvClientUserEditCtrl } from './clients/mvClientUserEditCtrl';
import { MvClientUserLocationsCtrl } from './clients/mvClientUserLocationsCtrl';
import { AgencyNotificationsCtrl } from './agency/notifications/controller';
import { MvAppAuthLocationCtrl } from './integration/auth/mvAppAuthLocationCtrl';
import { MvAppAuthClientCtrl } from './integration/auth/mvAppAuthClientCtrl';
import { MvBillingDetailsUpdateCtrl } from './payments/billingDetailsUpdate/mvBillingDetailsUpdateCtrl';
import { MvBillingDetailsUpdateFinaliseCtrl } from './payments/billingDetailsUpdate/mvBillingDetailsUpdateFinaliseCtrl';
import { LoginController } from './account/mvLoginCtrl';
import { MvResetPasswordCtrl } from './account/mvResetPasswordCtrl';
import { MvForgotPasswordCtrl } from './account/mvForgotPasswordCtrl';
import { LocationDetailsController } from './account/locationDetailsController';
import { ProfileController } from './account/profile/controller';
import { MvClientListCtrl } from './clients/mvClientListCtrl';
import { ClientActivitiesController } from './clients/activities';
import { BuilderTemplateCategoriesController } from './clients/builderTemplateCategories/builderTemplateCategoriesController';
import { ClientLearnMenuCtrl } from './clients/clientLearnMenuCtrl';
import { MvLocationDetailCtrl } from './locations/mvLocationDetailCtrl';
import { ClientExternalLinksController } from './clients/externalLinks/controller';
import { ClientBrandColoursController } from './clients/brandColours/controller';
import { MvClientUsersCtrl } from './clients/mvClientUsersCtrl';
import { InAppNotificationsListController } from './inAppNotifications';
import { MvPlannerEventCtrl } from './planner/mvPlannerEventCtrl';
import { MvReportSummaryEditCtrl } from './reports/summary/mvReportSummaryEditCtrl';
import { MvSpecialRequestsCtrl } from './specialRequests/mvSpecialRequestsCtrl';
import { MvSpecialRequestDetailsCtrl } from './specialRequests/mvSpecialRequestDetailsCtrl';
import { MvOverviewCtrl } from './overview/mvOverviewCtrl';
import { MvTryThisCtrl } from './trythis/mvTryThisCtrl';
import { LearnArticleEditController } from './trythis/mvTryThisEditCtrl';
import { MvTryThisDetailsCtrl } from './trythis/mvTryThisDetailsCtrl';
import { ScheduledPostController } from './scheduledPosts/controller';
import { InAppNotificationsDetailsController } from './inAppNotifications/details/controller';
import { IRouteProvider, RouteAuthResolver } from './common/angularData';
import { MemberEngagerCtrl } from './contentBuilder/memberEngager/memberEngager';
import { MemberEngagerAutomatedEmailCtrl } from './contentBuilder/memberEngager/automatedEmail/memberEngagerAutomatedEmail';
import { TaOptions, TaRegisterTool } from './typings/textAngular/textAngular';
import { registerClubReadyMailMergeTool, registerFitwareMailMergeTool } from './contentBuilder/common/textAngularTools';
import { I18nService } from './i18n';
import { SKUGroupPrintProvidersController } from './agency/printProviderSKUGroups/printProviders/controller';
import { SKUGroupSKUSController } from './agency/printProviderSKUGroups/skus/controller';
import { SKUGroupDetailsController } from './agency/printProviderSKUGroups/details/controller';
import { CheckoutController } from './shoppingCart';
import { MvLocationCategoriesCtrl } from './locations/mvLocationCategoriesCtrl';
import { StripeAuthRequestCtrl } from './integration/stripe/stripeAuthRequestCtrl';
import { StripeAuthResponseCtrl } from './integration/stripe/stripeAuthResponseCtrl';
import { MvAgencyUserEditCtrl } from './agencies/mvAgencyUserEditCtrl';
import IProvideService = angular.auto.IProvideService;

const $compileProviderSID = '$compileProvider';
const $delegateSID = '$delegate';
const $httpProviderSID = '$httpProvider';
const $kookiesProviderSID = '$kookiesProvider';
const $locationProviderSID = '$locationProvider';
const $provideSID = '$provide';
const $routeProviderSID = '$routeProvider';
const $sceDelegateProviderSID = '$sceDelegateProvider';

angular.module('app').config([
    $routeProviderSID,
    $locationProviderSID,
    $kookiesProviderSID,
    $httpProviderSID,
    $sceDelegateProviderSID,
    $provideSID,
    $compileProviderSID,
    // eslint-disable-next-line max-params
    function configRoutes(
        $routeProvider: IRouteProvider,
        $locationProvider: ILocationProvider,
        $kookiesProvider: Untyped,
        $httpProvider: IHttpProvider,
        $sceDelegateProvider: ISCEDelegateProvider,
        $provide: IProvideService,
        $compileProvider: ICompileProvider,
    ) {
        const routeRoleChecks: {
            admin: RouteAuthResolver;
            user: RouteAuthResolver;
            manager: RouteAuthResolver;
            reportsAccess: RouteAuthResolver;
            builderAccess: RouteAuthResolver;
            specialRequestAccess: RouteAuthResolver;
        } = {
            admin: {
                auth: [MvAuth.SID, (mvAuth: MvAuth) => mvAuth.authorizeCurrentUserForRoute(Roles.admin)],
            },
            user: {
                auth: [MvAuth.SID, (mvAuth: MvAuth) => mvAuth.authorizeAuthenticatedUserForRoute()],
            },
            manager: {
                auth: [MvAuth.SID, (mvAuth: MvAuth) => mvAuth.authorizeCurrentUserForRoute(Roles.manager)],
            },
            reportsAccess: {
                auth: [
                    MvAuth.SID,
                    (mvAuth: MvAuth) => mvAuth.authorizeCurrentUserForRoute(Roles.manager, Roles.analyst),
                ],
            },
            builderAccess: {
                auth: [
                    MvAuth.SID,
                    (mvAuth: MvAuth) => mvAuth.authorizeCurrentUserForRoute(Roles.manager, Roles.builder),
                ],
            },
            specialRequestAccess: {
                auth: [
                    MvAuth.SID,
                    (mvAuth: MvAuth) => mvAuth.authorizeCurrentUserForRoute(Roles.manager, Roles.specialRequester),
                ],
            },
        };
        $provide.decorator('$exceptionHandler', [
            $delegateSID,
            MvNotifier.SID,
            ($delegate: IExceptionHandlerService, mvNotifier: MvNotifier) => (exception: any, cause?: any) => {
                if (exception && exception.message) {
                    let message;
                    let loggingMessage;

                    if (isTranslatableError(exception)) {
                        message = translate(exception.key, exception.translateOptions);
                        loggingMessage = translate(
                            exception.key,
                            angular.extend({}, exception.translateOptions || {}, { localeCode: 'en-AU' }),
                        );
                    } else {
                        message = exception.message;
                    }

                    mvNotifier.unexpectedError(message, {
                        bypassLogging: exception.bypassLogging,
                        loggingMessage,
                        data: exception,
                    });
                } else {
                    mvNotifier.unexpectedErrorWithData('Error', exception);
                }
                $delegate(exception, cause);
            },
        ]);

        $kookiesProvider.config.path = '/'; // Default to making cookies available across the entire domain
        $kookiesProvider.config.json = true;
        $httpProvider.interceptors.push(networkDropoutInterceptorSID);
        $httpProvider.interceptors.push('mvAuthInterceptor');
        $httpProvider.interceptors.push('serviceMaintenanceInterceptor');
        ($httpProvider.defaults.transformResponse as IHttpResponseTransformer[]).push((data, headersGetter) => {
            const responseType = headersGetter('Content-Type');
            if (responseType && responseType.lastIndexOf('application/json', 0) !== 0) {
                return data;
            } else {
                return convertDateStringsToDates(data, true);
            }
        });
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'https://content-dev.digitalstack.io/**',
            'https://content.digitalstack.io/**',
        ]);
        // Whitelist blobs generated on the same domain.
        $compileProvider.aHrefSanitizationWhitelist(
            /^\s*((https?|ftp|mailto|tel|file):|blob:https:\/\/[^.]+\.digitalstack.io(:\d+)?\/.+)/,
        );

        function getYPosition(elem: HTMLElement) {
            const rect = elem.getBoundingClientRect();
            // Rect is relative to the viewport, so calculate page rect from the document's rect
            const bodyRect = document.body.getBoundingClientRect();
            const top = rect.top - bodyRect.top;
            return top;
        }

        function getXPosition(elem: HTMLElement): number {
            const rect = elem.getBoundingClientRect();
            // Rect is relative to the viewport, so calculate page rect from the document's rect
            const bodyRect = document.body.getBoundingClientRect();
            const left = rect.left - bodyRect.left;
            return left;
        }

        Chart.defaults.global.customTooltips = tooltip => {
            // Tooltip Element
            const tooltipEl = $('#chartjs-tooltip');

            // Hide if no tooltip
            if (!tooltip) {
                tooltipEl.css({
                    opacity: 0,
                });
                return;
            }

            // Set caret Position
            tooltipEl.removeClass('above below');
            tooltipEl.addClass(tooltip.yAlign);

            // Set Text
            tooltipEl.html(tooltip.text);

            // Find Y Location on page
            let top: number;
            if (tooltip.yAlign === 'above') {
                top = tooltip.y - tooltip.caretHeight - tooltip.caretPadding;
            } else {

                top = tooltip.y + tooltip.caretHeight + tooltip.caretPadding;
            }

            const chartX = getXPosition(tooltip.chart.canvas);
            const chartY = getYPosition(tooltip.chart.canvas);

            // Display, position, and set styles for font
            tooltipEl.css({
                opacity: 1,

                left: `${chartX + tooltip.x}px`,
                top: `${chartY + top}px`,
                fontFamily: tooltip.fontFamily,
                fontSize: tooltip.fontSize,
                fontStyle: tooltip.fontStyle,
            });
        };

        // Add custom textAngular toolbar components
        $provide.decorator('taOptions', [
            I18nService.SID,
            'taRegisterTool',
            '$delegate',
            (i18n: I18nService, taRegisterTool: TaRegisterTool, taOptions: TaOptions): TaOptions => {
                registerFitwareMailMergeTool(taRegisterTool, i18n);
                registerClubReadyMailMergeTool(taRegisterTool, i18n);
                return taOptions;
            },
        ]);

        $locationProvider.html5Mode(true);
        $routeProvider
            .when('/', {
                templateUrl: '/partials/main/dashboard/template',
                controller: DashboardController,
                controllerAs: 'ctrl',
            })
            .when('/admin/', {
                redirectTo: () => '/admin/siteMaintenance',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/agencies', {
                templateUrl: '/partials/agencies/agency-list',
                controller: mvAgencyListCtrlSID,
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/agencies/:agencyId/users/:userId/edit', {
                templateUrl: '/partials/agencies/agency-user-edit',
                controller: MvAgencyUserEditCtrl.SID,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/siteMaintenance', {
                templateUrl: '/partials/admin/scheduled-site-maintenance',
                controller: ScheduledSiteMaintenanceCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/bannerNotification', {
                templateUrl: '/partials/admin/bannerNotification/view',
                controller: BannerNotificationController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/brands', {
                templateUrl: '/partials/admin/brands/template',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/servicing', {
                templateUrl: '/partials/admin/servicing/template',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/clubready-connect', {
                templateUrl: '/partials/admin/clubreadyConnect/template',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/subscriptions', {
                templateUrl: '/partials/admin/subscriptions/subscriptionList',
                controller: MvSubscriptionListCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/subscriptions/clients/:client_id', {
                templateUrl: '/partials/admin/subscriptions/clientSubscriptionDetail',
                controller: MvClientSubscriptionDetailCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/subscriptions/clients/:client_id/locations', {
                templateUrl: '/partials/admin/subscriptions/subscriptionLocationList',
                controller: MvSubscriptionLocationListCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/subscriptions/clients/:client_id/locations/:location_id', {
                templateUrl: '/partials/admin/subscriptions/locationSubscriptionDetail',
                controller: MvLocationSubscriptionDetailCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/subscriptions/clients/:client_id/locations/:location_id/recurringPayments/add', {
                templateUrl: '/partials/admin/subscriptions/recurringPaymentDetail',
                controller: 'recurringPaymentDetailCtrl',
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when(
                '/admin/subscriptions/clients/:client_id/locations/:location_id/recurringPayments/:recurring_payment_id',
                {
                    templateUrl: '/partials/admin/subscriptions/recurringPaymentDetail',
                    controller: RecurringPaymentDetailCtrl,
                    controllerAs: 'ctrl',
                    resolve: routeRoleChecks.admin,
                },
            )
            .when('/admin/tasks', {
                templateUrl: '/partials/admin/tasks',
                controller: TasksCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/agency/contactDetails', {
                templateUrl: '/partials/agency/contactDetails/template',
                controller: AgencyContactDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/admin/payable-services', {
                templateUrl: '/partials/admin/payableServices/template',
                controller: PayableServicesController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/external-authentication-apps', {
                templateUrl: '/partials/admin/externalAuthApps/template',
                controller: ExternalAuthAppsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/external-authentication-apps/:id', {
                templateUrl: '/partials/admin/externalAuthApps/details/template',
                controller: ExternalAuthAppDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/admin/maps-report', {
                templateUrl: '/partials/admin/mapsReport/template',
                controller: MapsReportController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.admin,
            })
            .when('/agency/clientUsers', {
                templateUrl: '/partials/agency/clientUsers/template',
                controller: AgencyClientUsersController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/clientUsers/:userId/edit', {
                templateUrl: '/partials/clients/client-user-edit',
                controller: MvClientUserEditCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/clientUsers/:userId/locations', {
                templateUrl: '/partials/clients/client-user-locations',
                controller: MvClientUserLocationsCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/notifications', {
                templateUrl: '/partials/agency/notifications/notifications',
                controller: AgencyNotificationsCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/printProviderSKUGroups', {
                templateUrl: '/partials/agency/printProviderSKUGroups/template',
                controller: AgencySKUGroupsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/printProviderSKUGroups/:skuGroupId', {
                templateUrl: '/partials/agency/printProviderSKUGroups/skus/template',
                controller: SKUGroupSKUSController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/printProviderSKUGroups/:skuGroupId/sku/:skuId?', {
                templateUrl: '/partials/agency/printProviderSKUGroups/skus/skuDetails/template',
                controller: SKUDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/printProviderSKUGroups/:skuGroupId/details', {
                templateUrl: '/partials/agency/printProviderSKUGroups/details/template',
                controller: SKUGroupDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/agency/printProviderSKUGroups/:skuGroupId/printProviders', {
                templateUrl: '/partials/agency/printProviderSKUGroups/printProviders/template',
                controller: SKUGroupPrintProvidersController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/checkout', {
                templateUrl: '/partials/shoppingCart/checkout/template',
                controller: CheckoutController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/legacy-connect', {
                templateUrl: '/partials/integration/auth/appAuthLocation',
                controller: MvAppAuthLocationCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/legacy-connect/client', {
                templateUrl: '/partials/integration/auth/appAuthClient',
                controller: MvAppAuthClientCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/connect/agency', {
                redirectTo: '/connect',
            })
            .when('/apps', {
                redirectTo: '/connect',
            })
            .when('/apps/client', {
                redirectTo: '/connect',
            })
            .when('/apps/agency', {
                redirectTo: '/connect',
            })
            .when('/updateBillingDetails', {
                templateUrl: '/partials/payments/billingDetailsUpdate/billingDetailsUpdate',
                controller: MvBillingDetailsUpdateCtrl,
                controllerAs: 'ctrl',
            })
            .when('/updateBillingDetails/finalise', {
                templateUrl: '/partials/payments/billingDetailsUpdate/billingDetailsUpdateFinalise',
                controller: MvBillingDetailsUpdateFinaliseCtrl,
                controllerAs: 'ctrl',
            })
            .when('/login', {
                templateUrl: '/partials/account/login',
                controller: LoginController,
                controllerAs: 'ctrl',
            })
            .when('/resetPassword', {
                templateUrl: '/partials/account/resetPassword',
                controller: MvResetPasswordCtrl,
                controllerAs: 'ctrl',
            })
            .when('/forgotPassword', {
                templateUrl: '/partials/account/forgotPassword',
                controller: MvForgotPasswordCtrl,
                controllerAs: 'ctrl',
            })
            .when('/stripe/authRequest', {
                templateUrl: '/partials/integration/stripe/stripeAuthRequest',
                controller: StripeAuthRequestCtrl,
                controllerAs: 'ctrl',
            })
            .when('/stripe/authResponse', {
                templateUrl: '/partials/integration/stripe/stripeAuthResponse',
                controller: StripeAuthResponseCtrl,
                controllerAs: 'ctrl',
            })
            .when('/locationDetails', {
                templateUrl: '/partials/account/locationDetails',
                controller: LocationDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/location-hashtag-sets', {
                templateUrl: '/partials/locations/location-hashtag-sets',
                resolve: routeRoleChecks.user,
            })
            .when('/profile', {
                templateUrl: '/partials/account/profile/template',
                controller: ProfileController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/agency/clients', {
                templateUrl: '/partials/clients/client-list',
                controller: MvClientListCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id', {
                templateUrl: '/partials/clients/client-detail',
                controller: 'mvClientDetailCtrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/locations/:locationId/users/:userId/edit', {
                templateUrl: '/partials/clients/client-user-edit',
                controller: 'mvClientUserEditCtrl',
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/locations/:locationId/users/:userId/locations', {
                templateUrl: '/partials/clients/client-user-locations',
                controller: MvClientUserLocationsCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/activities', {
                templateUrl: '/partials/clients/activities/template',
                controller: ClientActivitiesController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/builderTemplateCategories', {
                templateUrl: '/partials/clients/builderTemplateCategories/builderTemplateCategories',
                controller: BuilderTemplateCategoriesController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/fonts', {
                templateUrl: '/partials/clients/client-fonts',
                controller: 'mvClientFontsCtrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/learnMenu', {
                templateUrl: '/partials/clients/client-learn-menu',
                controller: ClientLearnMenuCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/locationCategories', {
                templateUrl: '/partials/clients/client-location-categories',
                controller: 'mvClientLocationCategoriesCtrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/locations/:id', {
                templateUrl: '/partials/locations/location-detail',
                controller: MvLocationDetailCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/locations/:id/users', {
                templateUrl: '/partials/locations/location-users',
                controller: 'mvLocationUsersCtrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/locations/:id/categories', {
                templateUrl: '/partials/locations/location-categories',
                controller: MvLocationCategoriesCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            /* A.when('/clients/:id/platforms', {
                templateUrl: '/partials/clients/platforms/template',
                controller: ClientPlatformsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            }) */
            .when('/clients/:id/links', {
                templateUrl: '/partials/clients/externalLinks/template',
                controller: ClientExternalLinksController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:id/brand-colours', {
                templateUrl: '/partials/clients/brandColours/template',
                controller: ClientBrandColoursController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/users', {
                templateUrl: '/partials/clients/client-users',
                controller: MvClientUsersCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/users/:userId/edit', {
                templateUrl: '/partials/clients/client-user-edit',
                controller: MvClientUserEditCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/clients/:clientId/users/:userId/locations', {
                templateUrl: '/partials/clients/client-user-locations',
                controller: MvClientUserLocationsCtrl,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.manager,
            })
            .when('/notifications/:notificationId', {
                templateUrl: '/partials/inAppNotifications/details/template',
                controller: InAppNotificationsDetailsController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/notifications', {
                templateUrl: '/partials/inAppNotifications/list/template',
                controller: InAppNotificationsListController,
                controllerAs: 'ctrl',
                resolve: routeRoleChecks.user,
            })
            .when('/payments', {
                templateUrl: '/partials/payments/payments',
                controller: 'mvPaymentsCtrl',
            })
            .when('/payments/confirm', {
                templateUrl: '/partials/payments/paymentsConfirm',
                controller: 'mvPaymentsConfirmCtrl',
            })
            .when('/payments/finalise', {
                templateUrl: '/partials/payments/finalise',
                controller: 'mvPaymentsFinaliseCtrl',
            })
            .when('/planner', {
                templateUrl: '/partials/planner/planner',
                controller: 'mvPlannerCtrl',
                activeTab: 'plan',
                resolve: routeRoleChecks.user,
            })
            .when('/planner/:setMonth', {
                templateUrl: '/partials/planner/planner',
                controller: 'mvPlannerCtrl',
                activeTab: 'plan',
                resolve: routeRoleChecks.user,
            })
            .when('/planner/:clientId/:locationId/:date/add', {
                templateUrl: '/partials/planner/event',
                controller: MvPlannerEventCtrl,
                controllerAs: 'ctrl',
                activeTab: 'plan',
                resolve: routeRoleChecks.user,
            })
            .when('/planner/:clientId/:locationId/:date/recurring/:recurringPlannerId', {
                templateUrl: '/partials/planner/event',
                controller: MvPlannerEventCtrl,
                controllerAs: 'ctrl',
                activeTab: 'plan',
                resolve: routeRoleChecks.user,
            })
            .when('/planner/:clientId/:locationId/:date/:plannerId', {
                templateUrl: '/partials/planner/event',
                controller: MvPlannerEventCtrl,
                controllerAs: 'ctrl',
                activeTab: 'plan',
                resolve: routeRoleChecks.user,
            })
            .when('/privacy', {
                templateUrl: '/partials/main/privacy',
            })
            // .when('/reports', {
            //     TemplateUrl: '/partials/reports/reportPage/template',
            //     Controller: ReportPageController,
            //     ControllerAs: 'ctrl',
            //     ActiveTab: 'reports',
            //     Resolve: routeRoleChecks.reportsAccess,
            // })
            .when('/reports/summaries/:reportSummaryId/edit', {
                templateUrl: '/partials/reports/summary/reportSummaryEdit',
                controller: MvReportSummaryEditCtrl,
                controllerAs: 'ctrl',
                activeTab: 'reports',
                resolve: routeRoleChecks.manager,
            })
            .when('/reports/summaries/add', {
                templateUrl: '/partials/reports/summary/reportSummaryEdit',
                controller: MvReportSummaryEditCtrl,
                controllerAs: 'ctrl',
                activeTab: 'reports',
                resolve: routeRoleChecks.manager,
            })
            .when('/reports/old', {
                templateUrl: '/partials/reports/old/reports',
                controller: 'mvReportsCtrl',
                activeTab: 'reports',
                resolve: routeRoleChecks.user,
            })
            .when('/specialRequests', {
                templateUrl: '/partials/specialRequests/specialRequests',
                controller: MvSpecialRequestsCtrl,
                controllerAs: 'ctrl',
                activeTab: 'specialRequests',
                resolve: routeRoleChecks.specialRequestAccess,
            })
            .when('/specialRequests/:specialRequestId', {
                templateUrl: '/partials/specialRequests/specialRequestDetails',
                controller: MvSpecialRequestDetailsCtrl,
                controllerAs: 'ctrl',
                activeTab: 'specialRequests',
                resolve: routeRoleChecks.specialRequestAccess,
            })
            .when('/overview', {
                templateUrl: '/partials/overview/overview',
                controller: MvOverviewCtrl,
                controllerAs: 'ctrl',
                activeTab: 'overview',
                resolve: routeRoleChecks.user,
            })
            .when('/terms', {
                templateUrl: '/partials/main/terms',
            })
            .when('/trythis', {
                templateUrl: '/partials/trythis/trythis',
                controller: MvTryThisCtrl,
                controllerAs: 'ctrl',
                activeTab: 'trythis',
                resolve: routeRoleChecks.user,
            })
            .when('/trythis/add', {
                templateUrl: '/partials/trythis/trythis-edit',
                controller: LearnArticleEditController,
                controllerAs: 'ctrl',
                activeTab: 'trythis',
                resolve: routeRoleChecks.user,
            })
            .when('/trythis/edit/:trythisId', {
                templateUrl: '/partials/trythis/trythis-edit',
                controller: LearnArticleEditController,
                controllerAs: 'ctrl',
                activeTab: 'trythis',
                resolve: routeRoleChecks.manager,
            })
            .when('/trythis/:tryThisId', {
                templateUrl: '/partials/trythis/trythis-details',
                controller: MvTryThisDetailsCtrl,
                controllerAs: 'ctrl',
                activeTab: 'trythis',
                resolve: routeRoleChecks.user,
            })
            .when('/trythis/:tryThisId/:slug', {
                templateUrl: '/partials/trythis/trythis-details',
                controller: MvTryThisDetailsCtrl,
                controllerAs: 'ctrl',
                activeTab: 'trythis',
                resolve: routeRoleChecks.user,
            })
            .when('/builderTemplateGallery', {
                templateUrl: '/partials/templateGallery/builderTemplateGallery',
                controller: MvBuilderTemplateGalleryCtrl,
                controllerAs: 'ctrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
                reloadOnSearch: false,
            })
            .when('/contentBuilder', {
                templateUrl: '/partials/contentBuilder/contentBuilder',
                controller: 'mvContentBuilderCtrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
            })
            .when('/emailBuilder', {
                templateUrl: '/partials/contentBuilder/email/emailBuilder',
                controller: 'mvEmailBuilderCtrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
            })
            .when('/memberEngager', {
                templateUrl: '/partials/contentBuilder/memberEngager/memberEngager',
                controller: MemberEngagerCtrl,
                controllerAs: 'ctrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
            })
            .when('/memberEngager/emailBuilder', {
                // Duplicate route for e-mail builder, for breadcrumb support
                templateUrl: '/partials/contentBuilder/email/emailBuilder',
                controller: 'mvEmailBuilderCtrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
            })
            .when('/memberEngager/automatedEmail/:id', {
                templateUrl: '/partials/contentBuilder/memberEngager/automatedEmail/memberEngagerAutomatedEmail',
                controller: MemberEngagerAutomatedEmailCtrl,
                controllerAs: 'ctrl',
                activeTab: 'builder',
                resolve: routeRoleChecks.builderAccess,
            })
            .when('/p/:postKey', {
                templateUrl: '/partials/scheduledPosts/view',
                controller: ScheduledPostController,
                controllerAs: 'ctrl',
            })
            .when('/help/no-assigned-locations', {
                templateUrl: '/partials/help/noAssignedLocations/template',
                resolve: routeRoleChecks.user,
            })
            .when('/help/builder-keyboard-shortcuts', {
                templateUrl: '/partials/help/builderKeyboardShortcuts/template',
            })
            .when('/:rest*', {
                reloadOnUrl: false,
                reloadOnSearch: false,
                template: '<ds-react-app></ds-react-app>',
            } as any);
    },
]);
