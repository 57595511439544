/// <reference path="../../../typings/browser.d.ts" />
import { Location, Untyped } from '@deltasierra/shared';

export const mvLocationResourceSID = 'mvLocationResource';

export interface LocationResource extends ng.resource.IResource<LocationResource>, Location {}

export interface MvLocationResource extends ng.resource.IResourceClass<LocationResource> {
    update(location: Untyped): Location;
}

const factory = function ($resource: ng.resource.IResourceService): MvLocationResource {
    const resource = $resource<LocationResource>(
        '/api/clients/:clientId/locations/:locationId',
        {
            clientId: '@clientId',
            locationId: '@locationId',
        },
        {
            update: {
                method: 'PUT',
                isArray: false,
            },
        },
    ) as MvLocationResource;

    return resource;
};

angular.module('app').factory(mvLocationResourceSID, ['$resource', factory]);
