import { gql, useQuery } from '@apollo/client';
import {
    DSLoadMoreButton,
    DSWell,
    DSWellHeader,
    DSWellHeaderRightContent,
    DSWellHeaderTitle,
} from '@deltasierra/components';
import { useOnHitPageBottom } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { withAngularIntegration } from '../../../common/componentUtils/reactComponentRegistration';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import { relayConnectionToArray } from '../../../graphql/utils';
import { BrandButton } from './BrandButton';
import { BrandTable } from './BrandTable';
import { GetBrandsForAdminWell, GetBrandsForAdminWellVariables } from './__graphqlTypes/GetBrandsForAdminWell';

const StyledCenter = styled.div`
    text-align: center;
`;

export const GET_BRANDS_FOR_ADMIN_WELL = gql`
    query GetBrandsForAdminWell($after: String, $first: Int) {
        admin {
            id
            brands(after: $after, first: $first) {
                edges {
                    node {
                        id
                        ...BrandTableFragment
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${BrandTable.fragments.BrandTableFragment}
`;

export const BrandAdminWell: React.FC = () => {
    const { data, fetchMore, loading } = useQuery<GetBrandsForAdminWell, GetBrandsForAdminWellVariables>(
        GET_BRANDS_FOR_ADMIN_WELL,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                first: 100,
            },
        },
    );
    const brands = relayConnectionToArray(data?.admin.brands);
    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(data?.admin?.brands, async after =>
        fetchMore({ variables: { after } }),
    );

    useOnHitPageBottom(handleFetchMore, loading);

    return (
        <DSWell>
            <DSWellHeader>
                <DSWellHeaderTitle>{t('ADMIN.BRANDS.BRANDS')}</DSWellHeaderTitle>
                <DSWellHeaderRightContent>
                    <BrandButton />
                </DSWellHeaderRightContent>
            </DSWellHeader>
            <BrandTable brands={brands} loading={loading} />
            {hasNextPage && (
                <StyledCenter>
                    <DSLoadMoreButton gutterTop loading={loading} onClick={handleFetchMore} />
                </StyledCenter>
            )}
        </DSWell>
    );
};
BrandAdminWell.displayName = 'BrandAdminWell';

export default withAngularIntegration(BrandAdminWell, 'brandAdminWell', {});
