import { gql } from '@apollo/client';
import { BuilderTemplateId } from '@deltasierra/ids';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { convertIdToUniversalNodeId } from '../../../graphql/utils';
import {
    CreateScheduledPublishGroupMutation,
    CreateScheduledPublishGroupMutationVariables,
} from './__graphqlTypes/CreateScheduledPublishGroupMutation';

const CREATE_SCHEDULED_PUBLISH_GROUP_MUTATION = gql`
    mutation CreateScheduledPublishGroupMutation($input: CreateScheduledPublishGroupInput!) {
        createScheduledPublishGroup(input: $input) {
            result {
                id
            }
        }
    }
`;

/**
 * @deprecated This is added to support the legacy build publish flow
 * In the newer flows we create this group on the backend.
 *
 * @param gqlService - The graphql service
 * @param builderTemplateId - The builder template id this is associated with
 * @returns The id of the scheduled publish group
 */
export async function createScheduledPublishGroup(
    gqlService: GraphqlService,
    builderTemplateId: BuilderTemplateId,
): Promise<string> {
    const client = gqlService.getClient();
    const result = await client.mutate<
        CreateScheduledPublishGroupMutation,
        CreateScheduledPublishGroupMutationVariables
    >({
        mutation: CREATE_SCHEDULED_PUBLISH_GROUP_MUTATION,
        variables: {
            input: {
                builderTemplateId: convertIdToUniversalNodeId('BuilderTemplate', builderTemplateId),
            },
        },
    });
    if (!result.data) {
        throw new Error('Failed to create scheduled publish group');
    }
    return result.data.createScheduledPublishGroup.result.id;
}
