/* eslint-disable max-lines,max-lines-per-function */
import {
    AnyResourceType,
    AssetLibraryResourceType,
    LocalResourceType,
    PlannerResourceType,
} from '@deltasierra/components';
import {
    ViewFileDto,
    getColorScheme,
    parseColor,
    colorSchemeHelpers,
    BuilderDocument,
    ChannelName,
    deDuplicateEditableFields,
    defaultBleed,
    isEmailDocument,
    isMultiImageContentDocument,
    isPrintDocument,
    isVideoDocument,
    TextSubstitutionField,
    BuilderDocumentFormat,
    ImageLayer,
    ImageSubstitutionType,
    LocationType,
    isImageLayer,
    isTextLayer,
    isVideoLayer,
    Layer,
    MapLayer,
    TextCase,
    VideoLayer,
    BuilderMultiImageOption,
    BuilderPrintDocument,
    BuilderPrintDocumentPage,
    BuilderPrintDocumentPageOrientation,
    initialisePages,
    Section,
    AutomaticTextPlaceholders,
    BuilderFontCustomDto,
    BuilderTemplate,
    BuilderTemplateId,
    BuilderType,
    ContentBuilderTemplate,
    DocumentTemplateToSave,
    linkToTemplate,
    BuilderTemplateCategoryId,
    BuilderTemplateFormat,
    Client,
    ClientId,
    ClientBrandColour,
    assertNever,
    ErrorCode,
    BROWSER_PIXELS_PER_INCH,
    getChannelDataIcon,
    AssignedLocation,
    GalleryPlannerDetails,
    PlatformId,
    Tag,
    Upload,
    MIME_TYPE_MAP,
    SUPPORTED_VIDEO_EXTENSIONS,
    SUPPORTED_VIDEO_MIME_TYPES,
} from '@deltasierra/shared';
import { FeatureFlag } from '@deltasierra/features/feature-flags/core';

import { isNotNullOrUndefined, isNullOrUndefined, isRecordType } from '@deltasierra/type-utilities';

import { clone, noop } from '@deltasierra/object-utilities';

import { dataUrlToBlob } from '@deltasierra/web-image-utilities';
import * as linq from 'linq';
import type { IPromise, IQResolveReject, IScope, IAngularEvent } from 'angular';
import { MvIdentity } from '../account/mvIdentity';
import { MvClient } from '../clients/mvClient';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import {
    $filterSID,
    $intervalSID,
    $locationSID,
    $qSID,
    $routeSID,
    IRoute,
    $scopeSID,
    $timeoutSID,
    EVENT_DESTROY,
    IKookies,
} from '../common/angularData';
import { $modalInstanceSID, $modalSID } from '../common/angularUIBootstrapData';
import { ConfirmModal, confirmModalSID } from '../common/confirmModal';
import { DataUtils } from '../common/dataUtils';
import { FileUtils } from '../common/fileUtils';
import { ImageCropperService } from '../common/imageCropper/service';
import { ImageMetadataService } from '../common/imageMetadataService';
import { InteractionUtils } from '../common/interactionUtils';
import { MvNotifier } from '../common/mvNotifier';
import { PopoverService } from '../common/popover';
import { SentryService } from '../common/sentryService';
import { TemplateLoaderService } from '../common/templateLoaderService';
import { UploadMap, UploadService } from '../common/uploadService';
import { GraphqlService } from '../graphql/GraphqlService';
import { convertIdToUniversalNodeId } from '../graphql/utils';
import { I18nService } from '../i18n';
import { LocalPublishService } from '../integration/publish/localPublishService';
import { IntroDataService } from '../intro/introDataService';
import { IntroWrapper } from '../intro/introWrapper';
import { MvLocation } from '../locations/mvLocation';
import { MvPlanner } from '../planner/mvPlanner';
import { PlannerUIService } from '../planner/plannerUIService';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { IntroService } from './../intro/introService';
import { BuilderConstants, builderConstantsSID } from './builderConstants';
import {
    FieldValidationResult,
    FieldValidationResultSeverity,
    getFieldValidationClassName,
} from './builderDocumentValidation';
import { BuilderTemplateApiClient } from './builderTemplateApiClient';
import ChannelDataService, { ChannelDataConfig } from './channelDataService';
import {
    BuilderCommonService,
    CurrentTextSubstitutionField,
    ShowModalAndUploadResourcesResult,
    TextSubstitutionScopeMixins,
    UploadResourcesScope,
} from './common/builderCommonService';
import { ActionType, ContentBuilder, ContentBuilderFactory } from './contentBuilder';
import { EmailBuilder } from './email/emailBuilder';
import { FontService } from './fontService';
import { GET_BUILDER_CONFIG } from './GetBuilderConfig.query';
import { getFormatByName } from './imageFormats';
import { FileCache, ImageCache, ImageLoaderService } from './imageLoaderService';
import { mvBuilderTemplateFormatResourceSID, MvBuilderTemplateFormatResource } from './mvBuilderTemplateFormatResource';
import { DuplicateToPagePromptService } from './print/duplicateToPagePrompt';
import { ImageDpiPromptService } from './print/imageDpiPromptService';
import { VideoCache, VideoLoaderService } from './videoLoaderService';
import { GetBuilderConfig } from './__graphqlTypes/GetBuilderConfig';
import { GET_CUSTOM_MERGE_FIELDS } from './GetCustomMergeFields.query';
import { GetCustomMergeFields } from './__graphqlTypes/GetCustomMergeFields';
import { GET_MERGE_FIELDS } from './GetMergeFields.query';
import { GetMergeFields, GetMergeFields_location_buildableTemplateMergeFields } from './__graphqlTypes/GetMergeFields';

export type GetForUploadType = () => Promise<{
    contentBuilder: ContentBuilder | EmailBuilder;
    uploadMap: UploadMap;
    compositeImageUpload: Upload;
}>;

interface BuilderPlannerDetails extends GalleryPlannerDetails {
    visible?: boolean;
}

type BuilderView = 'BUILDER' | 'PUBLISH';
type BuilderSubView = 'ERROR_NOT_FOUND' | 'EXISTING_DOCUMENT' | 'NEW_DOCUMENT' | 'SPINNER';

const CONTROL_SELECTORS = {
    ANIMATION: { NEXT: '#btnNextFrame' },
    IMAGE: { NEXT: '#btnNextImage' },
    PAGING: { NEXT: '#btnNextPage' },
};

const MAX_RETRY_ATTEMPTS = 1;
const VERIFY_USED_FONTS_DELAY = 500;

interface LayerSortOptions {
    helper?: (event?: any, ui?: any) => any;
    start?: (event?: any, ui?: any) => any;
    stop?: (event?: any, ui?: any) => any;
    items?: string;
}

class NotFoundError extends Error {
    public data = { code: ErrorCode.NotFoundError };
}

export interface ContentBuilderCtrlScope extends IScope, TextSubstitutionScopeMixins {
    advancedMode: () => boolean;
    toggleEditableField: (
        layer: BuilderDocument | Layer,
        fieldPath: string,
        controlType: string,
        label: string,
    ) => void;
    hasEditableField: (layer: BuilderDocument | Layer, path: string, control: string) => boolean;
    showImageChooser: (layer?: ImageLayer) => void;
    showVideoChooser: (layer?: VideoLayer) => void;
    contentBuilder: ContentBuilder;
    canvasId: string;
    identity: MvIdentity;
    clients: Client[] | null;
    plannerId: number | null;
    plannerDetails: BuilderPlannerDetails | null;
    collectionId: number | null;
    collectionGraphqlId: string | null;
    hasSuggestedCollection: boolean;
    builderTemplateFormats: BuilderTemplateFormat[] | null;
    introBuild: IntroWrapper | null;

    boundData: {
        isDraft: boolean;
        selectedClient: Client | null;
        showZoom: boolean;
        showZoomPromise: ng.IPromise<void> | null;
        hasSeenAllFrames: boolean;
        hasSeenAllImages: boolean;
        hasSeenAllPages: boolean;
        view: BuilderView;
        builderSubView: BuilderSubView;
    };

    fileUtils: FileUtils;
    mvNotifier: MvNotifier;

    selectedBuilderTemplateFormats: BuilderTemplateFormat[];
    selectedCategoryIds: BuilderTemplateCategoryId[];
    currentTextSubstitutionField: CurrentTextSubstitutionField | null;
    imageCache: ImageCache;
    fileCache: FileCache;
    videoCache: VideoCache;
    location: AssignedLocation | null;
    shouldShowDocumentProperties: boolean;
    shouldShowChannelDataProperties: boolean;
    shouldAllowSettingClipTextLayers: boolean;
    shouldAllowSettingTextRenderingLineHeightFix: boolean;
    isLoading: boolean;
    isVideoPreviewSupported: boolean;
    isSaving: boolean;
    resourceLoadingCount: number;
    templateId: BuilderTemplateId | null;
    templateGraphqlId: string | null;
    originalTemplate: ContentBuilderTemplate | null;
    tagContext: {
        value: string | null;
    };
    tags: Tag[];
    customMergeFields: Array<{ key: string, value: string }>;
    // eslint-disable-next-line camelcase
    mergeFields: ReadonlyArray<GetMergeFields_location_buildableTemplateMergeFields>;

    isDirty: boolean;
    dirtyChecks: number;
    dirtyModal: ModalInstance | null;

    fontCustomOptions: BuilderFontCustomDto[];
    fontOptions: string[];
    loading: {
        localImagePublish: boolean;
        setPlannerStatusToPlanned: boolean;
    };
    publishingDisabled: boolean;
    templatePromise: IPromise<void> | null;
    fontLoadingPromise: IPromise<void> | null;
    lastFontMissingDate: Date | null;
    totalResourcesLoading: number;

    isPrintTemplate: boolean;
    isVideoTemplate: boolean;
    templateFormatType: 'image' | 'print' | 'video';
    layersOnPage: Layer[];
    layersOnMultiImage: Layer[];
    imageMetadataService: ImageMetadataService;
    imageDpiPromptService: ImageDpiPromptService;
    imageSubsitutionNotifications: Array<() => void>;

    layerSortOptions: LayerSortOptions;

    availablePageOrientations: Array<{ value: BuilderPrintDocumentPageOrientation; label: string }>;
    brandColours?: Array<ClientBrandColour & { type: string }> | undefined;
    customSwatches:
    | Array<{
        title: string;
        swatches: ClientBrandColour[];
    }>
    | undefined;

    isTemplateValid(): boolean;

    validateField(layer: Layer, fieldName: string): FieldValidationResult[];

    getFieldValidationClassName(messages: FieldValidationResult[]): string;

    fieldChanged(layer: Layer, fieldPath: string): void;

    textSubstitutionChanged(field: TextSubstitutionField): void;

    newTextSubstitutionField(): void;

    onPlannerDetailsLoaded(plannerDetails: BuilderPlannerDetails): IPromise<void>;

    updateLocation(location: AssignedLocation): void;

    initNewDocument(): void;

    updateEditorFromDocument(): void;

    setMouseAction(action: string): void;

    getCurrentResourceLoadingCount(): number;

    updateDocumentFromEditor(): void;

    selectLayer(layer: Layer, $event?: JQueryEventObject): void;

    deleteLayer(layer: Layer): void;

    moveLayerUp(layer: Layer): void;

    moveLayerDown(layer: Layer): void;

    duplicateLayer(layer: Layer): void;

    toggleLayerVisibility(layer: Layer): void;

    onFileSelectForImage(file: File, optionalLayer?: ImageLayer): IPromise<ImageLayer>;

    onSelectLocalImage(file: File, optionalLayer?: ImageLayer): IPromise<void>;

    onSelectPlannerImage(plannerImage: Upload, optionalLayer?: ImageLayer): IPromise<void>;

    addRectangle(): void;

    addText(): void;

    addVideo(): void;

    addEllipse(): void;

    addMap(): void;

    canvasMouseDown(event: IAngularEvent & MouseEvent): void;

    canvasMouseUp(event: IAngularEvent & MouseEvent): void;

    canvasMouseMove(event: IAngularEvent & MouseEvent): void;

    canvasDblClick(event: IAngularEvent & MouseEvent): void;

    mouseOverLayer(layer: Layer): void;

    mouseLeaveLayer(layer: Layer): void;

    zoomIn(): void;

    zoomOut(): void;

    zoomToFit(): void;

    zoomActualSize(): void;

    saveAsExistingBuilderTemplate(deleteLocationDrafts: boolean): void;

    saveAsNewBuilderTemplate(): void;

    deleteBuilderTemplate(): void;

    isLocationDraftEnabled: boolean;
    isMultiImageTemplateEnabled: boolean;

    selectDocumentProperties(): void;

    selectChannelDataProperties(): void;

    togglePlannerDetails(): void;

    areResourcesLoading(): boolean;

    needsToSeeAllFramesToPublish(): boolean;

    needsToSeeAllImagesToPublish(): boolean;

    needsToSeeAllPagesToPublish(): boolean;

    toggleAnimation(): void;

    previousAnimationFrame(): void;

    nextAnimationFrame(): void;

    isFirstFrame(): boolean;

    isLastFrame(): boolean;

    addAnimationFrameBefore(): void;

    addAnimationFrameAfter(): void;

    deleteCurrentAnimationFrame(): void;

    startAnimation(): void;

    stopAnimation(): void;

    startVideo(): void;

    stopVideo(): void;

    toggleMute(): void;

    toggleLooping(): void;

    toggleLoopForever(): void;

    isLayerVisible(layer: Layer | Section): boolean;

    hasVideoLayer(): boolean;

    gotoPreviousPage(): void;

    gotoNextPage(): void;

    addPageBefore(): void;

    addPageAfter(): void;

    deletePage(): void;

    getPageOrientation(): BuilderPrintDocumentPageOrientation;

    setPageOrientation(orientation: BuilderPrintDocumentPageOrientation): void;

    toggleMultiImage(): void;

    isMultiImage(): boolean;

    gotoMultiImage(multiImageIndex: number): void;

    gotoPrintTemplatePage(pageId: number): void;

    addMultiImage(): void;

    deleteMultiImage(multiImageIndex: number): void;

    moveMultiImage(fromIndex: number, toIndex: number): void;

    movePrintTemplatePage(fromId: number, toId: number): void;

    getMultiImageIndex(): number;

    refreshMultiImageThumbnail(index: number): Promise<string | null>;

    refreshPrintTemplatePageThumbnail(index: number): Promise<string | null>;

    multiImageItems: ReadonlyArray<{ id: number; src: string; title: string }>;

    printTemplatePages: ReadonlyArray<{ id: number; src?: string; title: string }>;

    getPrintTemplatePageCount: () => number;

    getChannelDataConfig(): unknown;

    addChannelData(channelName: string): void;

    shouldDisplayChannelDataConfigOption<T>(channelDataConfig: ChannelDataConfig<T>): boolean;

    shouldDisplayChannelData<T>(channelDataConfig: ChannelDataConfig<T>): boolean;

    deleteChannelDatum(channelName: string): void;

    checkChannelDataExists(channelName: ChannelName): boolean;

    channelDataList: string[];

    isExportEnabled(): boolean;

    startExport(): void;

    cancelPublish(): void;

    constructPlannerUrl(): void;

    getCanvasHeight(): number;

    getCanvasWidth(): number;

    isLargeDisplay(): boolean;

    onSelectedCategoryUpdate(categories: BuilderTemplateCategoryId[]): void;

    validateTextFontSizeField(): void;

    validateLetterSpacingField(): void;

    resourcePickerMediaTypeFilter: 'image' | 'video';
    isAssetLibraryModalShown: boolean;
    isResourcePickerModalShown: boolean;
    canChooseLocationLogo: boolean;
    handleCloseResourcePicker: () => void;
    handleCloseAssetLibrary: () => void;

    isLocationDetailsModalShown: boolean;
    onLocationDetailsModalCancel: () => void;
    onLocationDetailsModalSubmit: () => void;
    onLocationDetailsModalValidationError: (error: Error) => void;

    resourcePickerOptionalLayer?: ImageLayer | VideoLayer;

    openAssetLibraryModal: () => void;
    openAssetLibraryModalWithSuggestedCollection: () => void;

    handleAssetChosen: (asset: ViewFileDto) => void;
    handleResourcePicked: (resource: AnyResourceType) => void;

    getChannelDataIcon: (channelName: ChannelName) => string;

    plannerResources: Upload[];
    assetResources: ViewFileDto[];
    assetLibraryMediaFilter: Array<'document' | 'folder' | 'image' | 'video'>;

    builderType: BuilderType;

    getForUpload: GetForUploadType;

    loadLocationDraft: (locationDraftGraphqlId: string) => Promise<void>;
    loadOriginalTemplate: () => Promise<void>;

    onChangeTextCase: () => void;
}

angular.module('app').controller('mvContentBuilderCtrl', [
    $scopeSID,
    $locationSID,
    $routeSID,
    $qSID,
    $filterSID,
    $timeoutSID,
    $intervalSID,
    '$kookies',
    $modalSID,
    MvIdentity.SID,
    UploadService.SID,
    MvNotifier.SID,
    mvClientResourceSID,
    MvLocation.SID,
    MvPlanner.SID,
    ContentBuilderFactory.SID,
    BuilderTemplateApiClient.SID,
    mvBuilderTemplateFormatResourceSID,
    ImageLoaderService.SID,
    VideoLoaderService.SID,
    ChannelDataService.SID,
    BuilderCommonService.SID,
    confirmModalSID,
    builderConstantsSID,
    LocalPublishService.SID,
    PlannerUIService.SID,
    InteractionUtils.SID,
    DataUtils.SID,
    FontService.SID,
    FileUtils.SID,
    ImageMetadataService.SID,
    ImageDpiPromptService.SID,
    DuplicateToPagePromptService.SID,
    PopoverService.SID,
    IntroService.SID,
    IntroDataService.SID,
    I18nService.SID,
    TemplateLoaderService.SID,
    ImageCropperService.SID,
    MvClient.SID,
    GraphqlService.SID,
    SentryService.SID,
    // eslint-disable-next-line max-statements, func-names, max-params
    function (
        $scope: ContentBuilderCtrlScope,
        $location: ng.ILocationService,
        $route: IRoute,
        $q: ng.IQService,
        $filter: ng.IFilterService,
        $timeout: ng.ITimeoutService,
        $interval: ng.IIntervalService,
        $kookies: IKookies,
        $modal: ModalService,
        mvIdentity: MvIdentity,
        uploadService: UploadService,
        mvNotifier: MvNotifier,
        mvClientResource: MvClientResource,
        mvLocation: MvLocation,
        mvPlanner: MvPlanner,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ContentBuilderFactory: ContentBuilderFactory,
        builderTemplateApiClient: BuilderTemplateApiClient,
        mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
        imageLoaderService: ImageLoaderService,
        videoLoaderService: VideoLoaderService,
        channelDataService: ChannelDataService,
        builderCommonService: BuilderCommonService,
        confirmModal: ConfirmModal,
        builderConstants: BuilderConstants,
        localPublishService: LocalPublishService,
        plannerUIService: PlannerUIService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        fontService: FontService,
        fileUtils: FileUtils,
        imageMetadataService: ImageMetadataService,
        imageDpiPromptService: ImageDpiPromptService,
        duplicateToPagePromptService: DuplicateToPagePromptService,
        popoverService: PopoverService,
        introService: IntroService,
        introDataService: IntroDataService,
        i18nService: I18nService,
        templateLoaderService: TemplateLoaderService,
        imageCropperService: ImageCropperService,
        mvClient: MvClient,
        graphqlService: GraphqlService,
        sentryService: SentryService,
    ) {
        const SHOW_ZOOM_TIME_MS = 2000;
        const MAX_IMAGE_DIMENSIONS = 2000;

        $scope.identity = mvIdentity;
        $scope.canvasId = 'contentBuilderCanvas';
        $scope.clients = null;
        $scope.plannerId = null;
        $scope.plannerDetails = null;
        $scope.builderTemplateFormats = null;
        $scope.boundData = {
            builderSubView: 'SPINNER',
            hasSeenAllFrames: false,
            hasSeenAllImages: false,
            hasSeenAllPages: false,
            isDraft: false,
            selectedClient: null,
            showZoom: true,
            showZoomPromise: null,
            view: 'BUILDER',
        };
        $scope.selectedBuilderTemplateFormats = [];
        $scope.selectedCategoryIds = [];
        $scope.currentTextSubstitutionField = null;
        $scope.imageCache = new ImageCache();
        $scope.fileCache = new FileCache();
        $scope.videoCache = new VideoCache();
        $scope.contentBuilder = ContentBuilderFactory.getInstance(
            $scope.canvasId,
            $scope.imageCache,
            $scope.fileCache,
            $scope.videoCache,
            () => $scope.selectDocumentProperties(),
        );
        $scope.location = null;
        $scope.shouldShowDocumentProperties = true;
        $scope.shouldAllowSettingClipTextLayers = false;
        $scope.shouldAllowSettingTextRenderingLineHeightFix = false;
        $scope.shouldShowChannelDataProperties = false;
        $scope.isLoading = true;
        $scope.isVideoPreviewSupported = true;
        $scope.isSaving = false;
        $scope.resourceLoadingCount = 0;
        $scope.templateId = null;
        $scope.originalTemplate = null;
        $scope.tagContext = { value: null };
        $scope.tags = [];

        $scope.isDirty = false;
        $scope.dirtyChecks = 0; // Horrible, horrible hack ... HACK THE PLANET!
        $scope.dirtyModal = null;

        $scope.fontCustomOptions = [];
        $scope.fontOptions = [];
        $scope.loading = {
            localImagePublish: false,
            setPlannerStatusToPlanned: false,
        };
        $scope.publishingDisabled = false;
        $scope.templatePromise = null;
        $scope.fontLoadingPromise = null;
        $scope.lastFontMissingDate = null;
        $scope.totalResourcesLoading = 0;
        $scope.imageSubsitutionNotifications = [];
        $scope.customSwatches = undefined;

        $scope.introBuild = null;

        $scope.isLocationDraftEnabled = false;
        $scope.isMultiImageTemplateEnabled = false;

        $scope.contentBuilder.linkedAssetLibraryAsset = [];

        $scope.multiImageItems = [];

        $scope.printTemplatePages = [];

        $scope.getPrintTemplatePageCount = () => {
            if (isPrintDocument($scope.contentBuilder.document)) {
                return $scope.contentBuilder.document.pages.length;
            }
            return 1;
        };

        $scope.validateField = (layer: Layer, fieldPath: string) =>
            $scope.contentBuilder.validateField(layer, fieldPath);

        $scope.getFieldValidationClassName = getFieldValidationClassName;

        $scope.fieldChanged = (layer: Layer, fieldPath: string) => {
            const description = layer ? `${layer.title} ` : '';
            $scope.contentBuilder.captureDocumentChange(`${description}(${fieldPath})`);
        };

        $scope.textSubstitutionChanged = (field: TextSubstitutionField) => {
            $scope.contentBuilder.captureDocumentChange(field ? `${field.displayName} placeholder` : 'Placeholder');
        };

        $scope.newTextSubstitutionField = () => {
            $scope.contentBuilder.captureDocumentChange('New text substitution');
        };

        $scope.isTemplateValid = () => {
            const validDocument = $scope.contentBuilder.validateDocument();
            return validDocument.filter(result => result.severity === FieldValidationResultSeverity.Error).length === 0;
        };

        $scope.getChannelDataIcon = getChannelDataIcon;

        if (!introService.isAnyIntroActive()) {
            $scope.$on('$locationChangeStart', (event, next) => {
                if ($scope.isDirty) {
                    event.preventDefault();
                    if (!$scope.dirtyModal) {
                        const url = $location.url();
                        $scope.dirtyModal = confirmModal.open(
                            i18nService.text.common.leavePagePrompt.title(),
                            i18nService.text.common.leavePagePrompt.confirm(),
                            () => {
                                $scope.isDirty = false;
                                $scope.dirtyModal = null;
                                $location.url(url);
                            },
                            () => {
                                $scope.dirtyModal = null;
                            },
                        );
                    }
                }
            });
        }
        $scope.$on(EVENT_DESTROY, () => $scope.contentBuilder.destroy());
        $scope.$on(builderConstants.EVENTS.PUBLISH_CANCEL, onBuilderPublishCancelEvent);
        $scope.$on(builderConstants.EVENTS.PUBLISH_FINISH, onBuilderPublishFinishEvent);
        $scope.$on(builderConstants.EVENTS.RENDER_AND_RESET_ZOOM, onBuilderRenderAndResetZoom);
        $scope.$on('$destroy', () => fontService.clearAllLoadedFonts());

        /*
         * This watcher and the one below are a nasty fix for a rendering issue with Chrome (2019-09-16)
         */
        $scope.$watch(
            () => $scope.boundData.view,
            (newValue, oldValue) => {
                if (
                    newValue !== oldValue &&
                    newValue === 'BUILDER' &&
                    $scope.boundData.builderSubView === 'EXISTING_DOCUMENT'
                ) {
                    requestAnimationFrame(renderFrame);
                }
            },
        );

        /*
         * This watcher and the one above are a nasty fix for a rendering issue with Chrome (2019-09-16)
         */
        $scope.$watch(
            () => $scope.boundData.builderSubView,
            (newValue, oldValue) => {
                if (newValue !== oldValue && newValue === 'EXISTING_DOCUMENT' && $scope.boundData.view === 'BUILDER') {
                    requestAnimationFrame(renderFrame);
                }
            },
        );

        $scope.fileUtils = fileUtils;
        $scope.mvNotifier = mvNotifier;

        builderCommonService.applyTextSubstitutionMixins($scope, 'contentBuilder');

        $scope.imageMetadataService = imageMetadataService;
        $scope.imageDpiPromptService = imageDpiPromptService;

        let isAnimating = true;
        let lastTimestamp = 0;
        const targetFps = 60;
        const msPerSecond = 1000;
        let msPerFrame = msPerSecond / targetFps;

        function renderFrame(timestamp: number) {
            const windowLocal = window as { show_fps?: number };
            if (windowLocal.show_fps) {
                msPerFrame = msPerSecond / windowLocal.show_fps;
            }
            const delta = timestamp - lastTimestamp;
            if (delta >= Math.floor(msPerFrame)) {
                lastTimestamp = timestamp;
                void $timeout(() => {
                    /*
                     * 2019-09-16: The crazy conditions before each render call are a nasty fix for a rendering issue
                     * with Chrome 77 and
                     * possibly later version.
                     */
                    if (
                        $scope.boundData.view === 'BUILDER' &&
                        $scope.boundData.builderSubView === 'EXISTING_DOCUMENT'
                    ) {
                        $scope.contentBuilder.render();
                    }
                    if (isAnimating) {
                        if (
                            $scope.boundData.view === 'BUILDER' &&
                            $scope.boundData.builderSubView === 'EXISTING_DOCUMENT'
                        ) {
                            requestAnimationFrame(renderFrame);
                        }
                    }
                });
            } else if (isAnimating) {
                if ($scope.boundData.view === 'BUILDER' && $scope.boundData.builderSubView === 'EXISTING_DOCUMENT') {
                    requestAnimationFrame(renderFrame);
                }
            }
        }

        if ($scope.boundData.view === 'BUILDER' && $scope.boundData.builderSubView === 'EXISTING_DOCUMENT') {
            requestAnimationFrame(renderFrame);
        }

        $scope.$on('$destroy', () => {
            $scope.imageCache.clear();
            $scope.fileCache.clear();
            $scope.videoCache.clear();
            isAnimating = false;
            // Hide the popover as we leave this page
            if ($scope.needsToSeeAllFramesToPublish()) {
                hideSeenAllFramesMessage();
            }

            if ($scope.needsToSeeAllPagesToPublish()) {
                hideSeenAllPagesMessage();
            }

            if ($scope.needsToSeeAllImagesToPublish()) {
                hideSeenAllImagesMessages();
            }
        });

        async function initLocation() {
            return builderCommonService.getLocation($scope).then(updateLocation, err => {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToGetLocation(), err);
            });
        }

        function initClients() {
            return builderCommonService.setClients($scope).then(
                (selectedClient: Client | null) => {
                    $scope.boundData.selectedClient = selectedClient; // Might be null
                },
                err => {
                    mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToGetClients(), err);
                },
            );
        }

        function loadAllBuilderTemplateFormats() {
            const resource = mvBuilderTemplateFormatResource.query(
                {
                    // Type: $scope.isPrintTemplate ? 'print' : 'image'
                },
                () => {
                    // Do nothing
                },
                (data: unknown) => {
                    mvNotifier.unexpectedErrorWithData(
                        i18nService.text.build.error.failedToRetrieveBuilderTemplateFormats(),
                        data,
                    );
                },
            );
            $scope.builderTemplateFormats = resource;
            return resource.$promise;
        }

        async function filterFormatsByEnabledPlatformsForClient() {
            if ($scope.builderTemplateFormats && $scope.boundData.selectedClient) {
                const clientPlatforms = await mvClient.getPlatforms($scope.boundData.selectedClient.id);
                const clientPlatformIds = clientPlatforms.map(clientPlatform => clientPlatform.id);

                if ($scope.builderTemplateFormats) {
                    $scope.builderTemplateFormats = $scope.builderTemplateFormats.filter(builderTemplateFormat => {
                        const format = builderTemplateFormat;
                        if (clientPlatformIds.indexOf(PlatformId.from(format.platformId)) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
            }
        }

        function initTemplate(templateId: BuilderTemplateId): ng.IPromise<ContentBuilderTemplate> {
            if (introService.isIntroActive('build')) {
                /*
                 * FIXME: Remove this cast
                 * TS versions prior to v3.9 didn't pickup this typing issue.
                 * Our TS upgrade exposed this issue. It's too
                 * complicated and time consuming to fix at the moment...
                 */
                return $q.resolve(introDataService.getExampleTemplate() as ContentBuilderTemplate);
            } else {
                return builderTemplateApiClient.getBuilderTemplate(templateId).then(template => {
                    if (isEmailDocument(template.document)) {
                        // DS-2734: If someone has tried to load an e-mail template in the content builder, let's
                        //  Redirect them to the proper page. (This also helps with our type safety.)
                        // We use window.location instead of $location.path, because the promise reject seems to break
                        //  The navigation.
                        window.location.assign(linkToTemplate(BuilderDocumentFormat.email, templateId));
                        return $q.reject();
                    } else {
                        if (isNullOrUndefined(template.id)) {
                            return $q.reject(new NotFoundError());
                        }
                        return template;
                    }
                });
            }
        }

        function initWatchers() {
            $scope.$watch(() => $scope.contentBuilder.document, onDocumentChange, true);
            $scope.$watch(() => $scope.isLoading, watcherImageSubstitutions);
            $scope.$watch(() => $scope.contentBuilder.uiContext.zoom, onZoom, true);
            $scope.$watch(() => $scope.contentBuilder.advancedMode, onAdvancedModeChange, true);
            $scope.$watch(() => $scope.resourceLoadingCount, onResourceLoadingCountChange);
            $scope.$watch(() => $scope.contentBuilder.resourceLoadingCount, onResourceLoadingCountChange);
            $scope.$watch(() => $scope.selectedBuilderTemplateFormats, syncContentBuilderFormats, true);
            $scope.$watch(() => $scope.contentBuilder.document.layers, updateLayersOnPageOrMultiImage, true);
            $scope.$watch(
                () => {
                    if (isPrintDocument($scope.contentBuilder.document)) {
                        return $scope.contentBuilder.document.pages;
                    }
                    return undefined;
                },
                updateLayersOnPageOrMultiImage,
                true,
            );
            $scope.$watch(
                () => $scope.contentBuilder.multiImageContext.imageIndex,
                updateLayersOnPageOrMultiImage,
                true,
            );
            $scope.$watch(() => $scope.contentBuilder.document.multiImage, updateLayersOnPageOrMultiImage, true);
            $scope.$watch(() => $scope.contentBuilder.pageContext.pageIndex, updateLayersOnPageOrMultiImage);
            $scope.$watch(() => $scope.boundData.hasSeenAllFrames, hideSeenAllFramesMessage);
            $scope.$watchGroup(
                [() => $scope.contentBuilder.multiImageContext.imageIndex, () => $scope.multiImageItems],
                ([currentIndex, multiImageItems]: [number, typeof $scope.multiImageItems]) =>
                    setHasViewedAllMultiImages(currentIndex, multiImageItems.length - 1),
            );
            $scope.$watch(() => $scope.boundData.hasSeenAllImages, hideSeenAllImagesMessages);
            $scope.$watch(() => $scope.boundData.hasSeenAllPages, hideSeenAllPagesMessage);
            $scope.$watch(() => $scope.contentBuilder.selectedLayerGroup.isAny(), onSelectedLayerChange);
            $scope.$watch(() => $scope.contentBuilder.document.multiImage, onMultiImageChange, true);
            $scope.$watch(
                () => {
                    if (isPrintDocument($scope.contentBuilder.document)) {
                        return $scope.contentBuilder.document.pages;
                    }
                    return undefined;
                },
                onPrintTemplatePageChange,
                true,
            );

            // This nifty little hack prevents adjusting the template size without the layers being updated
            $scope.$watch(
                () => $scope.contentBuilder.document.dimensions,
                $scope.contentBuilder.onDocumentDimensionsUpdated.bind($scope.contentBuilder),
                true,
            );
        }

        function initDirtyWatch() {
            $scope.$watch(() => $scope.contentBuilder.document, onDirtyChange, true);
            $scope.$watch(() => $scope.contentBuilder.textSubstitutionValues, onDirtyChange, true);
        }

        async function initPage() {
            const params = $location.search();
            const userAgent = navigator.userAgent;
            /*
             * DS-3403: Hotfix for Google Chrome preview video rotation issue.
             * Browsers other than Chrome do not support previewing vertical video on canvas reliably.
             * Uses isVideoPreviewSupported to determine when to show warning to user.
             */
            $scope.isVideoPreviewSupported = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edge') === -1;
            $scope.plannerId = params.planner;
            $scope.isPrintTemplate = Boolean(params.print);
            $scope.isVideoTemplate = Boolean(params.video);
            $scope.templateFormatType = getFormatTypeFilter();
            initSuggestedCollection(params.collection as number);
            return !$scope.plannerId ? loadABunchOfData() : Promise.resolve();
        }

        function initSuggestedCollection(collection: number | null) {
            if (collection) {
                $scope.collectionId = collection;
                $scope.hasSuggestedCollection = true;
            } else {
                $scope.collectionId = null;
                $scope.hasSuggestedCollection = false;
            }
        }

        function getFormatTypeFilter(): 'image' | 'print' | 'video' {
            if ($scope.isPrintTemplate) {
                return 'print';
            } else if ($scope.isVideoTemplate) {
                return 'video';
            } else {
                return 'image';
            }
        }

        async function watcherImageSubstitutions() {
            if ($scope.location) {
                await doImageSubstitutions($scope.location);
            }
        }

        function loadABunchOfData() {
            const params = $location.search();
            const promises = [
                initLocation().then(initClients),
                loadAllBuilderTemplateFormats(),
                loadLinkedAssetLibraryAssetsForBuilderTemplate(params.template),
                getBuilderEnvironmentConfig(),
            ];
            let finalPromise;
            if (params.template) {
                finalPromise = $q
                    .all(promises)
                    .then(() => initTemplate(params.template))
                    .then(async template => {
                        $scope.originalTemplate = clone(template);
                        $scope.isPrintTemplate = template.document.format === BuilderDocumentFormat.print;
                        $scope.isVideoTemplate = template.document.format === BuilderDocumentFormat.video;
                        $scope.templateFormatType = getFormatTypeFilter();

                        $scope.shouldAllowSettingClipTextLayers =
                            template.document.clipTextLayers === undefined || template.document.clipTextLayers === true;
                        $scope.shouldAllowSettingTextRenderingLineHeightFix =
                            template.document.useTextRenderingLineHeightFix === undefined ||
                            template.document.useTextRenderingLineHeightFix === false;

                        await filterFormatsByEnabledPlatformsForClient();
                        return template;
                    })
                    .then(async template => loadFromTemplate(template))
                    .catch(err => {
                        // Catch err here. Check if it is not undefined, and has a code === ErrorCode.NotFound.
                        // Do a sneaky number check to see if we are calling an invalid template id
                        // This needs to be solved before fetching when refactored
                        // Checking like this is an anti-pattern
                        const isNotFoundError = err && err.data && err.data.code === ErrorCode.NotFoundError;
                        const isInvalidTemplateId = isNaN(params.template) || Number(params.template) < 0;
                        if (isNotFoundError || isInvalidTemplateId) {
                            $scope.boundData.builderSubView = 'ERROR_NOT_FOUND';
                        } else {
                            mvNotifier.unexpectedErrorWithData(
                                i18nService.text.build.error.failedToRetrieveTemplate(),
                                err,
                            );
                            $scope.isLoading = false;
                        }
                    });
            } else {
                if ($scope.isVideoTemplate) {
                    $scope.contentBuilder.document.dimensions.width = 1920;
                    $scope.contentBuilder.document.dimensions.height = 1080;
                }
                finalPromise = $q.all(promises).then(async () => {
                    $scope.templateFormatType = getFormatTypeFilter();
                    await filterFormatsByEnabledPlatformsForClient();
                });
                $scope.boundData.builderSubView = 'NEW_DOCUMENT';
                $scope.isLoading = false;
            }
            initWatchers();

            $scope.templatePromise = finalPromise
                .then(initDirtyWatch)
                .then(() => templateLoaderService.preloadPartial('/partials/contentBuilder/publish/publish'))
                .then(noop);

            return $scope.templatePromise.then(async () => {
                await verifyUsedFontsAreAvailableAfterPromises();
            });
        }

        $scope.onPlannerDetailsLoaded = (plannerDetails: BuilderPlannerDetails) => {
            $scope.plannerDetails = plannerDetails;
            return loadABunchOfData();
        };

        async function loadLinkedAssetLibraryAssetsForBuilderTemplate(
            builderTemplateId: BuilderTemplateId,
        ): Promise<void> {
            if (introService.isIntroActive('build')) {
                $scope.contentBuilder.linkedAssetLibraryAsset = [];
            } else if (builderTemplateId) {
                $scope.contentBuilder.linkedAssetLibraryAsset =
                    await builderTemplateApiClient.getAssetLibraryAssetsUsedInTemplate(builderTemplateId);
            }
        }

        async function loadLinkedAssetLibraryAssetsForLocationDraft(locationDraftGraphqlId: string): Promise<void> {
            if (introService.isIntroActive('build')) {
                $scope.contentBuilder.linkedAssetLibraryAsset = [];
            } else {
                $scope.contentBuilder.linkedAssetLibraryAsset =
                    await builderTemplateApiClient.getAllAssetLibraryAssetsUsedInLocationDraft(locationDraftGraphqlId);
            }
        }

        async function getBuilderEnvironmentConfig(): Promise<void> {
            const gqlClient = graphqlService.getClient();
            const configResult = await gqlClient.query<GetBuilderConfig>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_BUILDER_CONFIG,
            });
            if (configResult.errors) {
                throw new Error('Failed to fetch builder config');
            }

            if (configResult.data) {
                $scope.isLocationDraftEnabled = configResult.data.config.features.builder.builderTemplateDrafts;
                $scope.isMultiImageTemplateEnabled = configResult.data.config.features.builder.multiImageTemplates;
            }
        }

        async function updateMergeFields(): Promise<void> {
            $scope.mergeFields = [];

            if (!$scope.location || !$scope.templateFormatType) {
                return;
            }
            const clientFeatures = new Set($scope.location.clientFeatures);

            if (!clientFeatures.has(FeatureFlag.SOCIAL_MERGE_FIELDS)) {
                return;
            }

            const gqlClient = graphqlService.getClient();
            const { data } = await gqlClient.query<GetMergeFields>({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                query: GET_MERGE_FIELDS,
                variables: { id: $scope.location.graphqlId, input: {} },
            });

            $scope.mergeFields = [...data?.location?.buildableTemplateMergeFields ?? []];
            $scope.mergeFields.forEach(
                mergeField => {
                    if (
                        isNullOrUndefined(mergeField.values) ||
                        mergeField.values.__typename !==
                            'LocationBuildableTemplateMergeFieldAllPlatformAndTemplateTypeValue' ||
                        isNullOrUndefined(mergeField.values.value)
                    ) {
                        $scope.contentBuilder.textSubstitutionValues[mergeField.field] = '';
                    } else {
                        $scope.contentBuilder.textSubstitutionValues[mergeField.field] = mergeField.values.value;
                    }
                },
            );
        }

        async function updateCustomMergeFields(): Promise<void> {
            $scope.customMergeFields = [];

            if(!$scope.location || !$scope.templateFormatType) {
                return;
            }

            const clientFeatures = new Set($scope.location.clientFeatures);

            if(!clientFeatures.has(FeatureFlag.CUSTOM_MERGE_FIELDS)) {
                return;
            }

            const gqlClient = graphqlService.getClient();

            const { data } = await gqlClient.query<GetCustomMergeFields>({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                query: GET_CUSTOM_MERGE_FIELDS,
                variables: { locationId: $scope.location.graphqlId, templateType: $scope.templateFormatType },
            });

            $scope.customMergeFields = [...data?.location?.buildableTemplateCustomMergeFields ?? []];

            $scope.customMergeFields.forEach(
                customMergeField => {
                    $scope.contentBuilder.textSubstitutionValues[customMergeField.key] = customMergeField.value;
                },
            );
        }

        function getPlannerImages() {
            if (!$scope.plannerDetails || !$scope.plannerDetails.uploads) {
                return [];
            } else {
                return dataUtils.filterBy('isImage', $scope.plannerDetails.uploads, true);
            }
        }

        function getPlannerVideos() {
            if (!$scope.plannerDetails || !$scope.plannerDetails.uploads) {
                return [];
            } else {
                return $scope.plannerDetails.uploads.filter(
                    upload => upload.ext && SUPPORTED_VIDEO_EXTENSIONS.indexOf(upload.ext.toLowerCase()) > -1,
                );
            }
        }

        function onDirtyChange() {
            $scope.dirtyChecks++;
            if ($scope.dirtyChecks > 2) {
                $scope.isDirty = true;
                // TODO: unwatch
            }
        }

        function selectClient(clientId: ClientId) {
            // Might be null
            $scope.boundData.selectedClient = builderCommonService.findClientById(clientId, $scope.clients);
        }

        async function updateLocation(location: AssignedLocation) {
            let oldClientId = null;
            if ($scope.location) {
                oldClientId = $scope.location.clientId;
            }

            $scope.location = location;
            $scope.contentBuilder.textSubstitutionValues.location = dataUtils.useFallbackWhenUndefinedOrNull(
                location.displayName,
                location.title,
            );
            $scope.contentBuilder.textSubstitutionValues.phone = location.phoneNumber;
            $scope.contentBuilder.textSubstitutionValues.email = location.locationEmail;
            $scope.contentBuilder.textSubstitutionValues.website = location.websiteUrl;
            const address = [location.addressLineOne, location.addressLineTwo, location.addressLineThree].filter(
                xs => xs,
            );
            $scope.contentBuilder.textSubstitutionValues.address = address.join('\n');
            $scope.contentBuilder.textSubstitutionValues['address-singleline'] = address.join(', ');

            await updateMergeFields();

            await updateCustomMergeFields();

            const promises = [];
            promises.push(doImageSubstitutions(location).then(() => checkLocationTextSubstitutionRequirements()));

            if (oldClientId !== location.clientId) {
                promises.push(loadColours());
            }
            // Load fonts before loading images to better fit in the 40s timeout
            return $q.all(promises).then(loadFonts);
        }

        function isLocationAddressSet(location: AssignedLocation) {
            return location.addressLineOne || location.addressLineTwo || location.addressLineThree;
        }

        function checkLocationTextSubstitutionRequirements() {
            let success = true;

            if (
                (!$scope.location || $scope.location.phoneNumber === null) &&
                $scope.contentBuilder.hasTextSubstitutionContent(AutomaticTextPlaceholders.Phone)
            ) {
                success = false;
            }

            if (
                (!$scope.location || $scope.location.locationEmail === null) &&
                $scope.contentBuilder.hasTextSubstitutionContent(AutomaticTextPlaceholders.Email)
            ) {
                success = false;
            }

            if (
                (!$scope.location || $scope.location.websiteUrl === null) &&
                $scope.contentBuilder.hasTextSubstitutionContent(AutomaticTextPlaceholders.Website)
            ) {
                success = false;
            }

            if (
                (!$scope.location || !isLocationAddressSet($scope.location)) &&
                $scope.contentBuilder.hasTextSubstitutionContent(
                    AutomaticTextPlaceholders.Address,
                    AutomaticTextPlaceholders.AddressSingleLine,
                )
            ) {
                success = false;
            }

            return success;
        }

        function displayImageSubsitutionMessages() {
            $scope.imageSubsitutionNotifications.forEach(fn => fn());
        }

        function doImageSubstitutions(location: AssignedLocation) {
            return $scope.contentBuilder
                .updateImageSubstitions(location)
                .then(details => {
                    if (!details.failedSubstitutions) {
                        return;
                    }

                    $scope.imageSubsitutionNotifications = details.failedSubstitutions
                        .map(type => {
                            switch (type) {
                                case ImageSubstitutionType.LocationLogo:
                                    return () =>
                                        $scope.mvNotifier.notify(
                                            i18nService.text.build.locationLogoNotSet({ location: location.title }),
                                            'warning',
                                        );
                                case ImageSubstitutionType.LocationMap:
                                    return () =>
                                        $scope.mvNotifier.notify(
                                            i18nService.text.build.locationMapNotCreated({ location: location.title }),
                                            'warning',
                                        );
                                default:
                                    return undefined;
                            }
                        })
                        .filter((val): val is () => void => val !== undefined);
                })
                .then(displayImageSubsitutionMessages);
        }

        function loadFonts() {
            if ($scope.location) {
                $scope.fontLoadingPromise = $q((resolve: IQResolveReject<void>, reject: IQResolveReject<void>) => {
                    void fontService.getFontsForClient($scope.location!.clientId, {
                        decrement: () => $scope.resourceLoadingCount--,
                        failure: (failedFonts: BuilderFontCustomDto[]) => onFontLoadingFailure(failedFonts, reject),
                        finished: (values: BuilderFontCustomDto[]) => onAllFontsLoaded(values, resolve),
                        increment: () => $scope.resourceLoadingCount++,
                        resetCount: () => {
                            $scope.resourceLoadingCount = 0;
                            return $scope.resourceLoadingCount;
                        },
                    });
                });
                return $scope.fontLoadingPromise;
            } else {
                return $q.resolve();
            }
        }

        function loadColours() {
            if ($scope.location) {
                return mvClient.getClientBrandColours($scope.location.clientId).then(colours => {
                    colours.sort((colorA, colorB) => colorA.order - colorB.order);
                    $scope.brandColours = colours
                        .map(colour => ({
                            ...colour,
                            type: getColorScheme(parseColor(colour.colour))?.toString().toUpperCase(),
                        }))
                        .filter(
                            (colour): colour is ClientBrandColour & { type: string } => typeof colour.type === 'string',
                        )
                        .filter(colour => colour.type === 'RGB' || colour.type === 'CMYK');
                    $scope.customSwatches = [
                        { swatches: colours, title: i18nService.text.agency.client.brandColors() },
                    ];
                });
            } else {
                return $q.resolve();
            }
        }

        $scope.updateLocation = updateLocation;

        async function loadFromTemplate(template: BuilderTemplate) {
            $scope.boundData.builderSubView = 'EXISTING_DOCUMENT';
            $scope.contentBuilder.advancedMode = false;
            $scope.boundData.isDraft = template.isDraft;
            $scope.templateId = template.id;
            $scope.templateGraphqlId = template.graphqlId;
            $scope.tags = template.tags || [];

            $scope.selectedBuilderTemplateFormats = linq
                .from($scope.builderTemplateFormats || [])
                .where(format => linq.from(template.formats || []).any(selected => selected.id === format.id))
                .toArray();
            syncContentBuilderFormats();

            // Deselect layers when loading new original template or location draft (referred to user as a version).
            $scope.contentBuilder.deselectAllLayers();
            $scope.shouldShowDocumentProperties = false;
            $scope.shouldShowChannelDataProperties = false;

            const templateWithDedupedFields: BuilderTemplate = {
                ...template,
                document: {
                    ...template.document,
                    editableFields: deDuplicateEditableFields(template.document.editableFields),
                },
            };

            const promise = $scope.contentBuilder
                .loadDocument(templateWithDedupedFields.document as BuilderDocument, $scope.fontCustomOptions)
                .then(() => {
                    selectClient(template.clientId);

                    $scope.selectedCategoryIds = template.categories
                        ? template.categories.map(category => category.id)
                        : [];

                    $scope.contentBuilder.cleanUpLayerMapping();
                    $scope.isLoading = false;
                    checkLocationTextSubstitutionRequirements();
                })
                .then(() => ($scope.location ? doImageSubstitutions($scope.location) : Promise.resolve()))
                .finally(() => {
                    if (introService.isIntroActive('build')) {
                        triggerIntro();
                    }
                });

            $scope.zoomToFit();
            return promise;
        }

        function triggerIntro() {
            $scope.introBuild = introService.setUpAndStartIntro(
                'build',
                $scope,
                ['.tools-container.tool-panel'],
                () => {
                    if ($scope.introBuild) {
                        if (
                            $scope.introBuild.getDirection() === 'forward' &&
                            $scope.introBuild.getCurrentStep() === 2
                        ) {
                            setView('PUBLISH');
                        } else if (
                            $scope.introBuild.getDirection() === 'backward' &&
                            $scope.introBuild.getCurrentStep() === 1
                        ) {
                            setView('BUILDER');
                            /*
                             * HACK: The digest loop hasn't finished by the time Intro.js wants to highlight something,
                             * So manually re-trigger its layout after a delay.
                             */
                            return $timeout(() => {
                                if ($scope.introBuild) {
                                    $scope.introBuild.refresh();
                                }
                            }, 100);
                        }
                    }
                    return Promise.resolve();
                },
            );
        }

        function getLargestPrintFormat(formats: BuilderTemplateFormat[]) {
            const result = formats.reduce((largest, format) => {
                if (!largest || largest.width * largest.height < format.width * format.height) {
                    return format;
                } else {
                    return largest;
                }
            }, null as BuilderTemplateFormat | null);

            return result;
        }

        $scope.initNewDocument = () => {
            if ($scope.isPrintTemplate) {
                if (!$scope.selectedBuilderTemplateFormats.length) {
                    mvNotifier.expectedError(i18nService.text.build.print.selectAtLeastOneFormat());
                    return;
                }

                const printDocument = $scope.contentBuilder.document as BuilderPrintDocument;
                printDocument.format = BuilderDocumentFormat.print;
                printDocument.dimensions.unit = 'px';

                const largestFormat = getLargestPrintFormat($scope.selectedBuilderTemplateFormats);

                if (largestFormat) {
                    printDocument.dimensions.width = largestFormat.width;
                    printDocument.dimensions.height = largestFormat.height;
                }

                printDocument.bleed.amount = defaultBleed.amount;
                printDocument.bleed.units = defaultBleed.units;
                // eslint-disable-next-line id-length, sort-keys
                printDocument.background = colorSchemeHelpers.cmyk.toString({ c: 0, m: 0, y: 0, k: 0 });
                initialisePages(printDocument);
            } else if ($scope.isVideoTemplate) {
                $scope.contentBuilder.document.format = BuilderDocumentFormat.video;
                $scope.addVideo();
            }
            $scope.zoomToFit();
            $scope.boundData.builderSubView = 'EXISTING_DOCUMENT';
            $scope.contentBuilder.resetDocumentHistory();
        };

        function onAllFontsLoaded(fontOptions: BuilderFontCustomDto[], resolve: IQResolveReject<void>) {
            $scope.fontOptions = fontOptions.map(font => font.family).sort();
            $scope.fontCustomOptions = fontOptions;
            $scope.contentBuilder.customFonts = fontOptions;
            resolve();
            return verifyUsedFontsAreAvailableAfterPromises();
        }

        function verifyUsedFontsAreAvailableAfterPromises() {
            const promises = [];
            if ($scope.templatePromise) {
                promises.push($scope.templatePromise);
            }
            if ($scope.fontLoadingPromise) {
                promises.push($scope.fontLoadingPromise);
            }
            return $q.all(promises).then(verifyUsedFontsAreAvailable);
        }

        function verifyUsedFontsAreAvailable() {
            if (!introService.isAnyIntroActive()) {
                const usedFonts = $scope.contentBuilder.getUsedFonts();
                const missingFonts = dataUtils.relativeDifference($scope.fontOptions, usedFonts);
                const now = new Date();
                if (
                    missingFonts.length > 0 &&
                    ($scope.lastFontMissingDate === null ||
                        now.valueOf() - $scope.lastFontMissingDate.valueOf() >= VERIFY_USED_FONTS_DELAY)
                ) {
                    $scope.lastFontMissingDate = now;
                    const newScope = $scope.$new();
                    Object.defineProperty(newScope, 'missingFonts', {
                        configurable: true,
                        enumerable: true,
                        value: missingFonts,
                        writable: true,
                    });
                    $modal.open({
                        backdrop: 'static',
                        controller: [
                            '$scope',
                            '$modalInstance',
                            'mvIdentity',
                            (
                                $scope1: IScope & { isAgencyUser: boolean; close(): void },
                                $modalInstance: ModalInstance,
                                mvIdentity1: MvIdentity,
                            ) => {
                                $scope1.isAgencyUser = mvIdentity1.isManager();

                                $scope1.close = () => {
                                    $modalInstance.dismiss();
                                };
                            },
                        ],
                        scope: newScope,
                        templateUrl: '/partials/contentBuilder/missingFontError',
                    });
                    $scope.publishingDisabled = true;
                } else if (missingFonts.length === 0) {
                    $scope.publishingDisabled = false;
                }
            }
        }

        function onFontLoadingFailure(failedFonts: BuilderFontCustomDto[], reject: IQResolveReject<void>) {
            sentryService.captureException(`Failed to load fonts ${JSON.stringify(failedFonts)}`, {});
            $scope.publishingDisabled = true;
            const newScope = $scope.$new();
            Object.defineProperty(newScope, 'failedFonts', {
                configurable: true,
                enumerable: true,
                value: failedFonts.map(font => font.family),
                writable: true,
            });
            $modal.open({
                backdrop: 'static',
                controller: [
                    '$scope',
                    '$modalInstance',
                    ($scope1: IScope & { close(): void; reload(): void }, $modalInstance: ModalInstance) => {
                        $scope1.close = () => {
                            $modalInstance.dismiss();
                        };

                        $scope1.reload = () => {
                            window.location.reload();
                        };
                    },
                ],
                scope: newScope,
                templateUrl: '/partials/contentBuilder/fontLoadingError',
            });
            reject();
        }
        function onDocumentChange() {
            $scope.updateEditorFromDocument();
        }

        function onZoom() {
            $scope.boundData.showZoom = true;
            if ($scope.boundData.showZoomPromise) {
                $timeout.cancel($scope.boundData.showZoomPromise);
            }
            $scope.boundData.showZoomPromise = $timeout(() => {
                $scope.boundData.showZoom = false;
            }, SHOW_ZOOM_TIME_MS);
            $scope.contentBuilder.rectifyPanning();
        }

        function onAdvancedModeChange() {
            $scope.setMouseAction('pan');
            if ($scope.contentBuilder.advancedMode) {
                $scope.contentBuilder.enableKeyboardShortcuts();
            } else {
                $scope.contentBuilder.disableKeyboardShortcuts();
            }
        }

        function syncContentBuilderFormats() {
            $scope.contentBuilder.formats = $scope.selectedBuilderTemplateFormats;
        }

        async function onResourceFinishedLoading() {
            const promises = [];
            const imageChangePromise = onMultiImageChange($scope.contentBuilder.document.multiImage, null);
            promises.push(imageChangePromise);

            if (isPrintDocument($scope.contentBuilder.document)) {
                const pageChangePromise = onPrintTemplatePageChange($scope.contentBuilder.document.pages, undefined);
                promises.push(pageChangePromise);
            }

            await Promise.all(promises);
        }

        async function onResourceLoadingCountChange() {
            const currentlyLoading = $scope.getCurrentResourceLoadingCount();
            if (currentlyLoading === 0) {
                $scope.totalResourcesLoading = 0;
                await onResourceFinishedLoading();
            } else {
                $scope.totalResourcesLoading = Math.max($scope.totalResourcesLoading, currentlyLoading);
            }
        }

        function updateLayersOnPageOrMultiImage() {
            const doc = $scope.contentBuilder.document;

            let layerIdsForPage: number[] | undefined;
            if (isPrintDocument(doc)) {
                layerIdsForPage = doc.pages[$scope.contentBuilder.pageContext.pageIndex].layerIds;
            } else if (isMultiImageContentDocument(doc)) {
                layerIdsForPage = doc.multiImage[$scope.contentBuilder.multiImageContext.imageIndex].layerIds;
            }

            const layersOnPage = linq
                .from(doc.layers)
                .where(layer => isNullOrUndefined(layerIdsForPage) || layerIdsForPage.indexOf(layer.id) > -1)
                .reverse()
                .toArray();

            $scope.layersOnPage = layersOnPage;
        }

        $scope.getCurrentResourceLoadingCount = () =>
            $scope.resourceLoadingCount + $scope.contentBuilder.resourceLoadingCount;

        $scope.$on(builderConstants.EVENTS.UPDATE_DOCUMENT_FROM_EDITOR, () => {
            $scope.updateDocumentFromEditor();
        });

        $scope.updateDocumentFromEditor = () => {
            $scope.contentBuilder.updateDocumentFromEditor();
        };

        $scope.updateEditorFromDocument = () => {
            $scope.contentBuilder.updateEditorFromDocument();
            $scope.channelDataList = Object.keys($scope.contentBuilder.document.channelData || {});
        };

        function onSelectedLayerChange(newValue: boolean, oldValue: boolean) {
            if (newValue) {
                $scope.shouldShowDocumentProperties = false;
                $scope.shouldShowChannelDataProperties = false;
            }
        }

        $scope.selectLayer = (layer: Layer, $event?: JQueryEventObject) => {
            // Don't try to select the layer if user clicked on layer sub-menu
            const $target = $event && $($event.target);
            const $layerDropDown = $target && $target.closest('.dropdown-menu').closest('.layer-dropdown');
            if ($layerDropDown && $layerDropDown.length) {
                return;
            }

            if ($event && $event.shiftKey) {
                $scope.contentBuilder.bulkSelectLayers(layer);
                return;
            }

            $scope.contentBuilder.selectLayer(layer, $event && ($event.ctrlKey || $event.metaKey));
        };

        $scope.deleteLayer = (layer: Layer) => {
            $scope.contentBuilder.deleteLayer(layer);
            // Bit dodge doing this here, not a clear separation of concerns (image cache is cleared by the
            //  ContentBuilder, but file cache is cleared in the controller).
            if (isImageLayer(layer) && layer.location) {
                if (
                    layer.locationType === LocationType.local &&
                    !$scope.contentBuilder.isLocationIsUsedByALayer(layer.location)
                ) {
                    $scope.imageCache.remove(layer.location);
                    $scope.fileCache.remove(layer.location);
                }
            }
        };

        $scope.moveLayerUp = layer => {
            if (!$scope.contentBuilder.canMoveLayerUp(layer)) {
                return;
            }

            $scope.contentBuilder.moveLayerUp(layer);
        };

        $scope.moveLayerDown = layer => {
            if (!$scope.contentBuilder.canMoveLayerDown(layer)) {
                return;
            }

            $scope.contentBuilder.moveLayerDown(layer);
        };

        let draggingLayerLabelWidth: number;

        $scope.layerSortOptions = {
            helper(event, ui) {
                draggingLayerLabelWidth = $(ui.context).find('.layer-label').width();
                return ui.context;
            },
            items: '.layer-entry',
            start: (event, ui) => {
                $(ui.item.context).find('.layer-label').width(draggingLayerLabelWidth);
            },
            stop: (event, ui) => {
                $(ui.item.context).find('.layer-label').css('width', '');

                const layerScope = angular.element(ui.item.context).scope() as any;
                const layer = layerScope.layer;

                const oldIndex = $scope.layersOnPage.indexOf(layer);
                const newIndex = $(ui.item.context).index();

                if (newIndex > oldIndex) {
                    let count = newIndex - oldIndex;
                    while (count-- > 0) {
                        $scope.contentBuilder.moveLayerDown(layer);
                    }
                } else if (newIndex < oldIndex) {
                    let count = oldIndex - newIndex;
                    while (count-- > 0) {
                        $scope.contentBuilder.moveLayerUp(layer);
                    }
                }
            },
        };

        $scope.duplicateLayer = (layer: Layer): ng.IPromise<number> =>
            $q((resolve: IQResolveReject<number>, reject: IQResolveReject<number>) => {
                const doc = $scope.contentBuilder.document;

                function duplicateLayerToPage(toPageIndex: number) {
                    $scope.contentBuilder.duplicateLayer(layer, { toPageIndex });
                    resolve();
                }

                function duplicateLayerToMultiImage(toMultiImageIndex: number) {
                    $scope.contentBuilder.duplicateLayer(layer, { toMultiImageIndex });
                    resolve();
                }

                if (isPrintDocument(doc)) {
                    const pageCount = doc.pages.length;
                    if (pageCount > 1) {
                        const pageIndex = $scope.contentBuilder.pageContext.pageIndex;

                        return duplicateToPagePromptService
                            .showModal({ pageCount, pageIndex })
                            .then(duplicateLayerToPage, reject);
                    } else {
                        return duplicateLayerToPage(0);
                    }
                } else if (isMultiImageContentDocument(doc)) {
                    const multiImageCount = doc.multiImage.length;
                    if (multiImageCount > 1) {
                        const multiImageIndex = $scope.contentBuilder.multiImageContext.imageIndex;
                        return duplicateToPagePromptService
                            .showModal({ pageCount: multiImageCount, pageIndex: multiImageIndex })
                            .then(duplicateLayerToMultiImage, reject);
                    } else {
                        return duplicateLayerToMultiImage(0);
                    }
                } else {
                    return $scope.contentBuilder.duplicateLayer(layer, {});
                }
            });

        $scope.toggleLayerVisibility = layer => {
            $scope.contentBuilder.toggleLayerVisibility(layer);
        };

        function addImageLayer(image: HTMLImageElement, location: string, dpi: number): ImageLayer {
            const layer = $scope.contentBuilder.addImageFromElement(image, location, dpi);
            $scope.selectLayer(layer);
            return layer as ImageLayer;
        }

        function updateImageLayer(image: HTMLImageElement, location: string, layer: ImageLayer) {
            const imageLayer = $scope.contentBuilder.setImageFromElementForReplacement(image, location, layer);
            $scope.selectLayer(imageLayer);
        }

        function showImageCropPrompt(layer: ImageLayer, file: File): ng.IPromise<File> {
            return imageCropperService.showCropperModal(
                file,
                {
                    height: layer.height,
                    width: layer.width,
                },
                undefined,
                layer.strictCropping,
            );
        }

        function getImageDpi(file: File): ng.IPromise<number> {
            // Try to get the DPI from the image, but ultimately confirm with user via a prompt
            const result = $scope.imageMetadataService.getImageDpi(file).then(
                dpiXY => $scope.imageDpiPromptService.show(dpiXY.x),
                () => $scope.imageDpiPromptService.show(),
            );

            return result;
        }

        $scope.onFileSelectForImage = (file: File, layer?: ImageLayer): IPromise<ImageLayer> => {
            // Get the DPI for the image so that we know how to scale the
            // Image correctly, including when cropping.
            // Also, don't bother trying to extract DPI for anything other
            // Than print templates - for everything else just return the
            // Web's hard-coded PPI
            const dpiPromise = $scope.isPrintTemplate ? getImageDpi(file) : $q.resolve(BROWSER_PIXELS_PER_INCH);

            return dpiPromise.then(async dpi => {
                // If we just selected an image, for an image layer that already exists, then show the crop dialog
                const cropPromise = layer ? showImageCropPrompt(layer, file) : $q.resolve(file);

                // Await the crop dialog...
                const inputFile = await cropPromise;
                const image = await imageLoaderService.loadImageFromFile(
                    $scope.imageCache,
                    $scope.fileCache,
                    inputFile,
                );
                if (layer) {
                    const originalLocation = layer.locationType === LocationType.local ? layer.location : undefined;
                    updateImageLayer(image, image.src, layer);
                    // If replacing image in an existing layer which uses a local file
                    // Remove the old file from our file cache.
                    if (originalLocation && !$scope.contentBuilder.isLocationIsUsedByALayer(originalLocation)) {
                        $scope.fileCache.remove(originalLocation);
                    }
                    return layer;
                } else {
                    return addImageLayer(image, image.src, dpi);
                }
            });
        };

        $scope.onSelectLocalImage = (file: File, optionalLayer?: ImageLayer) => {
            if (optionalLayer) {
                optionalLayer.substitutionType = null;
            }
            return $scope.onFileSelectForImage(file, optionalLayer).then(noop);
        };

        $scope.onSelectPlannerImage = async (plannerImage: Upload, optionalLayer?: ImageLayer) => {
            if (optionalLayer) {
                optionalLayer.substitutionType = null;
            }
            return imageLoaderService
                .loadFileFromExternal(plannerImage.url!)
                .then(file => $scope.onFileSelectForImage(file, optionalLayer))
                .then(noop);
        };

        async function validateVideoType(fileName: string): Promise<void> {
            return new Promise<void>((resolve, reject) => {
                const mimeType = fileUtils.getMimeType(fileName);
                if (SUPPORTED_VIDEO_MIME_TYPES.indexOf(mimeType) > -1) {
                    resolve();
                } else {
                    reject(new Error(i18nService.text.build.video.unsupportedFormat()));
                }
            });
        }

        function validateFileSize(videoFile: File): IPromise<void> {
            return uploadService.checkFileSize(videoFile);
        }

        async function selectAssetLibraryVideo(resource: AssetLibraryResourceType, layer: VideoLayer) {
            const videoFile = await imageLoaderService.loadFileFromExternal(resource.asset.url);

            await validateVideoType(videoFile.name);

            const video = await videoLoaderService.loadVideoFromFile($scope.videoCache, $scope.fileCache, videoFile);
            const newLayer = $scope.contentBuilder.setVideoFromElement(
                video.element,
                video.element.src,
                layer,
                LocationType.local,
            );
            $scope.contentBuilder.linkedAssetLibraryAsset = [
                ...$scope.contentBuilder.linkedAssetLibraryAsset.filter(({ layerId }) => layerId !== newLayer.id),
                { asset: resource.asset, layerId: newLayer.id },
            ];
        }

        async function selectLocalVideo(resource: LocalResourceType, layer: VideoLayer) {
            const videoFile = resource.file;

            await validateFileSize(videoFile);
            await validateVideoType(videoFile.name);

            const video = await videoLoaderService.loadVideoFromFile($scope.videoCache, $scope.fileCache, videoFile);
            $scope.contentBuilder.setVideoFromElement(video.element, video.element.src, layer, LocationType.local);
        }

        async function selectPlannerVideo(resource: PlannerResourceType, layer: VideoLayer) {
            const plannerVideo = resource.upload;
            const fullUrl = `https:${plannerVideo.url!}`;

            await validateVideoType(plannerVideo.filename);

            const video = await videoLoaderService.loadVideoFromUrl($scope.videoCache, fullUrl);
            $scope.contentBuilder.setVideoFromElement(video.element, fullUrl, layer, LocationType.external);
        }

        function selectLocationLogo(locationLogo: Upload, optionalLayer?: ImageLayer): IPromise<void> {
            if (locationLogo) {
                return imageLoaderService
                    .loadFileFromExternal(locationLogo.url!)
                    .then(file => $scope.onFileSelectForImage(file, optionalLayer))
                    .then(layer => {
                        layer.substitutionType = ImageSubstitutionType.LocationLogo;
                    });
            } else {
                return $q((resolve: IQResolveReject<void>, reject: IQResolveReject<void>) => {
                    try {
                        const layer = optionalLayer || $scope.contentBuilder.addImage();
                        layer.substitutionType = ImageSubstitutionType.LocationLogo;
                        $scope.contentBuilder.updateImageLayerSource(layer, null);
                        $scope.selectLayer(layer);
                        return resolve();
                    } catch (err) {
                        return reject();
                    }
                });
            }
        }

        /*
         * Resource picker
         */
        $scope.assetLibraryMediaFilter = [];
        $scope.canChooseLocationLogo = mvIdentity.isManager();
        $scope.plannerResources = [];
        $scope.assetResources = [];
        $scope.resourcePickerMediaTypeFilter = 'image';
        $scope.isAssetLibraryModalShown = false;
        $scope.isResourcePickerModalShown = false;

        $scope.handleCloseResourcePicker = () => {
            $scope.isAssetLibraryModalShown = false;
            $scope.isResourcePickerModalShown = false;
        };

        $scope.handleCloseAssetLibrary = () => {
            $scope.isAssetLibraryModalShown = false;
            $scope.isResourcePickerModalShown = true;
        };

        $scope.openAssetLibraryModal = () => {
            $scope.collectionGraphqlId = null;
            $scope.isAssetLibraryModalShown = true;
            $scope.isResourcePickerModalShown = false;
        };

        $scope.openAssetLibraryModalWithSuggestedCollection = () => {
            if ($scope.collectionId) {
                $scope.collectionGraphqlId = convertIdToUniversalNodeId('collection', $scope.collectionId);
            }
            $scope.isAssetLibraryModalShown = true;
            $scope.isResourcePickerModalShown = false;
        };

        $scope.handleAssetChosen = (asset: ViewFileDto) => {
            $scope.handleResourcePicked({ asset, type: 'assetLibrary' });
        };

        /**
         * Check whether image File dimensions is below the set MAX_IMAGE_DIMENSIONS.
         *
         * @param file - image File
         * @returns Promise<boolean>
         */
        const checkImageFileDimensionsLimit = async (file: File) => {
            const { height, width } = await fileUtils.getImageDimensionsFromFile(file);
            return height <= MAX_IMAGE_DIMENSIONS && width <= MAX_IMAGE_DIMENSIONS;
        };

        /**
         * Check whether image Upload dimensions is below the set MAX_IMAGE_DIMENSIONS.
         * If the 'url' property of the Upload object is null,
         * this function won't check the dimensions and will return true.
         *
         * @param upload - image Upload
         * @returns Promise<boolean>
         */
        const checkImageUploadDimensionsLimit = async (upload: Upload) => {
            if (upload.url) {
                const { height, width } = await fileUtils.getImageDimensionsFromDataUrl(upload.url as string);
                return height <= MAX_IMAGE_DIMENSIONS && width <= MAX_IMAGE_DIMENSIONS;
            } else {
                return true;
            }
        };

        /**
         * Check whether it is a Print Template and image resource dimensions is below the set MAX_IMAGE_DIMENSIONS.
         * Check only applies to PNG images and other file types will return true.
         *
         * @param resource - image of AnyResourceType
         * @returns {Promise<boolean>} False if current template is a Print template and
         * image is a PNG with a width and height > MAX_IMAGE_DIMENSIONS.
         */
        const checkImageResourceDimensionsLimit = async (resource: AnyResourceType): Promise<boolean> => {
            if (!$scope.isPrintTemplate) {
                return Promise.resolve(true);
            }

            if (resource.type === 'assetLibrary' && resource.asset.mimeType === MIME_TYPE_MAP.png) {
                const file = await imageLoaderService.loadFileFromExternal(resource.asset.url);
                return checkImageFileDimensionsLimit(file);
            } else if (resource.type === 'local' && resource.file.type === MIME_TYPE_MAP.png) {
                return checkImageFileDimensionsLimit(resource.file);
            } else if (
                (resource.type === 'logo' || resource.type === 'planner') &&
                $scope.fileUtils.getMimeType(resource.upload.filename) === MIME_TYPE_MAP.png
            ) {
                return checkImageUploadDimensionsLimit(resource.upload);
            } else {
                return Promise.resolve(true);
            }
        };

        $scope.builderType = 'contentBuilder';

        $scope.isLocationDetailsModalShown = false;
        $scope.onLocationDetailsModalCancel = () => {
            $scope.isLocationDetailsModalShown = false;
        };
        $scope.onLocationDetailsModalValidationError = () => null;
        $scope.onLocationDetailsModalSubmit = () => {
            if ($scope.location) {
                mvLocation
                    .getAssignedLocation($scope.location.id)
                    .then(async loc => {
                        await updateLocation(loc);
                        $scope.isLocationDetailsModalShown = false;
                    })
                    .catch(error =>
                        mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToSaveTemplate(), error),
                    );
            }
        };

        const handlePickedImage = interactionUtils.createFuture(
            'Insert image',
            async (context: { resource: AnyResourceType; optionalLayer?: ImageLayer }): Promise<void> => {
                try {
                    /*
                     * This is a hacky fix for DS-4802. Opening the cropping modal too soon
                     * causes the 'modal-open' class to be removed from the body element
                     * when the new modal opens. Adding this delay fixes this issue...
                     *
                     * @see https://digitalstack.atlassian.net/browse/DS-4802
                     */
                    await new Promise(resolve => {
                        setTimeout(resolve, 500);
                    });
                    const { optionalLayer, resource } = context;
                    const dimensionsCheck = await checkImageResourceDimensionsLimit(resource);
                    /* Limit image width and height to <= MAX_IMAGE_DIMENSIONS if image
                        type is PNG and it is being added to a Print template */
                    if (dimensionsCheck) {
                        if (optionalLayer) {
                            $scope.contentBuilder.linkedAssetLibraryAsset =
                                $scope.contentBuilder.linkedAssetLibraryAsset.filter(
                                    ({ layerId }) => layerId !== optionalLayer.id,
                                );
                        }
                        if (resource.type === 'assetLibrary') {
                            const file = await imageLoaderService.loadFileFromExternal(resource.asset.url);
                            const layer = await $scope.onFileSelectForImage(file, optionalLayer);
                            $scope.contentBuilder.linkedAssetLibraryAsset.push({
                                asset: resource.asset,
                                layerId: layer.id,
                            });
                        } else if (resource.type === 'planner') {
                            await $scope.onSelectPlannerImage(resource.upload, optionalLayer);
                        } else if (resource.type === 'local') {
                            if (!fileUtils.isImageMimeType(resource.file.type)) {
                                mvNotifier.expectedError(
                                    i18nService.text.common.unsupportedImageFileType({
                                        fileTypes: fileUtils.imageFileTypes.join(', '),
                                    }),
                                );
                            } else {
                                await $scope.onSelectLocalImage(resource.file, optionalLayer);
                            }
                        } else if (resource.type === 'logo') {
                            await selectLocationLogo(resource.upload, optionalLayer);
                        } else {
                            throw assertNever(resource);
                        }
                    } else {
                        mvNotifier.expectedError(
                            i18nService.text.build.error.dimensionsForPngInPrintTemplatesLimited({
                                maxDimensions: MAX_IMAGE_DIMENSIONS.toString(),
                            }),
                        );
                    }
                } catch (error) {
                    if (error) {
                        throw error;
                    } else {
                        /*
                         * DS-4027: It's a rejected promise from AngularUI Bootstrap.
                         * It's not actually an error, it's just how AngularUI
                         * Bootstrap handles models that are dismissed.
                         */
                    }
                }
            },
        );

        $scope.showImageChooser = (optionalLayer?: ImageLayer): ng.IPromise<void> => {
            $scope.assetLibraryMediaFilter = ['image', 'folder'];
            $scope.resourcePickerMediaTypeFilter = 'image';
            $scope.resourcePickerOptionalLayer = optionalLayer;
            $scope.isResourcePickerModalShown = true;
            $scope.plannerResources = getPlannerImages();
            return Promise.resolve();
        };

        const selectVideoForLayer = async (
            resource: AnyResourceType,
            optionalLayer: VideoLayer,
            attempts: number,
        ): Promise<void> => {
            try {
                if (resource.type === 'assetLibrary') {
                    await selectAssetLibraryVideo(resource, optionalLayer);
                } else if (resource.type === 'planner') {
                    await selectPlannerVideo(resource, optionalLayer);
                } else if (resource.type === 'local') {
                    await selectLocalVideo(resource, optionalLayer);
                } else if (resource.type === 'logo') {
                    // This shouldn't happen
                } else {
                    throw assertNever(resource);
                }
            } catch (err: unknown) {
                if (isRecordType(err) && err.message === 'No network connection') {
                    if (attempts < MAX_RETRY_ATTEMPTS) {
                        // Retry once
                        await $timeout(2000);
                        return selectVideoForLayer(resource, optionalLayer, attempts + 1);
                    } else {
                        mvNotifier.unexpectedError(i18nService.text.build.video.networkError());
                    }
                } else {
                    throw err;
                }
            }
            return Promise.resolve();
        };

        const handlePickedVideo = interactionUtils.createFuture(
            'Insert video',
            async (context: { resource: AnyResourceType; optionalLayer?: VideoLayer }): Promise<void> => {
                const { optionalLayer, resource } = context;
                if (!optionalLayer) {
                    throw new Error('Video layer not found');
                }

                await selectVideoForLayer(resource, optionalLayer, 0);
            },
        );

        $scope.handleResourcePicked = (resource: AnyResourceType) => {
            $scope.handleCloseAssetLibrary();
            $scope.handleCloseResourcePicker();
            if ($scope.resourcePickerMediaTypeFilter === 'image') {
                void handlePickedImage.run({
                    optionalLayer: $scope.resourcePickerOptionalLayer as ImageLayer,
                    resource,
                });
            } else if ($scope.resourcePickerMediaTypeFilter === 'video') {
                void handlePickedVideo.run({
                    optionalLayer: $scope.resourcePickerOptionalLayer as VideoLayer,
                    resource,
                });
            } else {
                throw assertNever($scope.resourcePickerMediaTypeFilter);
            }
        };

        $scope.showVideoChooser = (optionalLayer?: VideoLayer): ng.IPromise<void> => {
            $scope.assetLibraryMediaFilter = ['video'];
            $scope.resourcePickerMediaTypeFilter = 'video';
            $scope.resourcePickerOptionalLayer = optionalLayer;
            $scope.isResourcePickerModalShown = true;
            $scope.plannerResources = getPlannerVideos();
            return Promise.resolve();
        };

        $scope.addRectangle = () => {
            $scope.selectLayer($scope.contentBuilder.addRectangle());
        };

        $scope.addEllipse = () => {
            $scope.selectLayer($scope.contentBuilder.addEllipse());
        };

        $scope.addText = () => {
            $scope.selectLayer($scope.contentBuilder.addText());
        };

        $scope.addVideo = () => {
            $scope.selectLayer($scope.contentBuilder.addVideo());
        };

        $scope.addMap = () =>
            $q((resolve: IQResolveReject<MapLayer>, reject: IQResolveReject<MapLayer>) => {
                const newMapLayer = $scope.contentBuilder.addMap();
                $scope.selectLayer(newMapLayer);

                if (
                    $scope.location &&
                    $scope.location.map &&
                    $scope.location.map.upload &&
                    $scope.location.map.upload.url
                ) {
                    return imageLoaderService
                        .loadFileFromExternal($scope.location.map.upload.url)
                        .then(file => imageLoaderService.loadImageFromFile($scope.imageCache, $scope.fileCache, file))
                        .then(image => $scope.contentBuilder.setImageSourceFromElement(image, image.src, newMapLayer))
                        .then(resolve, reject);
                }

                return resolve(newMapLayer);
            });

        function getPageCoords(event: MouseEvent) {
            const pageX = event.pageX === undefined ? event.clientX + document.body.scrollTop : event.pageX;
            const pageY = event.pageY === undefined ? event.clientY + document.body.scrollLeft : event.pageY;
            return {
                pageX,
                pageY,
            };
        }

        $scope.canvasMouseDown = (event: MouseEvent) => {
            const pageCoords = getPageCoords(event);
            $scope.contentBuilder.mouseDown(pageCoords.pageX, pageCoords.pageY, event);
            event.preventDefault();
            event.stopPropagation();
        };

        $scope.canvasMouseUp = (event: MouseEvent) => {
            const pageCoords = getPageCoords(event);
            $scope.contentBuilder.mouseUp(pageCoords.pageX, pageCoords.pageY, event);
        };

        $scope.canvasMouseMove = (event: MouseEvent) => {
            const pageCoords = getPageCoords(event);
            $scope.contentBuilder.mouseMove(pageCoords.pageX, pageCoords.pageY, event);
        };

        $scope.canvasDblClick = (event: MouseEvent) => {
            const pageCoords = getPageCoords(event);
            $scope.contentBuilder.mouseDoubleClick(pageCoords.pageX, pageCoords.pageY, event);
        };

        $scope.setMouseAction = (action: ActionType) => {
            $scope.contentBuilder.setMouseAction(action);
        };

        $scope.mouseOverLayer = (layer: Layer) => {
            $scope.contentBuilder.setHighlightedLayer(layer);
        };

        $scope.mouseLeaveLayer = (layer: Layer) => {
            $scope.contentBuilder.clearHighlightedLayer();
        };

        $scope.zoomIn = () => {
            $scope.contentBuilder.zoomIn();
        };

        $scope.zoomOut = () => {
            $scope.contentBuilder.zoomOut();
        };

        $scope.zoomToFit = () => {
            $scope.contentBuilder.zoomToFit();
        };

        $scope.zoomActualSize = () => {
            $scope.contentBuilder.zoomActualSize();
        };

        async function getCompositeImageAsBlob() {
            // Save in original dimensions
            // NOTE: can export directly to blob on some browsers, maybe should try doing that first?
            return dataUrlToBlob((await $scope.contentBuilder.exportAsImage()).dataUrl!);
        }

        async function uploadCompositeImage(modalScope: UploadResourcesScope) {
            const blob = await getCompositeImageAsBlob();
            const output: Upload[] = [];
            return $q
                .all(
                    uploadService.upload([blob], 'builderTemplate', output, modalScope, {
                        suppressNotifications: true,
                    }),
                )
                .then(() => {
                    if (modalScope.stage) {
                        modalScope.stage++;
                    }
                    return output[0];
                });
        }

        function uploadDocumentLayers(
            contentBuilder: ContentBuilder,
            stages: number,
        ): ShowModalAndUploadResourcesResult {
            const filesToUpload: { [key: string]: Blob | File } = {};
            // Get the scope
            contentBuilder.document.layers
                .filter(
                    (layer): layer is ImageLayer | VideoLayer =>
                        (isImageLayer(layer) || isVideoLayer(layer)) &&
                        layer.locationType === LocationType.local &&
                        Boolean(layer.location),
                )
                .forEach((layer: ImageLayer | VideoLayer) => {
                    const file = $scope.fileCache.get(layer.location!);
                    if (file) {
                        filesToUpload[layer.location!] = file;
                    }
                });

            return builderCommonService.showModalAndUploadResources(
                $scope,
                'builderTemplateImage',
                filesToUpload,
                stages,
            );
        }

        /**
         * Hacky way to process things for an upload
         *
         * @returns - The information needed for an upload
         */
        $scope.getForUpload = async () => {
            const contentBuilder = $scope.contentBuilder;
            const result = uploadDocumentLayers(contentBuilder, 2);

            const [uploadMap, compositeImageUpload] = await $q.all([
                result.promise,
                uploadCompositeImage(result.scope),
            ]);

            // Wrap this in setTimeout or the modal glitches out
            setTimeout(() => {
                result.cleanup();
            }, 500);

            return {
                compositeImageUpload,
                contentBuilder,
                uploadMap,
            };
        };

        $scope.loadLocationDraft = async (locationDraftGraphqlId: string): Promise<void> => {
            $scope.isLoading = true;
            try {
                const draftBuilderTemplate = await builderTemplateApiClient.getLocationDraftAsBuilderTemplate(
                    locationDraftGraphqlId,
                );
                await loadLinkedAssetLibraryAssetsForLocationDraft(locationDraftGraphqlId);
                await loadFromTemplate(draftBuilderTemplate as ContentBuilderTemplate);
                await verifyUsedFontsAreAvailableAfterPromises();
            } catch (error: unknown) {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToRetrieveTemplate(), error);
            } finally {
                $scope.isLoading = false;
            }
        };

        $scope.loadOriginalTemplate = async (): Promise<void> => {
            $scope.isLoading = true;
            try {
                const originalTemplate = $scope.originalTemplate;
                if (!originalTemplate) {
                    throw new Error('Original template could not be found');
                }
                await loadLinkedAssetLibraryAssetsForBuilderTemplate(originalTemplate.id);
                await loadFromTemplate(clone(originalTemplate));
                await verifyUsedFontsAreAvailableAfterPromises();
            } catch (error: unknown) {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToRetrieveTemplate(), error);
            } finally {
                $scope.isLoading = false;
            }
        };

        function saveAsBuilderTemplate(templateId?: number, deleteLocationDrafts?: boolean) {
            const result = uploadDocumentLayers($scope.contentBuilder, 2);

            const cleanup = result.cleanup;
            $q.all([result.promise, uploadCompositeImage(result.scope)])
                .then(
                    async (arr: [{ [key: string]: Upload }, Upload]) => {
                        const uploadMap = arr[0];
                        const compositeImageUpload = arr[1];
                        if ($scope.contentBuilder.document.animation) {
                            $scope.contentBuilder.setAnimationFrame(0);
                        }
                        // Force case backwards compatibility
                        const compatibleLayers = $scope.contentBuilder.document.layers.map(layer =>
                            isTextLayer(layer)
                                ? {
                                    ...layer,
                                    lowercase: layer.textCase === TextCase.lowercase,
                                    uppercase: layer.textCase === TextCase.uppercase,
                                }
                                : layer,
                        );
                        $scope.contentBuilder.document.layers = compatibleLayers;
                        const builderTemplate: DocumentTemplateToSave = {
                            categories: $scope.selectedCategoryIds,
                            clientId: $scope.boundData.selectedClient!.id,
                            compositeImageUpload,
                            document: {
                                ...$scope.contentBuilder.document,
                                editableFields: deDuplicateEditableFields(
                                    $scope.contentBuilder.document.editableFields,
                                ),
                            },
                            formats: $scope.selectedBuilderTemplateFormats,
                            imageMap: uploadMap,
                            isDraft: $scope.boundData.isDraft,
                            isMultiImage: $scope.isMultiImage(),
                            linkedAssetLibraryAssetIds: $scope.contentBuilder.linkedAssetLibraryAsset.map(pair => ({
                                assetId: pair.asset.id,
                                layerId: pair.layerId,
                            })),
                            tags: $scope.tags,
                        };
                        try {
                            const data = await builderTemplateApiClient.createOrUpdateBuilderTemplate(
                                builderTemplate,
                                templateId,
                                deleteLocationDrafts,
                            );
                            cleanup();
                            mvNotifier.notify(i18nService.text.build.templateSaved());
                            // eslint-disable-next-line require-atomic-updates
                            $scope.isDirty = false;
                            if (templateId) {
                                $route.reload();
                            } else {
                                const search: {
                                    template: number;
                                    planner?: number;
                                } = { template: data.id };
                                if ($scope.plannerId) {
                                    search.planner = $scope.plannerId;
                                }
                                $location.path('/contentBuilder').search(search);
                            }
                        } catch (error) {
                            cleanup();
                            mvNotifier.unexpectedErrorWithData(
                                i18nService.text.build.error.failedToSaveTemplate(),
                                error,
                            );
                        }
                    },
                    data => {
                        cleanup();
                        mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToUploadImages(), data);
                    },
                )
                .catch(error => {
                    cleanup();
                    mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToUploadImages(), error);
                });
        }

        $scope.saveAsExistingBuilderTemplate = (deleteLocationDrafts: boolean) => {
            saveAsBuilderTemplate($scope.templateId!, deleteLocationDrafts);
        };

        $scope.saveAsNewBuilderTemplate = () => {
            saveAsBuilderTemplate();
        };

        $scope.deleteBuilderTemplate = () =>
            builderTemplateApiClient.deleteBuilderTemplate($scope.templateId!).then(
                () => {
                    mvNotifier.notify(i18nService.text.build.templateDeleted());
                    $scope.isDirty = false;
                    $location.path('/builderTemplateGallery');
                },
                (data: unknown) => {
                    mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToDeleteTemplate(), data);
                },
            );

        $scope.needsToSeeAllFramesToPublish = (): boolean => {
            const result =
                !$scope.contentBuilder.advancedMode &&
                Boolean($scope.contentBuilder.document.animation) &&
                !$scope.boundData.hasSeenAllFrames;

            return result;
        };

        $scope.needsToSeeAllPagesToPublish = () => {
            const doc = $scope.contentBuilder.document;
            const result =
                !$scope.contentBuilder.advancedMode &&
                isPrintDocument(doc) &&
                doc.pages.length > 1 &&
                !$scope.boundData.hasSeenAllPages;
            return result;
        };

        $scope.needsToSeeAllImagesToPublish = () => $scope.isMultiImage() && !$scope.boundData.hasSeenAllImages;

        function ValidateVideoRequirements(): boolean {
            if ($scope.isVideoTemplate) {
                const videoLayers = $scope.contentBuilder.getVideoLayers();

                if (videoLayers.length === 0 || videoLayers[0].location === null) {
                    mvNotifier.expectedError(i18nService.text.build.video.noVideoUploaded());
                    return false;
                }
            }
            return true;
        }

        $scope.isExportEnabled = () => {
            const validationErrors = $scope.contentBuilder
                .validateDocument()
                .filter(result => result.severity === FieldValidationResultSeverity.Error);
            const hasValidationErrors = validationErrors.length > 0;
            return !hasValidationErrors && Boolean($scope.templateId);
        };

        $scope.toggleEditableField = (
            layer: BuilderDocument | Layer,
            fieldPath: string,
            controlType: string,
            label: string,
        ) => {
            $scope.contentBuilder.toggleEditableField(layer, fieldPath, controlType, label);
        };

        $scope.hasEditableField = (layer: BuilderDocument | Layer, fieldPath: string, control: string) => {
            if (!layer) {
                return false;
            }
            return $scope.contentBuilder.hasEditableField(layer, fieldPath, control);
        };

        $scope.selectDocumentProperties = () => {
            $scope.contentBuilder.deselectAllLayers();
            $scope.shouldShowDocumentProperties = true;
            $scope.shouldShowChannelDataProperties = false;
        };

        $scope.selectChannelDataProperties = () => {
            $scope.contentBuilder.deselectAllLayers();
            $scope.shouldShowDocumentProperties = false;
            $scope.shouldShowChannelDataProperties = true;
        };

        $scope.togglePlannerDetails = () => {
            if ($scope.plannerDetails) {
                $scope.plannerDetails.visible = !$scope.plannerDetails.visible;
            }
        };

        $scope.areResourcesLoading = () =>
            $scope.getCurrentResourceLoadingCount() > 0 ||
            handlePickedVideo.isRunning() ||
            handlePickedImage.isRunning();

        $scope.toggleAnimation = () => {
            $scope.contentBuilder.createOrRemoveAnimation();
        };

        $scope.previousAnimationFrame = () => {
            $scope.contentBuilder.previousAnimationFrame();
            hideSeenAllFramesMessage();
        };

        $scope.nextAnimationFrame = () => {
            $scope.contentBuilder.nextAnimationFrame();
            hideSeenAllFramesMessage();
            if ($scope.isLastFrame()) {
                $scope.boundData.hasSeenAllFrames = true;
            }
        };

        function hideSeenAllFramesMessage() {
            popoverService.destroy(CONTROL_SELECTORS.ANIMATION.NEXT);
        }

        $scope.addAnimationFrameBefore = () => {
            $scope.contentBuilder.addAnimationFrameBefore();
        };

        $scope.addAnimationFrameAfter = () => {
            $scope.contentBuilder.addAnimationFrameAfter();
        };

        $scope.deleteCurrentAnimationFrame = () => {
            $scope.contentBuilder.deleteCurrentAnimationFrame();
        };

        $scope.isFirstFrame = () => $scope.contentBuilder.animationContext.frameIndex === 0;

        $scope.isLastFrame = (): boolean =>
            Boolean($scope.contentBuilder.document.animation) &&
            $scope.contentBuilder.document.animation !== null &&
            $scope.contentBuilder.animationContext.frameIndex ===
            $scope.contentBuilder.document.animation.frames.length - 1;

        $scope.isLayerVisible = (layer: Layer | Section): boolean => {
            const doc = $scope.contentBuilder.document;

            let isVisible = (layer && layer.visible === undefined) || layer.visible === true;

            if (isVisible && isPrintDocument(doc)) {
                const page = doc.pages[$scope.contentBuilder.pageContext.pageIndex];
                isVisible = page.layerIds.indexOf(layer.id) > -1;
            } else if (isVisible && isMultiImageContentDocument(doc)) {
                const multiImage = doc.multiImage[$scope.contentBuilder.multiImageContext.imageIndex];
                isVisible = multiImage.layerIds.indexOf(layer.id) > -1;
            }

            return isVisible;
        };

        $scope.hasVideoLayer = (): boolean => $scope.contentBuilder.getVideoLayers().length > 0;

        $scope.startAnimation = () => {
            $scope.contentBuilder.startAnimation();
        };

        $scope.stopAnimation = () => {
            $scope.contentBuilder.stopAnimation();
        };

        $scope.startVideo = (): void => {
            $scope.contentBuilder.startVideo();
        };

        $scope.stopVideo = (): void => {
            $scope.contentBuilder.stopVideo();
        };

        $scope.toggleMute = (): void => {
            $scope.contentBuilder.toggleMute();
        };

        $scope.toggleLooping = () => {
            $scope.contentBuilder.toggleLooping();
        };

        $scope.toggleLoopForever = () => {
            $scope.contentBuilder.toggleLoopForever();
        };

        $scope.getChannelDataConfig = () => channelDataService.channelDataConfig;

        $scope.addChannelData = (channelName: ChannelName) => {
            $scope.contentBuilder.addChannelData(channelName);
        };

        $scope.shouldDisplayChannelDataConfigOption = <T>(channelDataConfig: ChannelDataConfig<T>) => {
            const channelName = channelDataConfig.channel;
            const channelData = $scope.contentBuilder.document.channelData;
            if (!channelData) {
                return true;
            }
            const channelDatum = channelData[channelName];
            return channelDatum === undefined || channelDatum === null;
        };

        $scope.shouldDisplayChannelData = <T>(channelDataConfig: ChannelDataConfig<T>) => {
            const channelName = channelDataConfig.channel;
            const channelData = $scope.contentBuilder.document.channelData;
            if (!channelData) {
                return false;
            }
            const channelDatum = channelData[channelName];
            return channelDatum !== undefined && channelDatum !== null;
        };

        $scope.deleteChannelDatum = (channelName: ChannelName) => {
            $scope.contentBuilder.deleteChannelDatum(channelName);
        };

        $scope.checkChannelDataExists = (channelName: ChannelName) => {
            const channelData = $scope.contentBuilder.document.channelData || {};
            return !!channelData[channelName];
        };

        function validateSeenAllScenes() {
            function showMessage(target: string, sceneType: 'frame' | 'image' | 'page') {
                let contentText = '';
                switch (sceneType) {
                    case 'frame':
                        contentText = i18nService.text.build.mustViewAllFrames();
                        break;
                    case 'image':
                        contentText = i18nService.text.build.mustViewAllImages();
                        break;
                    case 'page':
                        contentText = i18nService.text.build.mustViewAllPages();
                        break;
                    default:
                        break;
                }
                popoverService.show(target, {
                    container: 'body',
                    content: `<span class='text-danger text-center'>${contentText}</span>`,
                    html: true,
                    placement: 'top',
                    trigger: 'manual',
                });
            }

            if ($scope.needsToSeeAllFramesToPublish()) {
                showMessage(CONTROL_SELECTORS.ANIMATION.NEXT, 'frame');
                return false;
            }

            if ($scope.needsToSeeAllPagesToPublish()) {
                showMessage(CONTROL_SELECTORS.PAGING.NEXT, 'page');
                return false;
            }

            if ($scope.needsToSeeAllImagesToPublish()) {
                showMessage(CONTROL_SELECTORS.IMAGE.NEXT, 'image');
                return false;
            }

            return true;
        }

        function validateRequiredSubstitutionContent(): boolean {
            let noIssue = true;

            if ($scope.imageSubsitutionNotifications.length > 0) {
                displayImageSubsitutionMessages();
                noIssue = false;
            }

            if (!checkLocationTextSubstitutionRequirements()) {
                noIssue = false;
            }

            return noIssue;
        }

        $scope.startExport = () => {
            if (introService.isIntroActive('build') && $scope.introBuild) {
                setView('PUBLISH');
                $scope.introBuild.nextStep();
                return;
            }

            if (!validateSeenAllScenes()) {
                return;
            }

            if (!ValidateVideoRequirements()) {
                return;
            }

            if (!checkLocationTextSubstitutionRequirements()) {
                $scope.isLocationDetailsModalShown = true;
                return;
            }

            if (!validateRequiredSubstitutionContent()) {
                return;
            }

            if (isVideoDocument($scope.contentBuilder.document)) {
                $scope.contentBuilder.stopVideo();
            }
            $scope.contentBuilder.uiContext.showPlaceholderOverlays = false;

            const lastStep = () => {
                setView('PUBLISH');
            };

            if ($scope.boundData.isDraft) {
                // eslint-disable-next-line consistent-return
                return confirmModal.open(
                    i18nService.text.build.unpublishedTemplate(),
                    i18nService.text.build.unpublishedTemplatePublishWarning(),
                    lastStep,
                    noop,
                );
            } else {
                lastStep();
            }
        };

        function scrollToTop() {
            window.scrollTo(0, 0);
        }

        function setView(view: BuilderView) {
            if (view === 'BUILDER' && $scope.contentBuilder.advancedMode) {
                $scope.contentBuilder.enableKeyboardShortcuts();
            } else {
                $scope.contentBuilder.disableKeyboardShortcuts();
            }
            $scope.boundData.view = view;
            scrollToTop();
        }

        function onBuilderPublishCancelEvent(event: IAngularEvent) {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            $scope.cancelPublish();
        }

        $scope.cancelPublish = () => {
            setView('BUILDER');
            $scope.contentBuilder.uiContext.showPlaceholderOverlays = true;
        };

        function onBuilderPublishFinishEvent() {
            $scope.isDirty = false;
            setView('BUILDER');
        }

        $scope.constructPlannerUrl = () => plannerUIService.getPlannerUrl($scope.plannerDetails!);

        $scope.isLargeDisplay = () => $(window).width() >= 1200;

        function onBuilderRenderAndResetZoom() {
            $scope.zoomToFit();
        }

        $scope.advancedMode = () => $scope.contentBuilder.advancedMode;

        function unselectLayer() {
            if ($scope.shouldShowDocumentProperties || $scope.shouldShowChannelDataProperties) {
                $scope.contentBuilder.deselectAllLayers();
            }
        }

        $scope.gotoPreviousPage = () => {
            $scope.contentBuilder.gotoPreviousPage();
            unselectLayer();
            hideSeenAllPagesMessage();
        };

        $scope.gotoNextPage = () => {
            $scope.contentBuilder.gotoNextPage();
            unselectLayer();
            hideSeenAllPagesMessage();
            if ($scope.contentBuilder.isLastPage()) {
                $scope.boundData.hasSeenAllPages = true;
            }
        };

        function hideSeenAllPagesMessage() {
            popoverService.destroy(CONTROL_SELECTORS.PAGING.NEXT);
        }

        function hideSeenAllImagesMessages() {
            popoverService.destroy(CONTROL_SELECTORS.IMAGE.NEXT);
        }

        $scope.addPageBefore = () => {
            $scope.contentBuilder.addPageBefore();
            unselectLayer();
        };

        $scope.addPageAfter = () => {
            $scope.contentBuilder.addPageAfter();
            unselectLayer();
        };

        $scope.deletePage = () => {
            $scope.contentBuilder.deletePage();
            unselectLayer();
        };

        $scope.availablePageOrientations = [
            {
                label: i18nService.text.build.portrait(),
                value: 'portrait',
            },
            {
                label: i18nService.text.build.landscape(),
                value: 'landscape',
            },
        ];

        $scope.setPageOrientation = (orientation: BuilderPrintDocumentPageOrientation) => {
            $scope.contentBuilder.setPageOrientation(orientation);
        };

        $scope.getPageOrientation = () => $scope.contentBuilder.getPageOrientation();

        $scope.onSelectedCategoryUpdate = (categories: BuilderTemplateCategoryId[]) => {
            $scope.selectedCategoryIds = categories;
        };

        $scope.validateTextFontSizeField = () => {
            $scope.contentBuilder.validateTextFontSizeField();
        };

        $scope.validateLetterSpacingField = () => {
            $scope.contentBuilder.validateLetterSpacingField();
        };

        $scope.refreshMultiImageThumbnail = async (index: number): Promise<string | null> => {
            const result = await $scope.contentBuilder.exportMultiImageItemAsImage(index, {
                height: 125,
                imageFormat: getFormatByName('PNG'),
                width: 125,
            });
            return result?.dataUrl ?? null;
        };

        $scope.refreshPrintTemplatePageThumbnail = async (index: number): Promise<string | null> => {
            const result = await $scope.contentBuilder.exportPrintTemplatePageAsImage(index, {
                height: 125,
                imageFormat: getFormatByName('PNG'),
                width: 125,
            });
            return result?.dataUrl ?? null;
        };

        $scope.gotoMultiImage = async (multiImageId: number) => {
            hideSeenAllImagesMessages();
            unselectLayer();

            const oldIndex = $scope.getMultiImageIndex();
            const oldIndexNewThumbnail = await $scope.refreshMultiImageThumbnail(oldIndex);
            $scope.multiImageItems = $scope.multiImageItems.map((item, index) => {
                if (index === oldIndex) {
                    return { id: index, src: oldIndexNewThumbnail ?? '', title: 'Image Template' };
                } else {
                    return { id: index, src: item.src, title: item.title };
                }
            });

            const multiImageIndex = $scope.multiImageItems.findIndex(({ id }) => id === multiImageId);
            $scope.contentBuilder.gotoMultiImage(multiImageIndex);
        };

        $scope.gotoPrintTemplatePage = async (pageId: number) => {
            hideSeenAllPagesMessage();

            const oldIndex = $scope.contentBuilder.pageContext.pageIndex;
            const oldIndexNewThumbnail = await $scope.refreshPrintTemplatePageThumbnail(oldIndex);
            $scope.printTemplatePages = $scope.printTemplatePages.map((item, index) => {
                if (index === oldIndex) {
                    return { id: index, src: oldIndexNewThumbnail ?? '', title: 'Print template page' };
                } else {
                    return { id: index, src: item.src, title: item.title };
                }
            });

            const pageIndex = $scope.printTemplatePages.findIndex(({ id }) => id === pageId);
            $scope.contentBuilder.gotoPrintTemplatePage(pageIndex);
            if ($scope.contentBuilder.isLastPage()) {
                $scope.boundData.hasSeenAllPages = true;
            }
        };

        $scope.addMultiImage = () => {
            $scope.contentBuilder.addMultiImage();
        };

        $scope.deleteMultiImage = (multiImageId: number) => {
            const multiImageIndex = $scope.multiImageItems.findIndex(({ id }) => id === multiImageId) ?? 0;
            $scope.contentBuilder.deleteMultiImage(multiImageIndex);
        };

        $scope.moveMultiImage = (fromId: number, toId: number) => {
            const fromIndex = $scope.multiImageItems.findIndex(({ id }) => id === fromId) ?? 0;
            const toIndex = $scope.multiImageItems.findIndex(({ id }) => id === toId) ?? 0;
            $scope.contentBuilder.moveMultiImage(fromIndex, toIndex);
        };

        $scope.movePrintTemplatePage = (fromId: number, toId: number) => {
            const fromIndex = $scope.printTemplatePages.findIndex(({ id }) => id === fromId) ?? 0;
            const toIndex = $scope.printTemplatePages.findIndex(({ id }) => id === toId) ?? 0;
            $scope.contentBuilder.movePrintTemplatePage(fromIndex, toIndex);
        };

        $scope.toggleMultiImage = () => {
            $scope.contentBuilder.toggleMultiImage();
        };

        $scope.getMultiImageIndex = () => $scope.contentBuilder.multiImageContext.imageIndex;

        function setHasViewedAllMultiImages(currentIndex: number, totalItems: number) {
            if (totalItems <= 0) {
                return;
            }
            if (currentIndex >= totalItems) {
                $scope.boundData.hasSeenAllImages = true;
            }
        }

        $scope.isMultiImage = () => isNotNullOrUndefined($scope.contentBuilder.document.multiImage);

        async function onMultiImageChange(
            newValue: BuilderMultiImageOption[] | null,
            oldValue: BuilderMultiImageOption[] | null,
        ): Promise<void> {
            if (newValue !== oldValue && !!newValue) {
                $scope.multiImageItems = await Promise.all(
                    newValue.map(async (value, index) => {
                        const dataUrl = await $scope.refreshMultiImageThumbnail(index);
                        return {
                            id: index,
                            src: dataUrl ?? '',
                            title: 'Image Template',
                        };
                    }),
                );
            }
        }

        async function onPrintTemplatePageChange(
            newValue?: BuilderPrintDocumentPage[],
            oldValue?: BuilderPrintDocumentPage[],
        ): Promise<void> {
            if (newValue !== oldValue && !!newValue) {
                $scope.printTemplatePages = await Promise.all(
                    newValue.map(async (value, index) => {
                        const dataUrl = await $scope.refreshPrintTemplatePageThumbnail(index);
                        return {
                            id: index,
                            src: dataUrl ?? '',
                            title: 'Print template page',
                        };
                    }),
                );
            }
        }

        void initPage();
    },
]);

angular.module('app').controller('mvContentBuilderSaveTemplateCtrl', [
    $scopeSID,
    $modalInstanceSID,
    function ($scope: IScope, $modalInstance: ModalInstance) {
        // Do nothing
    },
]);
