import { gql } from '@apollo/client';
// TODO: https://digitalstack.atlassian.net/browse/DSL-4656 - Phase 2
export const GET_CUSTOM_MERGE_FIELDS = gql`
    query GetCustomMergeFields($locationId: ID!, $templateType: BuildableTemplateType!) {
        location(id: $locationId) {
            id
            buildableTemplateCustomMergeFields: buildableTemplateCustomMergeFieldsNew(templateType: $templateType) {
                key
                value
            }
        }
    }
`;
