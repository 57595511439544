import * as React from 'react';
import { noop } from '@deltasierra/object-utilities';
import { Translate, TranslateProps } from '../directives/Translate';
import { ConfirmModal, ConfirmModalProps } from './commonModalService/Modals';

export type BootstrapSize = 'lg' | 'md' | 'sm' | 'xl' | 'xs';
export type BootstrapColor =
    'danger' | 'dark' | 'default' | 'info' | 'light' | 'primary' | 'secondary' | 'success' | 'warning';

export type ButtonProps = {
    /**
     * Set whether the button is displayed inline or block
     *
     * @default false
     */
    block?: boolean;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    label?: TranslateProps;
    onClick?: () => void;
    size?: BootstrapSize;
    spaceLeft?: boolean;
    theme?: BootstrapColor;
    type?: 'button' | 'reset' | 'submit';
};

/**
 * The base button component. This accepts either a label or children.
 *
 * @example
 * // Button with a label
 * <Button label={{ keyId: 'COMMON.MY_BUTTON' }} />
 * @example
 * // Button with custom contents
 * <Button>
 *     <img src="..." />
 * </Button>
 * @param props - Props
 * @param props.children - Custom content for the button (either specify content or a label but not both)
 * @param props.theme - Color theme (refer to the bootstrap documentation for theme options)
 * @param props.label - The button's label (either specify content or a label but not both)
 * @param props.onClick - On click handler/callback
 * @param props.size - Button sizing (refer to the bootstrap documentation for size options)
 * @returns React.ReactElement
 */
export const Button: React.FunctionComponent<ButtonProps> = React.memo(props => {
    const {
        block = false,
        children,
        className = '',
        disabled = false,
        label,
        onClick,
        size,
        spaceLeft = false,
        theme = 'default',
        type = 'button',
        ...rest
    } = props;
    const onClickHandlerOrDefault = onClick || noop;
    const memoizedOnClickHandler: () => void = React.useCallback<() => void>(() => onClickHandlerOrDefault(), [
        onClickHandlerOrDefault,
    ]);
    const themeClass = `btn-${theme}`;
    const sizeClass = size ? `btn-${size}` : '';
    const blockOrInlineClass = block ? 'btn-block' : '';
    return (
        <button
            {...rest}
            className={`btn ${themeClass} ${sizeClass} ${blockOrInlineClass} ${
                spaceLeft ? 'space-left' : ''
            } ${className}`}
            disabled={disabled}
            type={type}
            onClick={memoizedOnClickHandler}
        >
            {label && <Translate {...label} />}
            {!label && children}
        </button>
    );
});
Button.displayName = 'Button';

export type ConfirmButtonProps = ButtonProps &
    Pick<ConfirmModalProps, 'cancelText' | 'confirmText'> & {
        confirmTitle: TranslateProps;
        confirmMessage: TranslateProps;
    };

/**
 * A button that requires the user to confirm their choice before proceeding.
 *
 * @example
 * <ConfirmButton
 *      label={{ keyId: 'COMMON.DELETE' }}
 *      confirmTitle={{ keyId: 'COMMON.ARE_YOU_SURE' }}
 *      confirmMessage={{ keyId: 'USERS.CONFIRM_USER_DELETE', options: { firstName: '...' } }}
 *      onClick={() => { ... }}
 * />
 * @param props - Props
 * @param props.confirmTitle - The title for the confirmation dialog
 * @param props.confirmMessage - Message for the confirmation dialog
 * @returns React.ReactElement
 * @see {@link Button} for additional properties
 */
export const ConfirmButton: React.FunctionComponent<ConfirmButtonProps> = React.memo(props => {
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const { onClick = noop, ...rest } = props;
    return (
        <>
            <Button {...rest} onClick={() => setShowConfirmModal(true)} />
            <ConfirmModal
                cancelText={props.cancelText}
                confirmText={props.confirmText}
                message={props.confirmMessage}
                show={showConfirmModal}
                title={props.confirmTitle}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={onClick}
            />
        </>
    );
});
ConfirmButton.displayName = 'ConfirmButton';
