import { useCurrentClientDispatch } from './useCurrentClientDispatch';
import { useCurrentClientState } from './useCurrentClientState';

/**
 * Use the current client context provided by the provider
 *
 * @param [useLocalStorageAsFallback] - Whether to include the local storage as a fallback?
 * @returns [The current client, The update local storage function]
 */
export function useCurrentClient(
    useLocalStorageAsFallback = true,
): [ReturnType<typeof useCurrentClientState>, ReturnType<typeof useCurrentClientDispatch>] {
    return [useCurrentClientState(useLocalStorageAsFallback), useCurrentClientDispatch()];
}
