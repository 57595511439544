/* eslint-disable max-statements */
/// <reference path="../../../../typings/browser.d.ts" />
import {
    BuilderEmailDocument,
    BuilderEmailDocumentInspector,
    ButtonSection as IButtonSection,
    Html,
    isTextSection,
    Section,
} from '@deltasierra/shared';
import { startsWith } from '@deltasierra/string-utilities';
import { $qSID } from '../../common/angularData';
import { ButtonSection } from './sections/buttonSection';
import { ImageSection } from './sections/imageSection';
import { TextSection } from './sections/textSection';

export const CampaignMonitorTemplateParserSID = 'CampaignMonitorTemplateParser';

export interface ICampaignMonitorTemplateParserResult {
    html: Html;
    sections: Section[];
}

export interface ICampaignMonitorTemplateParser {
    processHtml(html: Html): ng.IPromise<ICampaignMonitorTemplateParserResult>;
    unprocessHtml(html: HTMLDocument, builderDocument: BuilderEmailDocument): ng.IPromise<Html>;
}

interface ParserContext {
    idCount: number;
}

interface SectionProcessor {
    process: (tDoc: Document, parserContext: ParserContext) => Section[];
    unprocess: (tDoc: Document, builderDocument: BuilderEmailDocument) => void;
}

angular.module('app').service(CampaignMonitorTemplateParserSID, [
    $qSID,
    function ($q: angular.IQService): ICampaignMonitorTemplateParser {
        const inspector = new BuilderEmailDocumentInspector();

        function getYPosition(doc: Document, elem: Element): number {
            // Get the global index out of all elements.
            // Assumes that elements that appear later in the markup also appear later visually; i.e. no CSS positioning.
            return $(doc).find('*').index(elem);
        }

        const TOKEN_START_MSO_IF = '[if mso]>';
        const TOKEN_END_MSO_IF = '<![endif]';

        function parseMsoCommentForButton($div: JQuery, parserContext: ParserContext, section: IButtonSection) {
            const comments = $div.contents().filter(function (this: Node) {
                return this.nodeType == 8;
            });
            if (comments.length != 3) {
                throw new Error('Expected 3 comment tags for button: start if, end if, and mso-specific markup.');
            }
            const commentNode = comments[2];
            const msoComment = (commentNode as { data?: string }).data!;
            if (msoComment.lastIndexOf(TOKEN_START_MSO_IF, 0) == -1) {
                throw new Error(`MSO markup does not appear to start with the expected token ${TOKEN_START_MSO_IF}`);
            }
            const endSubstringIndex = msoComment.length - TOKEN_END_MSO_IF.length;
            if (msoComment.indexOf(TOKEN_END_MSO_IF, endSubstringIndex) == -1) {
                throw new Error(`MSO markup does not appear to end with the expected token ${TOKEN_END_MSO_IF}`);
            }

            const markupString = msoComment.substring(TOKEN_START_MSO_IF.length, endSubstringIndex);
            const markup = $(markupString);
            // Outlook buttons use the VML tag "v:roundrect", which allows the "href" attribute. We are a bit more lenient
            //  And just look for any tag with an existing "href" attribute.
            markup.find('[href]').attr('href', `{{ getSectionById(${section.id}).link }}`);
            const textNode = markup.children().children().first(); // Dodgy, brittle. assumes markup of "v:roundrect -> v:textbox -> center"
            textNode.text(`{{ processSimpleText(getSectionById(${section.id}).text) }}`);
            section.msoHtml = markup
                .map((index, el: any) => el.outerHTML || '')
                .toArray()
                .join('');

            commentNode.remove();
            const directiveWrapper = $(`<div mso-comment section-id="${section.id}"></div>`);
            $div.append(directiveWrapper);
        }

        function processButtons(tDoc: Document, parserContext: ParserContext) {
            const buttons: ButtonSection[] = [];
            const buttonCells = $(tDoc).find('.btn');
            buttonCells.each((index, elem) => {
                const section = new ButtonSection(parserContext.idCount++);
                const $div = $(elem);
                const $a = $div.find('a');
                section.title = `Button ${index + 1}`;
                section.y = getYPosition(tDoc, elem);
                section.link = $a.attr('href');
                section.text = $a.text();
                buttons.push(section);

                $a.removeAttr('href');
                $a.attr('ng-href', `{{ getSectionById(${section.id}).link }}`);
                $a.text(`{{ processSimpleText(getSectionById(${section.id}).text) }}`);
                parseMsoCommentForButton($div, parserContext, section);

                $div.attr('ng-if', `isSectionVisible(${section.id})`);
                $div.attr('ng-style', `getSelectionStyle(${section.id})`);
                $div.attr('hoverable-email-section', section.id);
            });
            return buttons;
        }

        function unprocessButtons(tDoc: Document, builderDocument: BuilderEmailDocument) {
            const buttonCells = $(tDoc).find('.btn');
            buttonCells.each((index, elem) => {
                // TODO: convert non-mso comments back into conditional expressions. e.g. "<!--[if !mso]-->" -> "<![if !mso]>"

                const $div = $(elem);
                $div.removeAttr('ng-if ng-style hoverable-email-section');

                const $a = $div.find('a');
                $a.removeAttr('ng-href');
                $a.removeClass('ng-binding');

                const msoCommentWrapper = $div.find('[mso-comment]');
                msoCommentWrapper.replaceWith(msoCommentWrapper.contents());
            });
        }

        function processTexts(tDoc: Document, parserContext: ParserContext) {
            const texts: TextSection[] = [];
            const textCells = $(tDoc).find('p,h1,h2,h3,h4,h5,h6').parent();
            textCells.each((index, elem) => {
                const $div = $(elem);
                const section = new TextSection(parserContext.idCount++);
                section.title = `Text ${index + 1}`;
                section.y = getYPosition(tDoc, elem);
                section.html = $div.html().trim();
                texts.push(section);
                $div.html(
                    `${'<div ng-if="isSectionVisible('}${section.id})" ` +
                        `ng-bind-html="processBoundHtml(getSectionById(${section.id}).html)" ` +
                        `ng-style="getSelectionStyle(${section.id})" ` +
                        `hoverable-email-section="${section.id}">` +
                        '</div>',
                );
            });
            return texts;
        }

        function unprocessTexts(tDoc: Document, builderDocument: BuilderEmailDocument) {
            $(tDoc)
                .find('div[ng-bind-html^="processBoundHtml(getSectionById("]')
                .filter((index, element) => {
                    const $element = $(element);
                    // Extract the section ID from the "ng-bind-html" attribute.
                    // (We don't look at "hoverable-email-section", because those attributes don't exist in templates created before DS-2135.)
                    const attr = $element.attr('ng-bind-html');
                    const sectionIdString = attr.substring(attr.lastIndexOf('(') + 1, attr.indexOf(')'));
                    const sectionId = parseInt(sectionIdString, 10);
                    const section = inspector.getSectionById(builderDocument, sectionId);
                    return !!section && isTextSection(section);
                })
                .each((index, element) => {
                    const $element = $(element);
                    $element.replaceWith($element.contents());
                });
        }

        function processImages(tDoc: Document, parserContext: ParserContext) {
            const images: ImageSection[] = [];
            const imageCells = $(tDoc).find('img');
            imageCells.each((index, img) => {
                const $img = $(img);
                const section = new ImageSection(parserContext.idCount++);
                section.title = `Image ${index + 1}`;
                section.y = getYPosition(tDoc, img);
                section.originalLocation = $img.attr('src');
                section.location = section.originalLocation;
                if (startsWith(section.originalLocation, '//') || startsWith(section.originalLocation, 'http')) {
                    section.locationType = 'external';
                }
                const $parent = $img.parent();
                if ($parent.get(0).tagName == 'A') {
                    section.isLinkable = true;
                    section.link = $parent.attr('href');
                    $parent.attr('ng-href', `{{ getSectionById(${section.id}).link }}`);
                }

                // It's possible the image dimensions may not actually be stored as
                // Width and height attributes on the image element, they may be
                // Specified within a style/class.
                // When we process the actual image files we will get a second shot at
                // Working out the dimensions of any image sections
                section.width = parseInt($img.attr('width'), 10);
                section.height = parseInt($img.attr('height'), 10);

                images.push(section);

                // Attach the section id to the img element (note: can't seem to use
                // Data-section-id as it doesnt get serialised when using outerHTML later? - weird..)
                $img.attr('section-id', section.id);

                $img.removeAttr('src');
                $img.attr('ng-if', `isSectionVisible(${section.id})`);
                $img.attr('ng-src', `{{ getSectionById(${section.id}).location }}`);
                $img.attr('ng-style', `getSelectionStyle(${section.id})`);
                $img.attr('hoverable-email-section', section.id);
            });
            return images;
        }

        function unprocessImages(tDoc: Document, builderDocument: BuilderEmailDocument) {
            const imageCells = $(tDoc).find('img');
            imageCells.each((index, img) => {
                const $img = $(img);
                $img.removeAttr('ng-if ng-src ng-style hoverable-email-section section-id');
                const $parent = $img.parent();
                if ($parent.get(0).tagName === 'A') {
                    $parent.removeAttr('ng-href');
                }
            });
        }

        function extendArray<T>(arrayToExtend: T[], elementsToAdd: T[]) {
            Array.prototype.push.apply(arrayToExtend, elementsToAdd);
        }

        function sortSections(sections: Section[]) {
            sections.sort((a: Section, b: Section) => a.y - b.y);
        }

        const sectionProcessors: SectionProcessor[] = [
            {
                process: processTexts,
                unprocess: unprocessTexts,
            },
            {
                process: processImages,
                unprocess: unprocessImages,
            },
            {
                process: processButtons,
                unprocess: unprocessButtons,
            },
        ];

        /**
         * TDoc is mutated in-place.
         *
         * @param tDoc
         * @returns {Array}
         */
        function processTemplateHtmlDocument(tDoc: Document) {
            const parserContext = {
                idCount: 1,
            };
            const sections: Section[] = [];
            for (const processor of sectionProcessors) {
                extendArray(sections, processor.process(tDoc, parserContext));
            }

            sortSections(sections);
            return sections;
        }

        /**
         * Tries to restore our modified HTML back to something close to the original.
         *
         * @param tDoc
         * @param builderDocument
         */
        function unprocessTemplateHtmlDocument(tDoc: Document, builderDocument: BuilderEmailDocument) {
            for (const processor of sectionProcessors) {
                processor.unprocess(tDoc, builderDocument);
            }
        }

        function convertHtmlStringToDocument(htmlString: string): ng.IPromise<Document> {
            return $q.resolve().then(() => {
                const doc = document.implementation.createHTMLDocument('');
                doc.open();
                doc.write(htmlString);
                doc.close();
                return doc;
            });
        }

        function convertDocumentToHtmlString(doc: Document): Html {
            return Html.from(doc.documentElement.outerHTML);
        }

        function processHtml(htmlString: string) {
            return convertHtmlStringToDocument(htmlString).then(tDoc => {
                const sections = processTemplateHtmlDocument(tDoc);
                return {
                    html: convertDocumentToHtmlString(tDoc),
                    sections,
                } as ICampaignMonitorTemplateParserResult;
            });
        }

        function unprocessHtml(tDoc: HTMLDocument, builderDocument: BuilderEmailDocument) {
            return $q.resolve().then(() => {
                unprocessTemplateHtmlDocument(tDoc, builderDocument);
                return convertDocumentToHtmlString(tDoc);
            });
        }

        return {
            processHtml,
            unprocessHtml,
        };
    },
]);
