import * as React from 'react';
import { maxDate } from '@deltasierra/shared';
import moment from 'moment';
import { DateTimePickerFormInput } from './DateTimePicker';

export type DateTimeRangePickerProps = {
    min?: Date | null;
    onChange: (newValue: { end: Date | null; start: Date | null }) => void;
    showClarificationInTimezone?: string;
    value: { end: Date | null; start: Date | null };
};

export const DateTimeRangePicker: React.FunctionComponent<DateTimeRangePickerProps> = ({ min, onChange, value }) => {
    const { end = value.start ? addMinutes(value.start, 10) : null, start = null } = value;
    const endMin = start ? addMinutes(start, 10) : null;

    const onChangeStart = (newStart: Date | null) => {
        if (newStart && end) {
            onChange({ end: maxDate(addMinutes(newStart, 10), end), start: newStart });
        } else {
            onChange({ end, start: newStart });
        }
    };

    const onChangeEnd = (newEnd: Date | null) => {
        onChange({ end: newEnd, start });
    };

    return (
        <>
            <DateTimePickerFormInput
                label={{ keyId: 'COMMON.START_DATE' }}
                min={min}
                value={start}
                onChange={onChangeStart}
            />
            <DateTimePickerFormInput
                label={{ keyId: 'COMMON.END_DATE' }}
                min={endMin}
                value={end}
                onChange={onChangeEnd}
            />
        </>
    );
};

DateTimeRangePicker.displayName = 'DateTimeRangePicker';

function addMinutes(date: Date, minutes: number): Date {
    return moment(date).add(minutes, 'minutes').toDate();
}
