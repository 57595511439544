import { gql, useApolloClient, useMutation } from '@apollo/client';
import { chunkArray } from '@deltasierra/array-utilities';
import { Upload, protocolRelativeUrlToHttpsUrl } from '@deltasierra/shared';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useState } from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { CreateAssetFiles, CreateAssetFilesVariables } from './__graphqlTypes/CreateAssetFiles';
import { useUploadAssetsState } from './state';

const ASSETS_TO_UPLOAD_IN_BATCH = 5;
const ASSETS_TO_SAVE_IN_BATCH = 20;

const CREATE_ASSET_FILES_MUTATION = gql`
    mutation CreateAssetFiles($input: CreateAssetFileInput!) {
        createAssetFile(input: $input) {
            __typename
            ... on AssetFile {
                id
                title
                thumbnails {
                    medium {
                        url
                    }
                }
                collection {
                    id
                }
                type
            }
        }
    }
`;

type UseUploadAssetsOptions = {
    expires: Date | null;
    fileDispatch: ReturnType<typeof useUploadAssetsState>[1];
    fileState: ReturnType<typeof useUploadAssetsState>[0];
    folderOrCollectionId: string;
    onClose: () => void;
    tags: string[] | null;
};

// eslint-disable-next-line max-lines-per-function
export function useUploadAssets({
    expires,
    fileDispatch,
    fileState,
    folderOrCollectionId,
    onClose,
    tags,
}: UseUploadAssetsOptions): {
    assetsSaving: number;
    assetsUploading: number;
    isUploading: boolean;
    onClickSave: () => void;
    onSelectFiles: (files: File[]) => void;
} {
    const uploadService = useAngularServiceContext('uploadService');
    const apolloClient = useApolloClient();

    const [uploadsToSave, setUploadsToSave] = useState<Upload[]>([]);

    const [createAssetFile, { loading }] = useMutation<CreateAssetFiles, CreateAssetFilesVariables>(
        CREATE_ASSET_FILES_MUTATION,
        {
            update: (cache, result) => {
                if (
                    result.data?.createAssetFile.__typename === 'AssetFile' &&
                    result.data.createAssetFile.collection.id
                ) {
                    cache.modify({
                        fields: {
                            assetCount(cachedValue: number) {
                                return cachedValue + 1;
                            },
                        },
                        id: `Collection:${result.data.createAssetFile.collection.id}`,
                    });
                }
            },
        },
    );

    const onSelectFiles = React.useCallback(
        async (files: File[]) => {
            fileDispatch({ payload: files, type: 'ADD' });

            // Chunk the upload into a batch of X at a time
            const batches = chunkArray(files, ASSETS_TO_UPLOAD_IN_BATCH);
            for (const batch of batches) {
                // Await the entire chunk of promises first before starting the next batch
                // eslint-disable-next-line no-await-in-loop
                await Promise.all(
                    batch.map(async file => {
                        // This catch makes every batch safe to run
                        try {
                            const [upload] = await Promise.all(
                                uploadService.upload([file], 'assetLibrary', [], {}, { suppressNotifications: true }),
                            );

                            fileDispatch({ payload: [{ file, upload }], type: 'COMPLETED' });
                        } catch (error) {
                            Sentry.captureException(error);
                            fileDispatch({ payload: [file], type: 'FAILED' });
                        }
                    }),
                );
            }
        },
        [uploadService, fileDispatch],
    );

    const onClickSave = useCallback(async () => {
        try {
            const uploadsToBatch = fileState.completed.map(({ upload }) => upload);
            setUploadsToSave(uploadsToBatch);
            // Chunk the upload into a batch of X at a time
            const batches = chunkArray(uploadsToBatch, ASSETS_TO_SAVE_IN_BATCH);
            for (const batch of batches) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    await Promise.all(
                        batch.map(async upload =>
                            createAssetFile({
                                variables: {
                                    input: {
                                        expires: expires?.toISOString(),
                                        folderOrCollectionId,
                                        sizeInBytes: upload.size ?? 0,
                                        tags: tags ?? [],
                                        title: upload.filename,
                                        url: protocolRelativeUrlToHttpsUrl(upload.url!),
                                    },
                                },
                            }),
                        ),
                    );
                } finally {
                    setUploadsToSave(oldUploadsToSave =>
                        oldUploadsToSave.filter(oldUpload => !batch.some(bUpload => bUpload.id === oldUpload.id)),
                    );
                }
            }

            // Finally at the end refetch the collection assets, don't await this
            void apolloClient.refetchQueries({
                include: ['GetCollectionForCollectionAssets'],
            });

            onClose();
        } finally {
            setUploadsToSave([]);
        }
    }, [apolloClient, createAssetFile, expires, fileState.completed, folderOrCollectionId, onClose, tags]);

    return {
        assetsSaving: uploadsToSave.length,
        assetsUploading: fileState.processing.length,
        isUploading: uploadsToSave.length > 0 || loading,
        onClickSave,
        onSelectFiles,
    };
}
