import { DSGrid } from '@deltasierra/components';
import { filterUnique } from '@deltasierra/array-utilities';
import * as React from 'react';
import styled from 'styled-components';
import { HashtagsInput, HashtagsInputProps } from '../../../../common/HashtagsInput';
import { HashtagSetsDropdownButton } from '../HashtagSetsDropdown';

const WithGutters = styled.div<{ $gutterBottom?: boolean }>`
    ${props => props.$gutterBottom && `margin-bottom: ${props.theme.spacing(2)}px;`}
    max-width: 100%;
`;

const StyledHelperText = styled.div`
    font-size: ${props => props.theme.typography.body1.fontSize};
`;

export type HashtagsInputWithSelectProps = HashtagsInputProps & {
    locationId: string;
    maxHashtags?: number;
    maxLength?: number;
    gutterBottom?: boolean;
};

export function HashtagsInputWithHashtagSetsDropdown({
    gutterBottom,
    helperText,
    locationId,
    maxHashtags = Infinity,
    maxLength = Infinity,
    onChange,
    value,
    ...inputProps
}: HashtagsInputWithSelectProps): JSX.Element {
    const anchorRef = React.useRef<HTMLElement | null>(null);

    const shouldDisableHashtagSet = (hashtags: ReadonlyArray<string>) =>
        value.join().length + hashtags.join().length > maxLength || value.length + hashtags.length > maxHashtags;

    const handleChange = React.useCallback(
        (newValue: string[]) => {
            onChange?.(newValue.filter(filterUnique()));
        },
        [onChange],
    );

    const onAdd = (hashtags: string[]) => {
        // Add all the new hashtags and filter all the hashtags so theres no duplicates
        handleChange([...value, ...hashtags]);
    };

    return (
        <WithGutters $gutterBottom={gutterBottom}>
            <HashtagsInput
                {...inputProps}
                helperText={
                    <StyledHelperText>
                        <DSGrid container>
                            <DSGrid item xs>
                                {helperText}
                            </DSGrid>
                            <DSGrid item xs="auto">
                                <HashtagSetsDropdownButton
                                    disabledCheck={shouldDisableHashtagSet}
                                    existingHashtags={value}
                                    locationId={locationId}
                                    popperAnchorEl={anchorRef.current}
                                    onAdd={onAdd}
                                />
                            </DSGrid>
                        </DSGrid>
                    </StyledHelperText>
                }
                innerRef={anchorRef}
                value={value}
                onChange={handleChange}
            />
        </WithGutters>
    );
}
HashtagsInputWithHashtagSetsDropdown.displayName = 'HashtagsInputWithHashtagSetsDropdown';
