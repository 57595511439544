import * as React from 'react';
import { SimpleForm } from '@deltasierra/components';
import { FormikErrors, FormikTouched } from 'formik';
import { t } from '@deltasierra/shared';
import FieldGroup from '../../common/form/FieldGroup';
import { FormValues } from './LocationDetailsModalFormikWrapper';

export type LocationDetailsModalFormProps = {
    errors: FormikErrors<FormValues>;
    isValid: boolean;
    submitForm: () => void;
    touched: FormikTouched<FormValues>;
};

const LocationDetailsModalForm: React.FC<LocationDetailsModalFormProps> = ({ errors, isValid, submitForm, touched }) => (
    <SimpleForm direction={'horizontal'} isValid={isValid} onSubmit={submitForm}>
        <p style={{ paddingLeft: '10px', paddingRight: '10px' }}>{t('BUILD.LOCATION_DETAILS_MISSING_MESSAGE')}</p>
        <hr className="hr-primary" />
        <FieldGroup
            error={errors.phoneNumber}
            label={`${t('ACCOUNT.DETAILS.LOCATION_PHONE_NUMBER')}*:`}
            name="phoneNumber"
            touched={touched.phoneNumber}
        />
        <FieldGroup
            error={errors.locationEmail}
            label={`${t('ACCOUNT.DETAILS.LOCATION_EMAIL')}*:`}
            name="locationEmail"
            touched={touched.locationEmail}
        />
        <FieldGroup
            error={errors.websiteUrl}
            label={`${t('ACCOUNT.DETAILS.WEBSITE_URL')}*:`}
            name="websiteUrl"
            touched={touched.websiteUrl}
        />
        <br />
        <FieldGroup
            error={errors.addressLineOne}
            label={`${t('ACCOUNT.DETAILS.ADDRESS_LINE_ONE')}*:`}
            name="addressLineOne"
            touched={touched.addressLineOne}
        />
        <FieldGroup
            error={errors.addressLineTwo}
            label={`${t('ACCOUNT.DETAILS.ADDRESS_LINE_TWO')}:`}
            name="addressLineTwo"
            touched={touched.addressLineTwo}
        />
        <FieldGroup
            error={errors.addressLineThree}
            label={`${t('ACCOUNT.DETAILS.ADDRESS_LINE_THREE')}:`}
            name="addressLineThree"
            touched={touched.addressLineThree}
        />
    </SimpleForm>
);
LocationDetailsModalForm.displayName = 'LocationDetailsModalForm';

export default LocationDetailsModalForm;
