/// <reference path="../../../../../typings/browser.d.ts" />

import { OneWayBinding, RestrictToAttribute } from '../../../common/angularData';

export const emailEditSourceIframeSID = 'emailEditSourceIframe';
export const emailEditSourceIframeConfig: ng.IDirective<ng.IScope> = {
    restrict: RestrictToAttribute,
    scope: {
        [emailEditSourceIframeSID]: OneWayBinding,
    },
};

interface EmailEditSourceIframeScope extends ng.IScope {
    [emailEditSourceIframeSID]: HTMLDocument | string;
}

angular.module('app').directive(emailEditSourceIframeSID, [function (): ng.IDirective<EmailEditSourceIframeScope> {
    return {
        ...emailEditSourceIframeConfig,
        link(scope: EmailEditSourceIframeScope, element: ng.IAugmentedJQuery) {
            function renderTemplate() {
                const iframeElement: HTMLIFrameElement = element[0] as HTMLIFrameElement;
                const outputDoc = iframeElement.contentWindow!.document as HTMLDocument;
                let inputDoc = scope[emailEditSourceIframeSID];
                if (inputDoc && typeof inputDoc !== 'string') {
                    inputDoc = inputDoc.documentElement.outerHTML;
                }
                outputDoc.open();
                outputDoc.write((inputDoc as string) || '');
                outputDoc.close();
            }

            scope.$watch(() => scope[emailEditSourceIframeSID], renderTemplate);

            renderTemplate();
        },
    };
}]);
