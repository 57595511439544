import { DSButton } from '@deltasierra/components';
import { useToggle } from '@deltasierra/react-hooks';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { BrandDialog } from './BrandDialog';
import { BrandForDialog } from './__graphqlTypes/BrandForDialog';

export type BrandButtonProps = {
    brand?: BrandForDialog;
    disabled?: boolean;
    render?: (params: { toggle: (toggle: 'toggle' | 'toggleOff' | 'toggleOn') => void }) => React.ReactNode;
};

export const BrandButton: React.FC<BrandButtonProps> = props => {
    const [isOpen, toggle] = useToggle();

    return (
        <>
            {props.render ? props.render({ toggle }) : <DefaultBrandButton disabled={props.disabled} onClick={() => toggle('toggle')} />}
            {isOpen && (
                <BrandDialog
                    brand={props.brand}
                    isOpen={isOpen}
                    onClose={() => toggle('toggleOff')}
                    onConfirm={() => toggle('toggleOff')}
                />
            )}
        </>
    );
};
BrandButton.displayName = 'BrandButton';

const DefaultBrandButton: React.FC<{ disabled?: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
    <DSButton color="success" disabled={disabled} size="small" onClick={onClick}>
        {`+ ${t('ADMIN.BRANDS.NEW_BRAND')}`}
    </DSButton>
);
DefaultBrandButton.displayName = 'DefaultBrandButton';
