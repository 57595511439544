import { gql } from '@apollo/client';

export const GET_LOCATION_FEATURES_QUERY = gql`
    query GetLocationFeatures($locationId: ID!) {
        location(id: $locationId) {
            id
            features {
                showOldEmailTemplatesOption
                templateGalleryV2
            }
        }
    }
`;
