import * as React from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { noop } from '@deltasierra/object-utilities';
import { StyledLeftSpan } from '../../../integration/components/Styles/Styles';
import { PlatformLogo } from '../PlatformLogo';
import { PlatformPickerDropdownItemFragment } from './__graphqlTypes/PlatformPickerDropdownItemFragment';

const ItemWrapper = styled.div`
    height: 2.5rem;
    padding: 0.3125rem;
    width: 100%;
    cursor: pointer;
    color: #333;

    &:hover {
        background-color: #ddd;
    }

    &:active {
        box-shadow: 3px 3px 10px #bbb inset;
    }
`;

const fragments = {
    platform: gql`
        fragment PlatformPickerDropdownItemFragment on Platform {
            id
            name
            displayName
        }
    `,
};

export type PlatformPickerDropdownItemProps = {
    onClick?: (platform: PlatformPickerDropdownItemFragment) => void;
    platform: PlatformPickerDropdownItemFragment;
};

export const PlatformPickerDropdownItem: React.FunctionComponent<PlatformPickerDropdownItemProps> & {
    fragments: typeof fragments;
} = ({ onClick = noop, platform }) => (
    <ItemWrapper onClick={() => onClick(platform)}>
        <StyledLeftSpan className="text-left" style={{ height: '100%' }}>
            <PlatformLogo name={platform.name} />
        </StyledLeftSpan>
        <StyledLeftSpan style={{ color: '#666666', paddingLeft: '0.625rem', paddingTop: '.2rem' }}>
            {platform.displayName}
        </StyledLeftSpan>
    </ItemWrapper>
);

PlatformPickerDropdownItem.displayName = 'PlatformPickerDropdownItem';
PlatformPickerDropdownItem.fragments = fragments;
