import * as React from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { DSButton } from '@deltasierra/components';
import { PlatformLogo } from '../PlatformLogo';
import { PlatformPickerButtonFragment } from './__graphqlTypes/PlatformPickerButtonFragment';

const fragments = {
    platform: gql`
        fragment PlatformPickerButtonFragment on Platform {
            id
            name
            displayName
        }
    `,
};

const PlatformName = styled.div`
    padding-left: 0.625rem;
    margin-right: auto;
    font-family: GalanoGrotesque, sans-serif;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledButton = styled(DSButton)`
    padding: 0.4rem 1rem;
    color: ${({ theme }) => theme.palette.primary.main};
`;

export type PlatformPickerButtonProps = {
    onClick: () => void;
    platform: PlatformPickerButtonFragment;
};

export const PlatformPickerButton: React.FunctionComponent<PlatformPickerButtonProps> & {
    fragments: typeof fragments;
} = ({ onClick, platform }) => (
    <StyledButton color="primary" fullWidth variant="outlined" onClick={onClick}>
        <PlatformLogo name={platform.name} />
        <PlatformName>{platform.displayName}</PlatformName>
        <i className="fas fa-arrows-alt-v" />
    </StyledButton>
);

PlatformPickerButton.fragments = fragments;
PlatformPickerButton.displayName = 'PlatformPickerButton';
