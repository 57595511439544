import {
    IInstagramPublishData,
    InstagramDirectPublishFormData,
    InstagramPublishApi,
    IPublishVideoRequest,
    LocationId,
    paramsAndBody,
    PreparedVideoRequestData,
} from '@deltasierra/shared';
import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { invokeApiRoute } from '../../../common/httpUtils';
import { useCanPostToInstagramForLocation } from '../common/hooks';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { PublishCallback } from '../types';

const usePublishVideoCallback = () => {
    const http = useAngularServiceContext('$http');
    return React.useCallback(
        (locationId: LocationId, requestBody: IPublishVideoRequest<IInstagramPublishData>) =>
            invokeApiRoute(
                http,
                InstagramPublishApi.publishVideoDirect,
                paramsAndBody(
                    {
                        locationId,
                    },
                    requestBody,
                ),
            ),
        [http],
    );
};

export const useInstagramVideoPublishCallback = (
    publishController: MvContentBuilderPublishCtrl,
    videoData: PreparedVideoRequestData | null,
): PublishCallback<InstagramDirectPublishFormData> => {
    const videoPublishService = useAngularServiceContext('VideoPublishService');
    const i18n = useAngularServiceContext('I18nService');
    const identity = useAngularServiceContext('mvIdentity');
    const canPostToLocation = useCanPostToInstagramForLocation();
    const publishVideoCallback = usePublishVideoCallback();
    const currentUser = identity.currentUser;

    return React.useCallback<PublishCallback<InstagramDirectPublishFormData>>(
        async ({ commonData, locationId, publishedArtifactGroupId, scheduledPublishGroupId, uploadId }) => {
            if (!videoData) {
                // This shouldn't happen
                throw new Error('Video data has not been set.');
            }
            const canPostToLocationResult = await canPostToLocation(locationId);
            if (currentUser && canPostToLocationResult) {
                await videoPublishService.publishVideo<IInstagramPublishData>({
                    channelData: {
                        assignedUserId: currentUser.id,
                        notificationPhoneNumber: null,
                        ...commonData,
                    },
                    linkedAssetLibraryAssetIds: publishController.contentBuilder.linkedAssetLibraryAsset.map(value => ({
                        assetId: value.asset.id,
                        layerId: value.layerId,
                    })),
                    plannerDetails: publishController.plannerDetails || null,
                    publishCallback: request => publishVideoCallback(locationId, request),
                    publishedArtifactGroupId,
                    scheduledPublishGroupId,
                    templateId: publishController.templateId,
                    videoData: {
                        ...videoData,
                        image: uploadId,
                    },
                });
            } else {
                throw new Error(i18n.text.build.publish.instagram.accessCheckFailure());
            }
        },
        [
            canPostToLocation,
            currentUser,
            i18n.text.build.publish.instagram,
            publishController.contentBuilder.linkedAssetLibraryAsset,
            publishController.plannerDetails,
            publishController.templateId,
            publishVideoCallback,
            videoData,
            videoPublishService,
        ],
    );
};
