import * as React from 'react';
import { Translate, Dropdown, SvgLoader } from '@deltasierra/components';
import { gql } from '@apollo/client';
import { PlatformPickerButton } from './PlatformPickerButton';
import { PlatformPickerDropdownItem } from './PlatformPickerDropdownItem';
import { PlatformPickerFragment } from './__graphqlTypes/PlatformPickerFragment';

export type PlatformPickerProps = {
    /**
     * The name of the platform that should be currently selected
     */
    platform?: PlatformPickerFragment | null;

    platforms: PlatformPickerFragment[];

    loading?: boolean;

    /**
     * Callback when the user selects a different platform
     *
     * @param platform - The name of the platform the user has selected
     */
    onChange: (platform: string) => void;

    /**
     * Should we default to the first platform in the list? Or should we show a "please select" message?
     *
     * @default false
     */
    defaultToFirst?: boolean;

    /**
     * Display a filter search box
     *
     * @default false
     */
    filter?: boolean;
};

const fragments = {

    platform: gql`
        fragment PlatformPickerFragment on Platform {
            __typename
            id
            ...PlatformPickerButtonFragment
            ...PlatformPickerDropdownItemFragment
        }
        ${PlatformPickerButton.fragments.platform}
        ${PlatformPickerDropdownItem.fragments.platform}
    `,
};

export const PlatformPicker: React.FunctionComponent<PlatformPickerProps> & { fragments: typeof fragments } = ({
    defaultToFirst = false,
    filter = false,
    loading = false,
    onChange,
    platform = null,
    platforms,
}) => {
    const onChangeRef = React.useRef<typeof onChange>(onChange);
    onChangeRef.current = onChange;

    const [platformFilter, setPlatformFilter] = React.useState('');

    const filteredItems = platforms.filter(
        platformItem => platformItem.displayName.toLowerCase().indexOf(platformFilter.toLowerCase()) > -1,
    );

    return (
        <SvgLoader loading={loading} size="small">
            <Dropdown
                filterable={filter}
                getOptionTemplate={platformItem => <PlatformPickerDropdownItem key={platformItem.id} platform={platformItem} />}
                getValue={platformItem => platformItem.id}
                items={filteredItems}
                menuStyle={{ maxHeight: '25vh', overflowY: 'scroll', width: '100%' }}
                render={({ requestToggle }) => (
                    <>
                        {!platform && !defaultToFirst && (
                            <div className="btn btn-default" style={{ height: '3rem', width: '100%' }} onClick={() => requestToggle()}>
                                <Translate keyId="COMMON.SELECT_AN_OPTION" />
                            </div>
                        )}
                        {platform && <PlatformPickerButton platform={platform} onClick={requestToggle} />}
                    </>
                )}
                style={{ marginBottom: '0.625rem' }}
                value={null}
                onFilterChange={setPlatformFilter}
                onSelect={selected => {
                    onChange(selected.name);
                }}
            />
        </SvgLoader>
    );
};

PlatformPicker.fragments = fragments;
