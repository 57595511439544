import * as React from 'react';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding } from '../../common/angularData';
import { Translate } from '../../directives/Translate';

export type ReportSummaryProps = {
    text: string;
};

const ReportSummary: React.FunctionComponent<ReportSummaryProps> = ({ text }) => {
    const [showSummary, setShowSummary] = React.useState(false);
    return (
        <div className="read-more-container">
            <a className="btn btn-xs btn-default" href="#" onClick={() => setShowSummary(!showSummary)}>
                {showSummary ? <Translate keyId="REPORT.SHOW_SUMMARY" /> : <Translate keyId="REPORT.HIDE_SUMMARY" />}
            </a>
            <div className={`read-more-content-container ${showSummary ? 'collapsed' : ''}`}>
                <p className="preserve-newlines">{text}</p>
            </div>
        </div>
    );
};

export default withAngularIntegration(ReportSummary, 'reportSummary', { text: OneWayBinding });
