/// <reference path="../../../typings/browser.d.ts" />
import { Client, Untyped } from '@deltasierra/shared';

export const mvClientResourceSID = 'mvClientResource';

export interface ClientResource extends ng.resource.IResource<ClientResource>, Client {}

export interface MvClientResource extends ng.resource.IResourceClass<ClientResource> {
    update(client: Untyped): Client;
}

const factory = function ($resource: ng.resource.IResourceService): MvClientResource {
    const resource = $resource<ClientResource>(
        '/api/clients/:id',
        { id: '@id' },
        {
            // Tslint:disable-line:variable-name
            update: {
                method: 'PUT',
                isArray: false,
            },
        },
    ) as MvClientResource;

    return resource;
};

angular.module('app').factory(mvClientResourceSID, ['$resource', factory]);
