/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The status of the third-party account. Is it still connected to the third-party? Has it expired?
 */
export enum AppAuthAccountStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

/**
 * This is additional info flags we might return about an app connection
 */
export enum AppConnectionAdditionalInfoFlag {
  FACEBOOK_ACCESS_TOKEN_ISSUE = "FACEBOOK_ACCESS_TOKEN_ISSUE",
  FACEBOOK_INVALID_SESSION = "FACEBOOK_INVALID_SESSION",
  FACEBOOK_PASSWORD_CHANGED_OR_SECURITY_INVALIDATED = "FACEBOOK_PASSWORD_CHANGED_OR_SECURITY_INVALIDATED",
}

/**
 * Represents the current status of a connection to a third part service (such as a Facebook Integration). This is useful for checking if the connection to the service is valid. A connection could be in a disconnected state because the connection has expired, the user changed their password on the external service, or the connection was terminated from the other side (ie: deauthorizing an app from within FB).
 */
export enum AppConnectionStatus {
  CONFIGURATION_ERROR = "CONFIGURATION_ERROR",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOT_CONFIGURED = "NOT_CONFIGURED",
  NOT_CONNECTED = "NOT_CONNECTED",
}

/**
 * Asset library permissions for user operations
 */
export enum AssetPermission {
  DELETE = "DELETE",
  READ = "READ",
  UPDATE = "UPDATE",
}

/**
 * Sortable columns for assets
 */
export enum AssetSortColumn {
  DATEADDED = "DATEADDED",
  EXPIRY = "EXPIRY",
  FILESIZE = "FILESIZE",
  NAME = "NAME",
}

/**
 * The type of period choice (before/after a date)
 */
export enum BeforeAfterPeriodType {
  After = "After",
  Before = "Before",
}

export enum BuildableTemplateStatus {
  archived = "archived",
  published = "published",
  unpublished = "unpublished",
}

export enum BuildableTemplateType {
  email = "email",
  image = "image",
  print = "print",
  video = "video",
}

export enum BuilderDocumentFormat {
  content = "content",
  email = "email",
  print = "print",
  video = "video",
}

export enum CampaignMonitorEmailGeneratedReportsRequestsStatus {
  errored = "errored",
  in_progress = "in_progress",
  requested = "requested",
  succeeded = "succeeded",
}

export enum CampaignRolloutStatus {
  Active = "Active",
  Completed = "Completed",
  Failed = "Failed",
  RollingOut = "RollingOut",
  Scheduled = "Scheduled",
}

export enum ClientReportLocationEngagementSortType {
  builtItems = "builtItems",
  title = "title",
}

export enum ClientUsersSortType {
  firstName = "firstName",
  lastActivity = "lastActivity",
  lastName = "lastName",
  username = "username",
}

export enum ClubReadyListSummaryRequestStatus {
  errored = "errored",
  requested = "requested",
  running = "running",
  succeeded = "succeeded",
}

/**
 * Enum for the scheduledWorkflowType property of a ClubReady post artifact's meta
 */
export enum ClubReadyPostArtifactScheduledWorkflowType {
  LEGACY = "LEGACY",
  SCHEDULE_SERVICE = "SCHEDULE_SERVICE",
}

/**
 * The type of asset
 */
export enum FileType {
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum GetClientsSortType {
  numLocations = "numLocations",
  title = "title",
}

/**
 * The match type for the keyword
 */
export enum GoogleAdsKeywordReportMatchType {
  BROAD = "BROAD",
  EXACT = "EXACT",
  PHRASE = "PHRASE",
  UNKNOWN = "UNKNOWN",
}

/**
 * The status for the keyword
 */
export enum GoogleAdsKeywordReportStatus {
  ENABLED = "ENABLED",
  PAUSED = "PAUSED",
  REMOVED = "REMOVED",
  UNKNOWN = "UNKNOWN",
}

export enum GoogleMyBusinessReportAuthorType {
  AUTHOR_TYPE_UNSPECIFIED = "AUTHOR_TYPE_UNSPECIFIED",
  LOCAL_GUIDE = "LOCAL_GUIDE",
  MERCHANT = "MERCHANT",
  REGULAR_USER = "REGULAR_USER",
}

/**
 * State of a Job
 */
export enum JobState {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
}

/**
 * The group type of the location category option
 */
export enum LocationCategoryGroupEnum {
  GROUP = "GROUP",
  REGION = "REGION",
}

export enum MarkScheduledPublishesMarkType {
  failed = "failed",
  success = "success",
}

export enum PlatformAction {
  BUILDING = "BUILDING",
  PUBLISHING = "PUBLISHING",
  REPORTING = "REPORTING",
}

/**
 * Status of a PostArtifact
 */
export enum PostArtifactState {
  DELETED = "DELETED",
  POSTED = "POSTED",
  SCHEDULED = "SCHEDULED",
}

/**
 * A users roles
 */
export enum Roles {
  admin = "admin",
  analyst = "analyst",
  backdoor = "backdoor",
  builder = "builder",
  client = "client",
  connectToAccess = "connectToAccess",
  manager = "manager",
  planner = "planner",
  publisher = "publisher",
  specialRequester = "specialRequester",
  test = "test",
}

export enum SavedEmailPartType {
  footer = "footer",
  header = "header",
}

export enum ScheduledPublishesSchedulingStatus {
  failed = "failed",
  pending = "pending",
  success = "success",
}

export enum ScheduledPublishesStatus {
  blocked = "blocked",
  cancelled = "cancelled",
  created = "created",
  failed = "failed",
  running = "running",
  success = "success",
}

export enum SendgridEmailGeneratedReportsRequestsStatus {
  errored = "errored",
  in_progress = "in_progress",
  requested = "requested",
  succeeded = "succeeded",
}

/**
 * Column sort direction
 */
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubChannel {
  DIRECT = "DIRECT",
  MOBILE_APP = "MOBILE_APP",
}

export enum SuppressedEmailsReportsRequestsStatus {
  errored = "errored",
  in_progress = "in_progress",
  requested = "requested",
  succeeded = "succeeded",
}

/**
 * The type of match to use for tag filtering
 */
export enum TagMatchType {
  exact = "exact",
  partial = "partial",
}

export enum TemplateUsageReportsRequestsStatus {
  errored = "errored",
  in_progress = "in_progress",
  requested = "requested",
  succeeded = "succeeded",
}

export interface AddCampaignPlannerEventInput {
  readonly campaignId: string;
  readonly title: string;
  readonly channelId: string;
  readonly day: number;
  readonly instructions: string;
  readonly uploadIds: ReadonlyArray<string>;
  readonly contentSupplied: boolean;
  readonly suggestedBuildableTemplateId?: string | null;
  readonly suggestedBuilderTemplateCategoryId?: string | null;
  readonly suggestedBuilderTemplateId?: string | null;
  readonly suggestedCollectionId?: string | null;
}

export interface AdminClientsFilterInput {
  readonly title?: string | null;
}

export interface AgencyClientsFilterInput {
  readonly title?: string | null;
}

export interface AgencyNotificationUserReportsFilterInput {
  readonly name?: string | null;
  readonly email?: string | null;
}

export interface AgencyUsersFilterInput {
  readonly username?: string | null;
}

export interface AssetIdAndLayerIdInput {
  readonly assetId: number;
  readonly layerId: number;
}

export interface AssetIdAndLayerIdPublishInput {
  readonly legacyAssetId: number;
  readonly layerId: number;
}

export interface AssignBrandInput {
  readonly clientId: string;
  readonly brandId: string;
}

export interface BrandClientsFilterInput {
  readonly agencyId?: string | null;
  readonly title?: string | null;
}

export interface BrandClientsInput {
  readonly filter?: BrandClientsFilterInput | null;
  readonly sort?: GetClientsSortType | null;
}

export interface BuildableTemplateFilterInput {
  readonly status?: BuildableTemplateStatus | null;
  readonly statuses?: ReadonlyArray<BuildableTemplateStatus> | null;
  readonly categories?: ReadonlyArray<number> | null;
  readonly tags?: ReadonlyArray<TagFilterInput> | null;
  readonly onlySavedContentBuilds?: boolean | null;
  readonly locationId?: number | null;
}

export interface BuilderTemplateQueryInput {
  readonly formats?: ReadonlyArray<number> | null;
  readonly categories?: ReadonlyArray<number> | null;
  readonly uncategorised?: boolean | null;
  readonly recent?: boolean | null;
  readonly client?: number | null;
  readonly location?: number | null;
  readonly exactTag?: string | null;
  readonly tags?: string | null;
  readonly limit?: number | null;
  readonly previousDate?: DateTime | null;
  readonly previousId?: number | null;
  readonly platforms?: ReadonlyArray<number> | null;
  readonly editableFields?: EditableFieldsFilterSchema | null;
  readonly onlyLocationDraft?: boolean | null;
  readonly hideDraft?: boolean | null;
  readonly hidePublished?: boolean | null;
  readonly mobile?: boolean | null;
  readonly multiImage?: boolean | null;
}

export interface CampaignMonitorLoctationReportRecipientFilterInput {
  readonly name?: string | null;
  readonly email?: string | null;
}

export interface CampaignRolloutsConnectionFilterInput {
  readonly locationName?: string | null;
  readonly status?: ReadonlyArray<CampaignRolloutStatus> | null;
}

export interface ClientReportLocationEngagementFilterInput {
  readonly locationCategoryOptionId?: string | null;
  readonly since?: DateTime | null;
  readonly title?: string | null;
  readonly until?: DateTime | null;
}

export interface ClientReportLocationEngagementSortInput {
  readonly field: ClientReportLocationEngagementSortType;
  readonly direction: SortDirection;
}

export interface ClientUsersFilterInput {
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly username?: string | null;
  readonly anyFields?: string | null;
}

export interface ClientUsersSortInput {
  readonly field: ClientUsersSortType;
  readonly direction: SortDirection;
}

export interface ClubReadyActiveListFilter {
  readonly activityPeriod?: ClubReadyBeforeAfterDateOption | null;
}

export interface ClubReadyBeforeAfterDateOption {
  readonly period: BeforeAfterPeriodType;
  readonly date: DateTime;
}

export interface ClubReadyCustomListFilter {
  readonly emailListUploadId: string;
  readonly listDescription?: string | null;
}

export interface ClubReadyDateRangeFilterOption {
  readonly from: DateTime;
  readonly to: DateTime;
}

export interface ClubReadyInactiveListFilter {
  readonly activityPeriod?: ClubReadyBeforeAfterDateOption | null;
}

export interface ClubReadyIntegrationListsActive {
  readonly fetch: boolean;
  readonly options?: ClubReadyActiveListFilter | null;
}

export interface ClubReadyIntegrationListsCooledOffProspects {
  readonly fetch: boolean;
}

export interface ClubReadyIntegrationListsCustom {
  readonly fetch: boolean;
  readonly options?: ClubReadyCustomListFilter | null;
}

export interface ClubReadyIntegrationListsInactive {
  readonly fetch: boolean;
  readonly options?: ClubReadyInactiveListFilter | null;
}

export interface ClubReadyIntegrationListsProspects {
  readonly fetch: boolean;
  readonly options?: ClubReadyProspectsListFilter | null;
}

export interface ClubReadyProspectsListFilter {
  readonly addedBetweenRange?: ClubReadyDateRangeFilterOption | null;
}

export interface CopyBuilderTemplateInput {
  readonly clientIds: ReadonlyArray<string>;
  readonly builderTemplateId: string;
  readonly includeCategories: boolean;
  readonly isCopyAsUnpublished?: boolean | null;
}

export interface CreateActivityInput {
  readonly clientId: string;
  readonly label: string;
}

export interface CreateAssetFileInput {
  readonly expires?: DateTime | null;
  readonly folderOrCollectionId: string;
  readonly title: string;
  readonly sizeInBytes: number;
  readonly tags: ReadonlyArray<string>;
  readonly url: string;
}

export interface CreateAssetFolderInput {
  readonly expires?: DateTime | null;
  readonly folderOrCollectionId: string;
  readonly title: string;
}

export interface CreateBrandInput {
  readonly title: string;
  readonly color?: string | null;
  readonly logoId?: number | null;
}

export interface CreateCampaignInput {
  readonly title: string;
  readonly clientId: string;
}

export interface CreateChannelInput {
  readonly label: string;
  readonly isSelfService: boolean;
  readonly isSupported: boolean;
  readonly activityId: string;
  readonly platformId?: string | null;
}

export interface CreateClientHashtagSetInput {
  readonly clientId: string;
  readonly title: string;
  readonly description: string;
  readonly isCore: boolean;
  readonly hashtags: ReadonlyArray<string>;
}

export interface CreateClientInput {
  readonly title: string;
  readonly country: string;
}

export interface CreateCollectionInput {
  readonly title: string;
  readonly permissionLevel: string;
  readonly sharedWithIds: ReadonlyArray<string>;
}

export interface CreateEmailTemplateInput {
  readonly clientId: string;
  readonly title: string;
}

export interface CreateLocationHashtagSetInput {
  readonly locationId: string;
  readonly title: string;
  readonly description: string;
  readonly hashtags: ReadonlyArray<string>;
}

export interface CreateLocationUserInput {
  readonly agencyId: string;
  readonly username: string;
  readonly timezone: string;
  readonly localeCode: string;
  readonly roles: ReadonlyArray<Roles>;
  readonly guidedTourEnabled: boolean;
}

export interface CreateMailchimpCampaignInput {
  readonly builderTemplateId: number;
  readonly plannerId?: number | null;
  readonly linkedAssetLibraryAssetIds: ReadonlyArray<AssetIdAndLayerIdInput>;
  readonly name: string;
  readonly subject: string;
  readonly fromName: string;
  readonly replyTo: string;
  readonly listId: string;
  readonly segmentId?: number | null;
  readonly html: string;
}

export interface CreateScheduledPublishGroupInput {
  readonly builderTemplateId: string;
}

export interface CreateTemplateCategoryInput {
  readonly clientId: string;
  readonly label: string;
  readonly startDate: DateTime;
  readonly endDate?: DateTime | null;
}

export interface CreateTryThisSectionInput {
  readonly clientId: string;
  readonly title: string;
}

export interface CreateTryThisSubSectionInput {
  readonly tryThisSectionId: string;
  readonly title: string;
  readonly tag: string;
}

export interface DeleteActivityInput {
  readonly activityId: string;
}

export interface DeleteAssetsInput {
  readonly ids: ReadonlyArray<string>;
}

export interface DeleteCampaignPlannerEventInput {
  readonly id: string;
}

export interface DeleteChannelInput {
  readonly channelId: string;
}

export interface DeleteCollectionsInput {
  readonly ids: ReadonlyArray<string>;
}

export interface DeleteCustomFontInput {
  readonly id: string;
}

export interface DeleteLocationDraftsInput {
  readonly locationDraftIds: ReadonlyArray<string>;
}

export interface DeletePostArtifactGroupInput {
  readonly postArtifactGroupId: string;
}

export interface DeletePostArtifactsInput {
  readonly postArtifactIds: ReadonlyArray<string>;
}

export interface DeleteTemplateCategoryInput {
  readonly clientId: string;
  readonly templateCategoryId: string;
}

export interface DeleteTemplateInput {
  readonly ids?: ReadonlyArray<string> | null;
  readonly id?: string | null;
}

export interface DeleteUsersInput {
  readonly userIds: ReadonlyArray<string>;
}

export interface DuplicateTemplateInput {
  readonly id: string;
  readonly clientId: string;
  readonly copyCategories?: boolean | null;
  readonly isCopyAsUnpublished?: boolean | null;
}

export interface EditAssetInput {
  readonly id: string;
  readonly folderOrCollectionId?: string | null;
  readonly title?: string | null;
  readonly fileName?: string | null;
  readonly tags?: ReadonlyArray<string> | null;
  readonly expires?: DateTime | null;
}

export interface EditAssetsBulkInput {
  readonly assets: ReadonlyArray<EditAssetInput>;
}

export interface EditCampaignPlannerEventInput {
  readonly id: string;
  readonly title?: string | null;
  readonly channelId?: string | null;
  readonly day?: number | null;
  readonly instructions?: string | null;
  readonly uploadIds?: ReadonlyArray<string> | null;
  readonly contentSupplied?: boolean | null;
  readonly suggestedBuilderTemplateCategoryId?: string | null;
  readonly suggestedBuilderTemplateId?: string | null;
  readonly suggestedBuildableTemplateId?: string | null;
  readonly suggestedCollectionId?: string | null;
}

export interface EditClientHashtagSetInput {
  readonly id: string;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly isCore?: boolean | null;
  readonly hashtags?: ReadonlyArray<string> | null;
}

export interface EditCollectionInput {
  readonly id: string;
  readonly title?: string | null;
  readonly permissionLevel?: string | null;
  readonly sharedWithIds?: ReadonlyArray<string> | null;
}

export interface EditLocationHashtagSetInput {
  readonly id: string;
  readonly title?: string | null;
  readonly description?: string | null;
  readonly hashtags?: ReadonlyArray<string> | null;
}

export interface EditPostArtifactGroupCaptionInput {
  readonly postArtifactGroupId: string;
  readonly caption: string;
  readonly firstComment?: string | null;
}

export interface EditPostArtifactsCaptionInput {
  readonly postArtifactIds: ReadonlyArray<string>;
  readonly caption: string;
  readonly firstComment?: string | null;
}

export interface EditTemplateCategoryInput {
  readonly clientId: string;
  readonly templateCategoryId: string;
  readonly label: string;
  readonly startDate: DateTime;
  readonly endDate?: DateTime | null;
}

export interface EditableFieldsFilterSchema {
  readonly text?: boolean | null;
  readonly image?: boolean | null;
  readonly video?: boolean | null;
}

export interface FacebookLocationLikesReportOptions {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface FacebookLocationPostReportPostsOptions {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface FacebookOnlineFollowersReportOptions {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface FacebookPublishDataInput {
  readonly description?: string | null;
  readonly firstComment?: string | null;
}

export interface GenerateCSVForTemplateUsageInput {
  readonly id: string;
  readonly builderTemplateIds: ReadonlyArray<string>;
  readonly from: DateTime;
  readonly until: DateTime;
}

export interface GetAppsFilterInput {
  readonly client?: string | null;
  readonly location?: string | null;
  readonly locationId?: string | null;
  readonly accountName?: string | null;
  readonly platform?: string | null;
}

export interface GetCampaignsFilterInput {
  readonly isArchived?: boolean | null;
  readonly search?: string | null;
}

export interface GetLocationBuildableTemplateMergeFieldsInput {
  readonly fields?: ReadonlyArray<string> | null;
  readonly templateType?: ReadonlyArray<BuildableTemplateType> | null;
  readonly platformIds?: ReadonlyArray<string> | null;
}

export interface GoogleAdsClientAccountAvailableCampaignsInput {
  readonly search?: string | null;
}

export interface GoogleAdsCustomerAvailableCampaignsInput {
  readonly search?: string | null;
}

export interface GoogleAdsLocationReportAdsInput {
  readonly from: DateOnlyScalar;
  readonly until: DateOnlyScalar;
}

export interface GoogleAdsLocationReportKeywordsInput {
  readonly from: DateOnlyScalar;
  readonly until: DateOnlyScalar;
}

export interface GoogleMyBusinessReportRecentPostsInput {
  readonly count: number;
}

export interface GoogleMyBusinessReportSearchInput {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface GoogleMyBusinessReportTopQuestionsInput {
  readonly count: number;
}

export interface GoogleMyBusinessReportTopReviewsInput {
  readonly count: number;
}

export interface GoogleMyBusinessReportViewInput {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface ImageMapItemInput {
  readonly key: string;
  readonly image: number;
}

export interface InitializeUploadInput {
  readonly category: string;
  readonly fileName: string;
  readonly fileType: string;
  readonly height?: number | null;
  readonly width?: number | null;
  readonly size?: number | null;
}

export interface InstagramBusinessAccountReportOptions {
  readonly startDate: DateTime;
  readonly endDate: DateTime;
}

export interface InstagramDirectPublishDataInput {
  readonly caption?: string | null;
  readonly firstComment?: string | null;
  readonly isStory?: boolean | null;
}

export interface LinkedFacebookPage {
  readonly pageId: string;
  readonly pageName: string;
}

export interface LinkedInPublishDataInput {
  readonly comment?: string | null;
}

export interface LocationAvailableUsersFilterInput {
  readonly search?: string | null;
}

/**
 * Boxed type that represents either a Logo/Upload ID or an empty value.
 */
export interface LogoIdOrEmptyInput {
  readonly id?: string | null;
  readonly empty?: EmptyValueScalar | null;
}

export interface MarkScheduledPublishesInput {
  readonly as: MarkScheduledPublishesMarkType;
  readonly scheduledPublishIds: ReadonlyArray<string>;
}

export interface PublishAuditDataInput {
  readonly builderTemplateId: number;
  readonly deviceId?: number | null;
  readonly linkedAssetLibraryAssetIds: ReadonlyArray<AssetIdAndLayerIdPublishInput>;
  readonly plannerId?: string | null;
  readonly publishedArtifactGroupId?: number | null;
  readonly scheduledPublishGroupId?: string | null;
}

export interface PublishFacebookImageInput {
  readonly channelData: FacebookPublishDataInput;
  readonly artifactIds: ReadonlyArray<string>;
  readonly auditData: PublishAuditDataInput;
  readonly locationId: string;
  readonly scheduledTime?: DateTime | null;
}

export interface PublishInstagramDirectImageInput {
  readonly channelData: InstagramDirectPublishDataInput;
  readonly artifactIds: ReadonlyArray<string>;
  readonly auditData: PublishAuditDataInput;
  readonly locationId: string;
  readonly scheduledTime?: DateTime | null;
}

export interface PublishLinkedInImageInput {
  readonly channelData: LinkedInPublishDataInput;
  readonly artifactIds: ReadonlyArray<string>;
  readonly auditData: PublishAuditDataInput;
  readonly locationId: string;
  readonly scheduledTime?: DateTime | null;
}

export interface PublishTwitterImageInput {
  readonly channelData: TwitterPublishDataInput;
  readonly artifactIds: ReadonlyArray<string>;
  readonly auditData: PublishAuditDataInput;
  readonly locationId: string;
  readonly scheduledTime?: DateTime | null;
}

export interface RecycleAssetsInput {
  readonly ids: ReadonlyArray<string>;
}

export interface RenameCampaignInput {
  readonly id: string;
  readonly title: string;
}

export interface ReschedulePostArtifactGroupInput {
  readonly postArtifactGroupId: string;
  readonly scheduledTime: DateTime;
}

export interface ReschedulePostArtifactsInput {
  readonly postArtifactIds: ReadonlyArray<string>;
  readonly scheduledTime: DateTime;
}

export interface RestartScheduledPublishesInput {
  readonly scheduledPublishIds: ReadonlyArray<string>;
  readonly scheduledTime?: DateTime | null;
}

export interface RestoreAssetsInput {
  readonly ids: ReadonlyArray<string>;
}

export interface RolloutCampaignInput {
  readonly id: string;
  readonly start: DateOnlyScalar;
  readonly locations: ReadonlyArray<string>;
  readonly events: ReadonlyArray<RolloutEventInput>;
}

export interface RolloutEventInput {
  readonly campaignPlannerEvent: string;
  readonly day: number;
}

export interface SaveApiKeyConfigurationInput {
  readonly apiKey: string;
}

export interface SaveClubReadyClientConfigurationInput {
  readonly chainId: string;
}

export interface SaveClubReadyLocationConfigurationInput {
  readonly clubId: string;
}

export interface SaveFacebookAdsConfigurationInput {
  readonly id: string;
  readonly accountId: string;
  readonly accountName: string;
}

export interface SaveFacebookConfigurationInput {
  readonly id: string;
  readonly pageId: string;
  readonly pageName: string;
}

export interface SaveGoogleAdsConfigurationInput {
  readonly id: string;
  readonly campaignName?: string | null;
  readonly customerId: string;
  readonly accountId?: string | null;
}

export interface SaveGoogleAnalyticsConfigurationInput {
  readonly id: string;
  readonly pagePath: string;
  readonly propertyId: string;
}

export interface SaveGoogleMyBusinessConfigurationInput {
  readonly id: string;
  readonly accountId: string;
  readonly locationId: string;
}

export interface SaveInstagramConfigurationInput {
  readonly id: string;
  readonly accountId: string;
  readonly accountName: string;
  readonly page: LinkedFacebookPage;
}

export interface SaveLinkedInConfigurationInput {
  readonly id: string;
  readonly organizationUrn: string;
}

export interface SaveLocationDraftInput {
  readonly locationDraftId?: string | null;
  readonly title: string;
  readonly document: JSONString;
  readonly builderTemplateId: number;
  readonly locationId: number;
  readonly compositeImageUploadId: number;
  readonly imageMap: ReadonlyArray<ImageMapItemInput>;
  readonly linkedAssetLibraryAssetIds: ReadonlyArray<AssetIdAndLayerIdInput>;
}

export interface SaveTemplateEmailTemplateSettingsInput {
  readonly subject?: string | null;
}

export interface SaveTemplateInput {
  readonly id: string;
  readonly title?: string | null;
  readonly instructions?: string | null;
  readonly status?: BuildableTemplateStatus | null;
  readonly categoryIds?: ReadonlyArray<string> | null;
  readonly tagIds?: ReadonlyArray<string> | null;
  readonly emailTemplateSettings?: SaveTemplateEmailTemplateSettingsInput | null;
}

export interface SendClubReadyCampaignInput {
  readonly recipientLists: SendClubReadyCampaignRecipientLists;
  readonly emailTemplateId: number;
  readonly subject: string;
  readonly scheduleTime?: DateTime | null;
  readonly linkedAssetLibraryAssetIds?: ReadonlyArray<AssetIdAndLayerIdInput> | null;
  readonly uploadId: number;
  readonly plannerId?: number | null;
}

export interface SendClubReadyCampaignPreviewInput {
  readonly recipientEmail: string;
  readonly emailTemplateId: number;
  readonly subject: string;
  readonly scheduleTime?: DateTime | null;
  readonly html: string;
}

export interface SendClubReadyCampaignRecipientLists {
  readonly active?: SendClubReadyCampaignRecipientListsActive | null;
  readonly inactive?: SendClubReadyCampaignRecipientListsInactive | null;
  readonly prospects?: SendClubReadyCampaignRecipientListsProspects | null;
  readonly cooledOffProspects?: SendClubReadyCampaignRecipientListsCooledOffProspects | null;
  readonly custom?: SendClubReadyCampaignRecipientListsCustom | null;
}

export interface SendClubReadyCampaignRecipientListsActive {
  readonly send: boolean;
  readonly options?: ClubReadyActiveListFilter | null;
}

export interface SendClubReadyCampaignRecipientListsCooledOffProspects {
  readonly send: boolean;
}

export interface SendClubReadyCampaignRecipientListsCustom {
  readonly send: boolean;
  readonly options?: ClubReadyCustomListFilter | null;
}

export interface SendClubReadyCampaignRecipientListsInactive {
  readonly send: boolean;
  readonly options?: ClubReadyInactiveListFilter | null;
}

export interface SendClubReadyCampaignRecipientListsProspects {
  readonly send: boolean;
  readonly options?: ClubReadyProspectsListFilter | null;
}

export interface SendEmailNotificationSenderInfoInput {
  readonly fromEmailLocalPart: string;
  readonly fromEmailDomainPart: string;
  readonly fromName: string;
  readonly clientSendgridId: string;
}

export interface SendInAppEmailNotificationInput {
  readonly locationIds: ReadonlyArray<string>;
  readonly subject: string;
  readonly message: string;
  readonly link?: string | null;
  readonly saveBannerSection?: SendInAppEmailNotificationSaveSectionInput | null;
  readonly saveFooterSection?: SendInAppEmailNotificationSaveSectionInput | null;
  readonly senderInfo?: SendEmailNotificationSenderInfoInput | null;
  readonly filteredByTitles?: ReadonlyArray<string> | null;
}

export interface SendInAppEmailNotificationPreviewInput {
  readonly recipientEmail: string;
  readonly subject: string;
  readonly message: string;
  readonly link?: string | null;
  readonly saveBannerSection?: SendInAppEmailNotificationSaveSectionInput | null;
  readonly saveFooterSection?: SendInAppEmailNotificationSaveSectionInput | null;
  readonly senderInfo?: SendEmailNotificationSenderInfoInput | null;
}

export interface SendInAppEmailNotificationSaveSectionInput {
  readonly brandId?: string | null;
  readonly name: string;
  readonly content: string;
}

export interface SendMailchimpCampaignInput {
  readonly campaignId: string;
  readonly scheduleDate?: DateTime | null;
}

export interface SendMailchimpCampaignPreviewInput {
  readonly campaignId: string;
  readonly recipient: string;
}

export interface SendgridEmailCampaignReportUsersFilterInput {
  readonly name?: string | null;
  readonly email?: string | null;
}

export interface SetTemplateCategoriesOrderInput {
  readonly clientId: string;
  readonly templateCategoriesOrder: ReadonlyArray<string>;
}

export interface SortAssetsInput {
  readonly field: AssetSortColumn;
  readonly direction: SortDirection;
}

export interface TagFilterInput {
  readonly tag: string;
  readonly matchType?: TagMatchType | null;
}

export interface TrackAssetLibraryEventInput {
  readonly key: string;
  readonly browser: string;
  readonly timestamp: DateTime;
  readonly timezone: string;
}

export interface TwitterPublishDataInput {
  readonly description?: string | null;
}

export interface UnassignBrandInput {
  readonly clientId: string;
  readonly brandId: string;
}

export interface UpdateBrandInput {
  readonly id: string;
  readonly title?: string | null;
  readonly color?: string | null;
  readonly logoId?: number | null;
}

export interface UpdateClientInput {
  readonly id: string;
  readonly title?: string | null;
  readonly country?: string | null;
}

export interface UpdateCustomFontInput {
  readonly id: string;
  readonly family: string;
}

export interface UpdateIntegrationConfigurationInput {
  readonly id: string;
  readonly shouldEnable: boolean;
  readonly required?: boolean | null;
}

export interface UpdateLocationDetailsInput {
  readonly id: string;
  readonly title?: string | null;
  readonly displayName?: string | null;
  readonly timezone?: string | null;
  readonly addressLineOne?: string | null;
  readonly addressLineTwo?: string | null;
  readonly addressLineThree?: string | null;
  readonly locationEmail?: string | null;
  readonly phoneNumber?: string | null;
  readonly websiteUrl?: string | null;
}

export interface UpdateUserDetailsInput {
  readonly username?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly title?: string | null;
  readonly timezone?: string | null;
  readonly localeCode?: string | null;
  readonly marketingUpdatesEnabled?: boolean | null;
  readonly specialRequestNotificationsEnabled?: boolean | null;
  readonly roles: ReadonlyArray<Roles>;
  readonly guidedTourEnabled?: boolean | null;
}

export interface UpdateUserLocationsInput {
  readonly addLocations?: ReadonlyArray<string> | null;
  readonly removeLocations?: ReadonlyArray<string> | null;
  readonly deleteIfOrphaned?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
