import { gql } from '@apollo/client';
export const GET_MERGE_FIELDS = gql`
    query GetMergeFields($id: ID!, $input: GetLocationBuildableTemplateMergeFieldsInput!) {
        location(id: $id) {
            id
            buildableTemplateMergeFields(input: $input) {
                ... on LocationBuildableTemplateMergeFieldTemp {
                    field
                    values {
                        ... on LocationBuildableTemplateMergeFieldValue {
                            value
                            isFallback
                        }
                    }
                }
            }
        }
    }
`;
