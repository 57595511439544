
import $ from 'jquery';

import { PopoverOptions, Trigger } from 'bootstrap';
import * as React from 'react';
import { renderToString } from 'react-dom/server';

export class PopoverService {
    public static SID = 'PopoverService';

    public static readonly $inject: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    public constructor() {
        // Do nothing
    }

    public show(element: HTMLElement | string, options: PopoverOptions): void {
        const $element = $(element);
        $element.popover(options).popover('show');
    }

    public hide(element: HTMLElement | string): void {
        const $element = $(element);
        $element.popover('hide');
    }

    public destroy(element: HTMLElement | string): void {
        const $element = $(element);
        $element.popover('destroy');
    }
}

angular.module('app').service(PopoverService.SID, PopoverService);

export type PopoverHookConfig = {
    content: React.ReactNode;
    trigger?: Trigger;
    placement?: 'auto' | 'bottom' | 'left' | 'right' | 'top';
};

/**
 * Bootstrap popovers as a React hook!
 *
 * @example
 * const ref = usePopover({ content: 'Hello, World!' });
 * return <button ref={ref}>Click me</button>
 * @param config - Configuration for the popover such as content and trigger
 * @returns A React ref that should be attached to the element that will trigger the popover
 */
export function usePopover<TElement extends HTMLElement = HTMLElement>(
    config: PopoverHookConfig,
): React.RefObject<TElement> {
    const { content, placement = 'top', trigger = 'click' } = config;
    const ref = React.useRef<TElement>(null);
    const renderedContent = renderToString(<>{content}</>);

    React.useEffect(() => {
        const { current } = ref;
        if (current) {
            $(current).popover({ container: 'body', content: renderedContent, html: true, placement, trigger });
        }

        return () => {
            if (current) {
                $(current).popover('destroy');
            }
        };
    }, [placement, renderedContent, trigger]);
    return ref;
}
