import { ExternalAuthApp } from '@deltasierra/shared';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { ExternalAuthService } from '../externalAuthService';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { $locationSID } from '../../common/angularData';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { AuthAppDetailsController, ExternalAuthAppDetailsLocals } from './authAppDetailsController';

export class ExternalAuthAppsController {
    static SID = 'ExternalAuthAppsController';

    fetchData = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () => this.externalAuthService.getAllExternalAuthApps().then(apps => this.externalAuthApps = apps));

    externalAuthApps: ExternalAuthApp[] = [];

    static readonly $inject : string[] = [
        $modalSID,
        $locationSID,
        InteractionUtils.SID,
        I18nService.SID,
        ExternalAuthService.SID,
    ];

    constructor(
        private readonly $modal: ModalService,
        private readonly $location: ng.ILocationService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly externalAuthService: ExternalAuthService,
    ) {
        void this.fetchData.run({});
    }

    openCreateAppModal() {
        const modalInstance = this.$modal.open({
            templateUrl: '/partials/admin/externalAuthApps/createEditExternalAuthApp',
            controller: AuthAppDetailsController,
            controllerAs: 'ctrl',
            resolve: {
                appToUpdate: () => null,
            } as ExternalAuthAppDetailsLocals,
        });

        return modalInstance.result.then((value: ExternalAuthApp) => this.$location.path(`${this.$location.path()}/${value.id}`));
    }
}

angular.module('app').controller(ExternalAuthAppsController.SID, ExternalAuthAppsController);
